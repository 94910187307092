import React, {
  useState,
  useRef,
  lazy,
  Suspense,
  useEffect,
  useCallback,
} from "react";
import { useHistory, useRouteMatch, Route } from "react-router-dom";
import Header from "../components/header/header";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrgusers,
  getChildusers,
  updateuserGlobalRole,
  getUsersProjectRole,
  updateuserProjectRole,
  getTemplatePlanByOrg,
  getUsersGlobalRole,
  resetuserPassword,
  updateTemplatePlanById,
} from "../../services/authService";
import { showToaster, ShowAlertBox, getUserItem } from "../../services/helper";
import Switch from "@mui/material/Switch";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStateWithCallbackLazy } from "use-state-with-callback";
// import UserCreateModal from "./createUser";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import PageLoader from "../../services/pageLoader";
import { debounce } from "debounce";
import { getAdminProjects, getProjects } from "../../services/api.service";
import { Radio } from "@mui/material";
export default function RoleAssign(props) {
  const [projectList, setprojectList] = useState([]);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [includeDelete, setIncludeDelete] = useState(0);
  const [pageNo, setPageNo] = useState(0);
  const [limit, setLimit] = useState(5);
  const [loadMoreFetching, setLoadMoreFetching] = useState(false);
  const [loadMoreEnable, setLoadMoreEnable] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [listUsers, setListUsers] = useState(undefined);
  const history = useHistory();
  const [keyWord, setKeyWord] = useState("");
  const [users, setUsers] = useState([]);
  const [fonts, setfonts] = useStateWithCallbackLazy();
  const [newUser, setnewUser] = useState(undefined);
  const [checkedCreateUser, setCheckedCreateUser] = useState(false);
  const [checkedCreateProject, setCheckedCreateProject] = useState(false);
  const [checkedTemplateUpload, setCheckedTemplateUpload] = useState(false);
  const [checkedTemplateList, setCheckedTemplateList] = useState(false);
  const [checkedTemListEnable, setCheckedTemListEnable] = useState(false);
  const [checkedTemUploadEnable, setCheckedTemUploadEnable] = useState(false);
  const [checkedDeleteProject, setCheckedDeleteProject] = useState(false);
  const [randomPassword, setRandomPassword] = useState(undefined);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [editCategoryID, seteditCategoryID] = useState(undefined);
  let userId = getUserItem("id");
  let userName = getUserItem("name");
  let userEmail = getUserItem("email");
  let AdminRole = getUserItem("role");
  let userParentId = getUserItem("orgId");
  const [cacheUserList, setcacheUserList] = useState(undefined);
  const countuser = useRef(0);
  const [checkedManualTemplate, setCheckedManualTemplate] = useState(false);
  const [checkedAutomaticTemplate, setCheckedAutomaticTemplate] =
    useState(false);
  const [checkedManualEnable, setCheckedManualEnable] = useState(false);
  const [checkedAutomaticEnable, setCheckedAutomaticEnable] = useState(false);

  const [checkedPubliclTemplate, setCheckedPublicTemplate] = useState(false);
  const [checkedPrivateTemplate, setCheckedPrivateTemplate] = useState(false);
  const [checkedBothTemplate, setCheckedBothTemplate] = useState(false);
  const [checkedPublicEnable, setCheckedPublicEnable] = useState(false);
  const [checkedPrivateEnable, setCheckedPrivateEnable] = useState(false);
  const [checkedBothEnable, setCheckedBothEnable] = useState(false);
  // useEffect(async () => {
  //   await  getAllProjects(1, 0, "");
  //   await getOrganisationUsers();

  //   console.log(AdminRole, "listUserslistUsers");
  
  // }, []);
  useEffect(() => {
    const init = async () => {
      try {
        let ddd = localStorage.getItem("_organizationdetails");
        console.log(ddd, "listUserslistUsers");
        let userDetails = JSON.parse(ddd);
        setListUsers(userDetails);
      } catch (e) {
        console.log(e);
      }
    };
    init();
  }, []);

  useEffect(() => {
    const init = async () => {
      try {
        getOrgGlobalRole();
        getOrgTemplatelRole();
        await getAllProjects(1, 0, "");
        // updateProjectRoleDetails()
      } catch (e) {
        console.log(e);
      }
    };
    if (listUsers) {
      init();
    }
  }, [listUsers]);

  const backToDashboard = () => {
    history.push("/admin/org-settings");
  };

  const updateProjectRoleDetails = async (projList) => {
    try {
     
        const response = await getUsersGlobalRole(listUsers.id);
        try {
          setPageLoad(true);
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null&&response.data.result!==undefined&&
            response.data.result!==""&&response.data.result!==null
          ) {
            // setCategoryList(ddd)
            setPageLoad(false);
            setCheckedTemplateUpload(response.data.result[0].templateUpload);
            setCheckedTemplateList(response.data.result[0].templateList);
            setCheckedTemListEnable(response.data.result[0].templateList);
            setCheckedTemUploadEnable(response.data.result[0].templateUpload);

            // setCheckedUserEnable(response.data.result[0].addUser)
            // setCheckedProjectEnable(response.data.result[0].addProject)
            // console.log(response.data.result[0].addProject, "datatatatat");
          } else {
            setPageLoad(false);
            showToaster(
              "Something went wrong. Please Try Again",
              "warning"
            );
          }
        } catch (error) {
          console.log(error);

          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      

      console.log(AdminRole, "listUserslistUsers");
    } catch (e) {
      console.log(e);
    }
    // setprojectList(projList);
    return true;
  };

  const getOrgGlobalRole = () => {
    getUsersGlobalRole(listUsers.id).then((response) => {
      try {
        setPageLoad(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null&&response.data.result!==undefined&&
          response.data.result!==""&&response.data.result!==null&&response.data.result[0]!==undefined&&
          response.data.result[0]!==""&&response.data.result[0]!==null
        ) {
          // console.log(projectList, "response");
          // setCategoryList(ddd)
          setPageLoad(false);

          // setTimeout(() => {
          //     setprojectList(projectData2)
          // }, 1000);
          setCheckedTemplateUpload(response.data.result[0].templateUpload);
          setCheckedTemplateList(response.data.result[0].templateList);
          setCheckedTemListEnable(response.data.result[0].templateList);
          setCheckedTemUploadEnable(response.data.result[0].templateUpload);
          
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      } catch (error) {
        console.log(error);

        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  const getOrgTemplatelRole = () => {
    getTemplatePlanByOrg(listUsers.id).then((response) => {
      try {
        setPageLoad(true);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null&&response.data.result!==undefined&&
          response.data.result!==""&&response.data.result!==null
        ) {
          console.log(response, "response");
          // setCategoryList(ddd)
          setPageLoad(false);

          // setTimeout(() => {
          //     setprojectList(projectData2)
          // }, 1000);
          // setCheckedTemplateUpload(response.data.result[0].templateUpload);
          // setCheckedTemplateList(response.data.result[0].templateList);
          // setCheckedTemListEnable(response.data.result[0].templateList);
          // setCheckedTemUploadEnable(response.data.result[0].templateUpload);
          setCheckedManualTemplate(response.data.result[0].manualTemplate);
          setCheckedAutomaticTemplate(response.data.result[0].autoTemplate);
          setCheckedManualEnable(response.data.result[0].manualTemplate);
          setCheckedAutomaticEnable(response.data.result[0].autoTemplate);
    
          
          if(response.data.result[0].public&&response.data.result[0].private){
            setCheckedBothTemplate(true)
            setCheckedBothEnable(true)
          }else if(response.data.result[0].public&&response.data.result[0].private==false){
            setCheckedPublicTemplate(response.data.result[0].public)
            setCheckedPublicEnable(response.data.result[0].public)
          }else if(response.data.result[0].public==false&&response.data.result[0].private){
            setCheckedPrivateTemplate(response.data.result[0].private)
            setCheckedPrivateEnable(response.data.result[0].private)
          }
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        console.log(error);

        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  const cancelRoleTable = (data) => {
    console.log(data);
    setCheckedTemplateUpload(checkedTemUploadEnable);
    setCheckedTemplateList(checkedTemListEnable);
    setCheckedAutomaticTemplate(checkedAutomaticEnable)
    setCheckedManualTemplate(checkedManualEnable)
    setCheckedPublicTemplate(checkedPublicEnable)
    setCheckedPrivateTemplate(checkedPrivateEnable)
    setCheckedBothTemplate(checkedBothEnable)
    setListUsers(data);
  };
  // useEffect(() => {
  //     if (listUsers.id !== 0) {
  //         console.log("called,", listUsers);
  //         setPageLoad(true)
  //     }
  // }, [listUsers])

  const setAutoProjectEnable = (event) => {
    setCheckedAutomaticTemplate(event.target.checked);
    if(event.target.checked==true){
      setCheckedManualTemplate(false)
    }else{
      setCheckedManualTemplate(true)
    }
  };
  const setManualProjectEnabled = (event) => {
    setCheckedManualTemplate(event.target.checked);
    if(event.target.checked==true){
      setCheckedAutomaticTemplate(false)
    }else{
      setCheckedAutomaticTemplate(true)
    }
    if(checkedPubliclTemplate===false&&checkedPrivateTemplate===false){
      setCheckedPublicTemplate(true)
      setCheckedPrivateTemplate(false)
      setCheckedBothTemplate(false)
    }
  };
  const setTemplateUploadEnabled = (event) => {
    setCheckedTemplateUpload(event.target.checked);
    
  };
  const setTemplateListEnabled = (event) => {
    setCheckedTemplateList(event.target.checked);
  };

  const changePermission = ( actiontype, event) => {
    let previousData;
      if ( actiontype === "public") {
        setCheckedPublicTemplate(event.target.checked)
        setCheckedPrivateTemplate(false)
        setCheckedBothTemplate(false)
      } else if( actiontype === "private") {
        setCheckedPublicTemplate(false)
        setCheckedPrivateTemplate(event.target.checked)
        setCheckedBothTemplate(false)
      }else{
        setCheckedPublicTemplate(false)
        setCheckedPrivateTemplate(false)
        setCheckedBothTemplate(event.target.checked)
      }
  };

  const AddRoleinOrgUsers = async () => {
    console.log(listUsers.id, "listUsers");
    try {
      let filter = {
        // addUser: checkedCreateUser,
        // addProject: checkedCreateProject,
        templateList: checkedTemplateList,
        templateUpload: checkedTemplateUpload,
      };
      setPageLoad(true);
      await updateuserGlobalRole(listUsers.id, filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setPageLoad(false);
            if (response.data.result != undefined) {
            }
          } else {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
        } catch (error) {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
       let templatePlan={
        private:checkedBothTemplate?true: checkedPrivateTemplate,
        public:checkedBothTemplate?true:checkedPubliclTemplate,
        autoTemplate:checkedAutomaticTemplate,
        manualTemplate:checkedManualTemplate
       }
      await updateTemplatePlanById(listUsers.id,templatePlan).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setPageLoad(false);
            showToaster("Plan updated successfully", "success");
           
            if (response.data.result != undefined) {
            }
          } else {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
        } catch (error) {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    } catch (error) {
      setPageLoad(false);
      showToaster("Something went wrong. Please Try Again", "warning");
    }
  };
  const Allowed = {
    Uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
    Lowers: "qwertyuiopasdfghjklzxcvbnm",
    Numbers: "1234567890",
    Symbols: "@$!%*#?&",
  };

  const getRandomCharFromString = (str) =>
    str.charAt(Math.floor(Math.random() * str.length));

  const adminResetPassword = (email) => {
    let pwd = "";
    let length = 8;
    pwd += getRandomCharFromString(Allowed.Uppers); //pwd will have at least one upper
    pwd += getRandomCharFromString(Allowed.Lowers); //pwd will have at least one lower
    pwd += getRandomCharFromString(Allowed.Numbers); //pwd will have at least one number
    pwd += getRandomCharFromString(Allowed.Symbols); //pwd will have at least one symbolo

    for (let i = pwd.length; i < length; i++)
      pwd += getRandomCharFromString(Object.values(Allowed).join("")); //fill the rest of the pwd with random characters
    setRandomPassword(pwd);
    console.log(pwd, "generatePassword");
    console.log(randomPassword, "pwd");
    let filter = {
      email: email,
      userId: listUsers.id,
      password: pwd,
    };
    setPageLoad(true);
    resetuserPassword(filter).then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          console.log(response, "response");
          setPageLoad(false);
          if (response.data.result != undefined) {
            showToaster("Password is Reset Successfully", "success");
            // let projectList1 = response.data.result.data;
            // setUsers(projectList1);
            // setPosts(projectList1);
            // setListUsers({
            //   value: "Select Users",
            //   label: "Select Users",
            //   id: 0,
            //   email: "",
            // });
            // console.log(projectList1, "response");
          }
        } else {
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again1", "warning");
        }
      } catch (error) {
        setPageLoad(false);
        showToaster("Something went wrong. Please Try Again", "warning");
      }
    });
  };
  const moveToNextPage = () => {
    setFetching(true);
    setLoadMoreFetching(true);
    getAllProjects(1, pageNo + 1, keyWord);
    setPageNo(pageNo + 1);
  };

  const getAllProjects = async (flag, PageNo, KeyWord) => {
    let filter;
    setPageLoad(true);
    try {
      filter = {
        page_no: PageNo, //page_no is working as start in fetching data
        limit: limit,
        keyword: KeyWord.trim(), //keyword is used for search functionality
        inc_delete: 0,
      };
      filter.projectRole = AdminRole;
      filter.userId = listUsers.id;
      await getAdminProjects(filter).then((response) => {
        try {
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.status !== "" &&
            response.status !== undefined &&
            response.status !== null &&
            response.status === 200 &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            let projectData = response.data;
            setLoadMoreEnable(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
            if (projectData.length > 0) {
              if (projectData.length < limit) {
                setLoadMoreEnable(false);
              } else {
                setLoadMoreEnable(true);
              }

              // if (PageNo === 0) {
              //   let projectList1 = projectData;
              //   console.log(projectData);
              //   let sortedList = projectList1.sort((a, b) =>
              //     a.Deleted > b.Deleted ? 1 : b.Deleted > a.Deleted ? -1 : 0
              //   );
              //   setprojectList(sortedList);
              // } else {
              let projectList1 =
                PageNo === 0
                  ? [...projectData]
                  : [...projectList, ...projectData];
              let sortedList = projectList1.sort((a, b) =>
                a.Deleted > b.Deleted ? 1 : b.Deleted > a.Deleted ? -1 : 0
              );
              updateProjectRoleDetails(sortedList).then(() => {
                setFetching(false);
                setprojectList(sortedList);
              });

              // }
              // setFetching(false);
              //setLoadMoreEnable(true);
            } else {
              if (pageNo === 0) {
                setprojectList([]);
                setFetching(false);
                setLoadMoreEnable(false);
              }
            }
            setPageLoad(false);
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            showToaster(response.message, "warning");
            setprojectList([]);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          } else {
            showToaster("No response from the server", "warning");
            setprojectList([]);
            setFetching(false);
            setLoadMoreEnable(false);
            setPageLoad(false);
            if (flag === 1) {
              setLoadMoreFetching(false);
            }
          }
        } catch (error) {
          showToaster("Something went wrong. Please Try Again", "warning");
          setprojectList([]);
          setFetching(false);
          setLoadMoreEnable(false);
          setLoadMoreFetching(false);
          setPageLoad(false);
        }
      });
    } catch (error) {
      showToaster("Something went wrong. Please Try Again", "warning");
      setprojectList([]);
      setFetching(false);
      setLoadMoreEnable(false);
      setLoadMoreFetching(false);
      setPageLoad(false);
    }
  };

  return (
    <>
      <PageLoader showLoader={pageLoad} />
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header />

          <div className="pb-wrapper user-permission">
            <div className="row pb-header mb-4">
              <div className="col-md-6 col-lg-6">
                <div className="d-flex ">
                  <button
                    type="submit"
                    onClick={() => {
                      backToDashboard();
                      // setIncludeDelete(0)
                      // setPageNo(0);
                      // setKeyWord("");
                      // getAllProjects(0, 0, "", listCategory, listStatus);
                      // getDashboardDetails();
                    }}
                    className="btn btn-outline-primary text-nowrap back-button me-3 mb-2 mb-lg-0"
                    value="submit"
                  >
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.42652e-06 5.5C0.00211858 5.41315 0.0186696 5.32723 0.0490002 5.24563C0.0651946 5.21658 0.0839324 5.18898 0.105001 5.16313C0.123314 5.12504 0.144368 5.08828 0.168001 5.05313L4.368 0.240672C4.53056 0.0540017 4.78216 -0.0333139 5.02804 0.0116137C5.27392 0.0565413 5.47672 0.226887 5.56004 0.458484C5.64336 0.690081 5.59456 0.947744 5.432 1.13441L2.219 4.81251L13.3 4.81251C13.6866 4.81251 14 5.12031 14 5.5C14 5.87969 13.6866 6.18749 13.3 6.18749L2.219 6.18749L5.432 9.86559C5.59456 10.0523 5.64337 10.3099 5.56004 10.5415C5.47672 10.7731 5.27392 10.9435 5.02804 10.9884C4.78216 11.0333 4.53056 10.946 4.368 10.7593L0.168001 5.94687C0.144368 5.91172 0.123314 5.87496 0.105001 5.83687C0.0839324 5.81102 0.0651946 5.78342 0.0490003 5.75437C0.0186696 5.67277 0.00211859 5.58685 1.42652e-06 5.5Z"
                        fill=""
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center mb-3">
              <div className="d-flex projectSwitch align-items-center me-3">
                <span>Template Upload</span>
                {/* <div className="switch-custom d-flex"> */}
                <Switch
                  // disabled={checkedProjectEnable ? false : true}
                  checked={checkedTemplateUpload}
                  onChange={(e) => setTemplateUploadEnabled(e)}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>

              <div className="d-flex projectSwitch align-items-center me-3 ">
                <span>Template List</span>
                {/* <div className="switch-custom d-flex"> */}
                <Switch
                  // disabled={listUsers.id == 0 ? true : false}
                  checked={checkedTemplateList}
                  onChange={(e) => setTemplateListEnabled(e)}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>
              <div className="d-flex projectSwitch align-items-center me-3 ">
                <span>Automatic Plan</span>
                {/* <div className="switch-custom d-flex"> */}
                <Switch
                  // disabled={listUsers.id == 0 ? true : false}
                  checked={checkedAutomaticTemplate}
                  onChange={(e) => setAutoProjectEnable(e)}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                {checkedAutomaticTemplate && (
                  <div className="d-flex">
                    <div className="me-2">
                      <Radio
                        checked={checkedPubliclTemplate}
                        onChange={(e) =>
                          changePermission( "public", e)
                        }
                        // value={data.autoTemplate}
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <label for="html-11"> Public</label>
                    </div>
                    <div className="me-2">
                      <Radio
                        checked={checkedPrivateTemplate}
                        onChange={(e) =>
                          changePermission( "private", e)
                        }
                        // value={data.autoTemplate}
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <label for="html-12"> Private</label>
                    </div>
                    <div>
                      <Radio
                        checked={checkedBothTemplate}
                        onChange={(e) => changePermission( "both", e)}
                        // value={data.autoTemplate}
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <label for="html-12"> Both</label>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex projectSwitch align-items-center ">
                <span>Manual Plan</span>
                {/* <div className="switch-custom d-flex"> */}
                <Switch
                  // disabled={listUsers.id == 0 ? true : false}
                  checked={checkedManualTemplate}
                  onChange={(e) => setManualProjectEnabled(e)}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />

                {checkedManualTemplate && (
                  <div className="d-flex">
                    <div className="me-2">
                      <Radio
                        checked={checkedPubliclTemplate}
                        onChange={(e) =>
                          changePermission( "public", e)
                        }
                        // value={data.autoTemplate}
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <label for="html-11"> Public</label>
                    </div>
                    <div className="me-2">
                      <Radio
                        checked={checkedPrivateTemplate}
                        onChange={(e) =>
                          changePermission( "private", e)
                        }
                        // value={data.autoTemplate}
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <label for="html-12"> Private</label>
                    </div>
                    <div>
                      <Radio
                        checked={checkedBothTemplate}
                        onChange={(e) => changePermission( "both", e)}
                        // value={data.autoTemplate}
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                      />
                      <label for="html-12"> Both</label>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                {/* {listUsers !== undefined && ( */}
                <div className="d-flex justify-content-end mb-2">
                  <button
                    type="button"
                    onClick={() => {
                      AddRoleinOrgUsers();
                    }}
                    className={
                      listUsers !== undefined
                        ? "btn btn-primary  me-2 rounded-0"
                        : "btn btn-primary  me-2 rounded-0 disabled"
                    }
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      cancelRoleTable(listUsers);
                      // getOrganisationUsers()
                      // setListUsers("")
                      // setListUsers({
                      //     value: "Select Users",
                      //     label: "Select Users",
                      //     id: 0,
                      //     email: ""
                      // });
                    }}
                    className={
                      listUsers !== undefined
                        ? "btn btn-outline rounded-0"
                        : "btn btn-outline rounded-0 disabled"
                    }
                  >
                    Cancel
                  </button>
                </div>
                {/* )} */}
                <div className="table-wraper ">
                  <div className="pb-tbl-blk org-role">
                    <div className="pb-tbl-wrapper mb-3 pb-tbl-head mt-0">
                      <div className="pb-head-label">Project Name</div>
                      <div className="pb-head-label text-center"> Created Date</div>
                      <div className="pb-head-label text-center">Modified Date</div>
                    </div>

                    <>
                      {projectList.map((project, index) => {
                        let projectName = project.ProjectName;
                        let Created = project.CreatedOn;
                        let sortedCreatedDate = Created.split(" ")[0];
                        let Modified = project.UpdatedOn;
                        let sorteModifieddDate = Modified.split(" ")[0];
                        let projectProgress = project.RenderStatus;
                        // let sortedCars1 = project.sort((a, b) =>
                        //   a.sortedCreatedDate.split('-').reverse().join().localeCompare(b.sortedCreatedDate.split('-').reverse().join()));
                        let ddd = JSON.parse(project.ProjectData);
                        let Modules = ddd[project.ProjectID].Modules;
                        console.log(project, "projectList");
                        if (
                          projectName !== undefined &&
                          projectName !== null &&
                          projectName.trim() !== ""
                        ) {
                        } else {
                          projectName = "No project name provided";
                        }
                        return (
                          <div className="pb-tbl-wrapper pb-data-tbl">
                            <div className="pb-data-label  ">{projectName}</div>
                            <div className="pb-data-label text-center">
                              {sortedCreatedDate}
                            </div>
                            <div className="pb-data-label text-center">
                              {sorteModifieddDate}
                            </div>{" "}
                          </div>
                        );
                      })}
                    </>
                    {projectList.length > 0
                      ? ""
                      : fetching === false && (
                          <React.Fragment>
                            <div className="no-project-selected blink">
                              No project available
                            </div>
                          </React.Fragment>
                        )}
                  </div>
                  {projectList.length > 0 && (
                    <div className="d-flex justify-content-between w-100 footer-btn flex-wrap mt-4">
                      {loadMoreEnable && (
                        <div className="d-flex justify-content-end w-100">
                          <button
                            type="button"
                            style={{ display: "inline-block" }}
                            className="btn btn-primary cm-btn"
                            onClick={() => moveToNextPage()}
                          >
                            {fetching ? "Loading..." : "Load More"}{" "}
                            {fetching && (
                              <CircularProgress size="1rem" color="inherit" />
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {listUsers !== undefined && (
                <div className="col-md-4 user-permissin-details d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <div className="user-info">
                      <div className="user-img-wrap">
                        <div className="user-img-inner">
                          <div className="user-img">
                            <img src="../../assets/img/user-01.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3>
                      <b> {listUsers.value}</b>
                    </h3>
                    <p>
                      <i>{listUsers.timezone}</i>
                    </p>
                    <p>{listUsers.email}</p>
                    <button
                      onClick={() => adminResetPassword(listUsers.email)}
                      type="submit"
                      className="btn btn-outline mt-3 m-auto"
                      value="submit"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <UserCreateModal
        closePreview={() => setOpenCreateUser(false)}
        createUserModal={openCreateUser}
        setnewUsers={setnewUser}
        // selectedImage={selectedImageFile}
      /> */}
    </>
  );
}
