
import React, { useState,useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
    getRenderErrorDetails
} from "../../services/authService";
import {
  ShowAlertBox,
} from "../../services/helper";
import { useSelector, } from "react-redux";
export default function Errormodal(props) {
    let DisplayMode = useSelector((state) => state.project.DisplayMode);
    const [errorData, setErrorData] = useState(undefined)
    console.log(props,"props");
    const getRenderErrorData = () => {
      console.log("called1");
      getRenderErrorDetails(props.renderProjectId).then((response) => {
        try {
         
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setErrorData(response.data.result)
            console.log(response);
          } else {
            // setPageLoad(false);
            ShowAlertBox(
              "Oops...",
              "Something went wrong. Please Try Again1",
              "error"
            );
          }
        } catch (error) {
          console.log(error);
          // setPageLoad(false);
          ShowAlertBox(
            "Oops...",
            "Something went wrong. Please Try Again2",
            "error"
          );
        }
      });
    };
    useEffect(() => {
        console.log("called");
      getRenderErrorData()
    }, [props.show])
  return (
    
    <Modal
    isOpen={props.show}
    fade={true}
    centered
    className={
      DisplayMode
        ? "modal-dialog  modal-dialog-centered custom-modal dark render-error-modal modal-lg dark"
        : "modal-dialog  modal-dialog-centered custom-modal dark render-error-modal modal-lg"
    }
  >
    <ModalBody>
            <div  className="text-center mb-4">
              <h3  className="text-uppercase font-weight-bold">Render Error Details</h3>
            </div>

            <div  className="table-wraper render-error">
              <div  className="pb-tbl-blk">
                <div  className="pb-tbl-wrapper sticky-head  mb-3">
                  <div  className="pb-head-label"> Date&Time</div>
                  <div  className="pb-head-label ">Render Error Logs</div>
                </div>
              {errorData!==undefined&&
              errorData.map((error)=>{
                let Created = error.renderErrorDate
                let sortedCreatedDate = Created.split("T")[0];
                let sortedCreatedTime = Created.split("T")[0];
             return(
              <div  className="pb-tbl-wrapper pb-data-tbl">
              <div  className="pb-data-label">{sortedCreatedDate}/{error.errorTime}</div>
              <div  className="pb-data-label">{error.errorLogs}</div>
            </div>
             )
              })
             }
              </div>
            </div>

            <div  className="d-flex justify-content-center w-100 footer-btn flex-wrap mt-4">              
              <button type="button"   onClick={() =>   props.closemodal(false)}  className="btn btn-outline rounded-0 me-2" data-bs-dismiss="modal">CLOSE</button>
            
            {/* </div>
          </div>
        </div>
      </div> */}
    </div>
    </ModalBody>
  </Modal>

  )
}
