import axios from "axios";
import { Axioslib, AxioslibUser } from "../lib/axioslib";
import AxioslibApi from "../lib/axioslib-api";
// import AxioslibApiTemp from "../lib/axioslib-temp";
import { getUserItem } from "./helper";
export const getRenderDashboard = (body) => {
    

  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== "") {
      AxioslibUser.post("api/dashboard-render", body, {
        headers: {
          user_id: userId,
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            //   

            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const getDashboard = (body) => {
    

  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== "") {
      AxioslibUser.post("api/dashboard", body, {
        headers: {
          user_id: userId,
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            //   

            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const getProjectsRendered = (body) => {
    
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  // let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      AxioslibUser.post("users/get_projects-rendered", body, {
        headers: {
          user_id: Id,
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            //   

            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const getRenderProjects = (body) => {
    

  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
    
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      AxioslibUser.post("users/get_render-projects", body, {
        headers: {
          user_id: Id,
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            //   

            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};
export const getProjects = (body) => {
    

  let userId = getUserItem("id");
    
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== "") {
      AxioslibUser.post("api/get_projects", body, {
        headers: {
          user_id: userId,
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            //   

            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};
export const getProjectID = (body) => {
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      AxioslibUser.post("api/get_projectsId", body, {
        headers: {
          user_id: Id,
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};
export const uploadcsvFile = (body, config) => {
  let userId = getUserItem("id");
  return new Promise((resolve, reject) => {
    AxioslibUser.post(
      "api/upload/csv",
      body,
      {
        headers: {
          token: "QCSU7nvg5Xk5qUdqQgXFdQ",
          "Content-Type": "multipart/form-data"
        }
      },
      config
    )
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                let result = {
                  data: response.data.Body.result,
                  message: response.data.Body.message,
                  status: response.data.StatusCode
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        resolve({
          status: 101,
          message: "Something went wrong in server Response"
        });
      });
  });
};

export const uploadMediaFile = (body, config) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("/media/uploadMedia", body, config, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        //  

        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (
                response.data.Body.result !== undefined &&
                response.data.Body.result !== null &&
                response.data.Body.result !== "" &&
                response.data.Body.result.mediaurl !== undefined &&
                response.data.Body.result.mediaurl !== null
              ) {
                let result = {
                  data: response.data.Body.result.mediaurl,
                  message: response.data.Body.message,
                  status: response.data.StatusCode
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        const errorMsg = axios.isCancel(error) ? "Upload cancelled" : "No server Response";
        resolve({ status: 101, message: errorMsg });
      });
  });
};

export const getAllFonts = (body) => {
  // let accessToken = JSON.parse(localStorage.getItem('_user'))
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/get-fonts", body, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                let result = {
                  data: response.data.Body.result.fonts,
                  message: response.data.Body.message,
                  status: response.data.StatusCode
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        resolve({ status: 101, message: "No server Response" });
      });
  });
};

export const renderPreview = (body) => {
    
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      body["user_id"] = Id;
      AxioslibUser.post("/api/render", body, {
        headers: {
          user_id: Id,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
            
          if (response.data.status !== undefined && response.data.status !== null && response.data.status !== "") {
            if (response.data.render_id !== undefined && response.data.render_id !== null) {
              resolve({
                status: response.data.status,
                message: response.data.render_id
              });
            } else {
              resolve({
                status: 101,
                message: " No response from the server"
              });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "Something went wrong" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const renderSerial = (body) => {
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      body["user_id"] = Id;
      AxioslibUser.post("/api/render-serial", body, {
        headers: {
          user_id: Id,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.message !== undefined && response.data.message !== null) {
              resolve({
                message: response.data.message
              });
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "Something went wrong" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};
export const getFinalRenderVideo = (body) => {
   
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      body.user_id = Id;
      AxioslibUser.post("api/render-final-video", body, {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
            
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              resolve({
                data: response.data,
                status: response.status
              });
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "Something went wrong" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const getProjectPreview = (body) => {
   
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      body.user_id = Id;
      AxioslibUser.post("api/project-status", body, {
        headers: {
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  resolve({
                    data: response.data.Body.result,
                    status: response.data.StatusCode,
                    message: response.data.Body.message,
                    apiStatus: response.data.Body.success
                  });
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "Something went wrong" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};
export const publicSearchFont = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/search-public", body, {
      headers: {
        user_id: userId,
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                let result = {
                  data: response.data.Body.result.Fonts,
                  message: response.data.Body.message,
                  status: response.data.StatusCode
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        resolve({ status: 101, message: "Something went wrong" });
      });
  });
};
export const publicSearch = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/search-public", body, {
      headers: {
        user_id: userId,
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        if (response.status !== undefined && response.status !== null && response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                let result = {
                  data: response.data.Body.result.Images,
                  message: response.data.Body.message,
                  status: response.data.StatusCode
                };
                if (response.data.Body.result.total_count) {
                  result.total_count = response.data.Body.result.total_count;
                }
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        let response = error.response;
        if (response !== undefined && response.status !== undefined && response.status !== null && response.status === 400) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                let result = {
                  message: response.data.Body.message,
                  status: 101
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({ status: 101, message: "No records available " });
        }
      });
  });
};

export const getCategory = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
    
    
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/get-categories", body, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          
        if (response.status === 200) {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        resolve({ status: 101, message: "Same Category name not allowed" });
      });
  });
};

export const downloadVideoBlockUrl = (config) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/download-videoblock", config, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          
        if (response.data !== undefined && response.data !== null && response.data !== null) {
          if (response.data.Body !== undefined && response.data.Body !== null) {
            if (
              response.data.Body.result !== undefined &&
              response.data.Body.result !== null &&
              response.data.Body.result !== "" &&
              response.data.Body.result.mediaurl !== undefined &&
              response.data.Body.result.mediaurl !== null
            ) {
              let result = {
                data: response.data.Body.result.mediaurl,
                message: response.data.Body.message,
                status: response.data.StatusCode
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        let response = error.response;
        if (response !== undefined && response.status !== undefined && response.status !== null && response.status === 400) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data !== undefined && response.data !== null) {
              if (response.data.results !== undefined && response.data.results !== null && response.data.results !== "") {
                let result = {
                  message: response.data.message,
                  status: 101
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({ status: 101, message: "No records available " });
        }
      });
  });
};
export const videoBlockSearch = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/search-videoblock", body, {
      headers: {
        user_id: userId,
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        if (response.status !== undefined && response.status !== null && response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.results !== undefined && response.data.results !== null && response.data.results !== "") {
              let result = {
                data: response.data.results,
                totalcount: response.data.total_results,
                message: response.data.message,
                status: response.status
              };
              resolve(result);
                
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        let response = error.response;
        if (response !== undefined && response.status !== undefined && response.status !== null && response.status === 400) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data !== undefined && response.data !== null) {
              if (response.data.results !== undefined && response.data.results !== null && response.data.results !== "") {
                let result = {
                  message: response.data.message,
                  status: 101
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({ status: 101, message: "No records available " });
        }
      });
  });
};
export const publicShutterStockSearch = (body) => {
  let userId = getUserItem("id");
  let filter = {
    user_id: userId,
    imgId: body
  };
  let accessToken = JSON.parse(localStorage.getItem("_user"));

  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/save-shutterstock", filter, {
      headers: {
        user_id: userId,
        authorization: `Bearer ${accessToken}`
        // "token":"QCSU7nvg5Xk5qUdqQgXFdQ"
      }
    })
      .then((response) => {
        if (response.status !== undefined && response.status !== null && response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null && response.data.Body !== "") {
                let result = {
                  data: response.data.Body,
                  message: response.data.Body.message,
                  status: response.data.StatusCode
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        let response = error.response;
        if (response !== undefined && response.status !== undefined && response.status !== null && response.status === 400) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                let result = {
                  message: response.data.Body.message,
                  status: 101
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({ status: 101, message: "No records available " });
        }
      });
  });
};
export const projectDeleteOrRestore = (body) => {
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      AxioslibUser.post("api/delete_restore", body, {
        headers: {
          user_id: Id,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            resolve(response);
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const projectScreenCatchePreview1 = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
    

  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== "") {
      Axioslib.post("/api/cache-priview", body, {
        headers: {
          user_id: userId,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
            

          if (response.status === 200) {
            if (response.data.message !== undefined && response.data.message !== null && response.data.message !== null) {
              if (response.data.message === "NOTFOUND") {
                resolve({ status: 400, message: "Not Found" });
              }
            }
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        })
        .catch((error) => {
          console.log(error);

          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const projectScreenPreview = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
    

  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== "") {
      Axioslib.post("/api/priview", body, {
        headers: {
          user_id: userId,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.status === 200) {
              if (response.data !== undefined && response.data !== null && response.data !== null) {
                if (response.data.Body !== undefined && response.data.Body !== null) {
                  if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                    let result = {
                      data: response.data.Body.result,
                      message: response.data.Body.message,
                      status: response.data.StatusCode
                    };
                    resolve(result);
                  } else {
                    resolve({
                      status: 101,
                      message: " No response from the server"
                    });
                  }
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({
                status: 101,
                message: "Something went wrong. Please try again"
              });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const getAssets = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/search-repo", body, {
      headers: {
        authorization: `Bearer ${accessToken}`,
        user_id: userId
      }
    })
      .then((response) => {
        if (response.status === 200) {
          //   

          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                let result = {
                  data: response.data.Body.result,
                  message: response.data.Body.message,
                  status: response.data.StatusCode
                };
                resolve(result);
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        const errMsg = error?.response?.data?.message || "Something went wrong";
        resolve({ status: 101, message: errMsg });
      });
  });
};
export const getTemplatesRepoManual = (body, type, orgId, projectid) => {
  let userId = getUserItem("id");
  let id = orgId !== "AdminRole" ? orgId : userId;
  //    body.orgId=id;

  let filter = {
    screenContent: body,
    type: type,
    orgId: id,
    projectId: projectid
  };
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/suggest-templatesmanual", filter, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            let result = {
              data: response.data,
              message: response.statusText,
              status: response.status
            };
            resolve(result);
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        resolve({ status: 101, message: "Something went wrong" });
      });
  });
};

export const getTemplatesRepo = (body, orgid, projectId) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  let filter = {
    screenContent: body,
    orgId: orgid,
    projectId: projectId
  };
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/suggest-templates", filter, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            let result = {
              data: response.data,
              message: response.statusText,
              status: response.status
            };
            resolve(result);
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        resolve({ status: 101, message: "Something went wrong" });
      });
  });
};
// Jesna ends here --------------------
export const createProjectsPreview = (body) => {
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  body = JSON.parse(body);
  if (body.type == "create") {
    body = JSON.stringify({ ...body, createdBy: userId });
  }
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      AxioslibUser.post("api/create-projectpreview", body, {
        headers: {
          "Content-Type": " application/json",
          Accept: "application/json",
          user_id: Id,
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
            

          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: "No response from the server" });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "No response from the server" });
    }
  });
};
export const createProjects = (body) => {
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      AxioslibUser.post("api/create-project", body, {
        headers: {
          "Content-Type": " application/json",
          Accept: "application/json",
          user_id: Id,
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: "No response from the server" });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "No response from the server" });
    }
  });
};
export const getUsageProject = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  // let filter={
  //     userId:body.userId,

  // }
  return new Promise((resolve, reject) => {
    if (body !== undefined && body !== null && body !== "") {
      AxioslibUser.post(`api/get-trackingProject`, body, {})
        .then((response) => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              let result = {
                data: response.data,
                status: response.status
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: "No records available" });
            }
          } else {
            resolve({ status: 101, message: "No response from the server" });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "No response from the server" });
    }
  });
};
export const getUsageProjectChildDetails = (body) => {
  // let filter={
  //     userId:userId
  // }
   

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-child-renderdata`, body)
      .then((response) => {
          

        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getUsageProjectParentDetails = (body) => {
  // let filter={
  //     userId:userId
  // }
   

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-parent-renderdata`, body)
      .then((response) => {
          

        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getUsageProjectDetails = (body) => {
  // let filter={
  //     userId:userId
  // }
   

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-renderdata`, body)
      .then((response) => {
          

        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const getRenderProjectDetails = (body) => {
  // let filter={
  //     userId:userId
  // }
   

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-dashboard-renderdata`, body)
      .then((response) => {
          

        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const projectScreenCatchePreview = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
    

  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== "") {
      Axioslib.post("/api/cache-priview", body, {
        headers: {
          user_id: userId,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
            

          if (response.status === 200) {
            if (response.data.message !== undefined && response.data.message !== null && response.data.message !== null) {
              if (response.data.message === "NOTFOUND") {
                resolve({ status: 400, message: "Not Found" });
              }
            }
            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        })
        .catch((error) => {
          console.log(error);

          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const getAdminProjects = (body) => {
    

  let userId = getUserItem("id");
    
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== "") {
      AxioslibUser.post("api/get_admin_projects", body, {
        headers: {
          user_id: userId,
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            //   

            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result.Projects,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const getAdminDashboard = (body) => {
    

  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (userId !== undefined && userId !== null && userId !== "") {
      AxioslibUser.post("api/admin_dashboard", body, {
        headers: {
          user_id: userId,
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            //   

            if (response.data !== undefined && response.data !== null && response.data !== null) {
              if (response.data.Body !== undefined && response.data.Body !== null) {
                if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                  let result = {
                    data: response.data.Body.result,
                    message: response.data.Body.message,
                    status: response.data.StatusCode
                  };
                  resolve(result);
                } else {
                  resolve({
                    status: 101,
                    message: " No response from the server"
                  });
                }
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({
              status: 101,
              message: "Something went wrong. Please try again"
            });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const getAdminUsageProject = (body) => {
  let userId = getUserItem("id");
  let accessToken = JSON.parse(localStorage.getItem("_user"));
   
  // let filter={
  //     userId:body.userId,

  // }
  return new Promise((resolve, reject) => {
    if (body !== undefined && body !== null && body !== "") {
      AxioslibUser.post(`api/get-admin-trackingProject`, body, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data !== undefined && response.data !== null && response.data !== null) {
                
              let result = {
                data: response.data,
                status: response.status
              };
              resolve(result);
            } else {
              resolve({ status: 101, message: "No records available" });
            }
          } else {
            resolve({ status: 101, message: "No response from the server" });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "No response from the server" });
        });
    } else {
      resolve({ status: 101, message: "No response from the server" });
    }
  });
};

export const updateClusterDetails = (id, body) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/update-cluster-status/${id}`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          
        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const listVMDetails = (body) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.get(`api/get-vm-data`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          

        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const listClusterDetails = (body) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/list_clusters`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          

        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};
export const SaveClusterDetails = (body) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/save-cluster`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          
        if (response.status === 200) {
          //   

          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              let result = {
                data: response.data.Body,
                message: response.data.Body.message,
                status: response.data.StatusCode
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: response.data.status
              });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: 101, message: "No response from the server" });
      });
  });
};

export const DeleteClusterDetails = (body) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/delete_clusters`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          
        if (response.status === 200) {
          //   

          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              let result = {
                data: response.data.Body,
                message: response.data.Body.message,
                status: response.data.StatusCode
              };
              resolve(result);
            } else {
              resolve({
                status: 101,
                message: response.data.status
              });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((e) => {
        console.log(e);
        resolve({ status: 101, message: "No response from the server" });
      });
  });
};

export const getClusterDetails = (sort_by = "") => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  let url = `api/get-cluster-data/`;
  if (sort_by) {
    url += `?sort_by=${sort_by}`;
  }
  return new Promise((resolve, reject) => {
    AxioslibUser.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          

        if (response.data.success === true) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getAdminUsageProjectDetails = (body) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));

  return new Promise((resolve, reject) => {
    AxioslibUser.post(`api/get-admin-renderdata`, body, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
          

        if (response.status === 200) {
          resolve(response);
        } else {
          resolve({ status: 101, message: "No response from the server" });
        }
      })
      .catch((e) => {
        resolve({ status: 500, message: e.response.data.errors });
      });
  });
};

export const getAdminProjectStatus = (body) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/admin-project-status", body, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            if (response.data.Body !== undefined && response.data.Body !== null) {
              if (response.data.Body.result !== undefined && response.data.Body.result !== null && response.data.Body.result !== "") {
                resolve({
                  data: response.data.Body.result,
                  status: response.data.StatusCode,
                  message: response.data.Body.message,
                  apiStatus: response.data.Body.success
                });
              } else {
                resolve({
                  status: 101,
                  message: " No response from the server"
                });
              }
            } else {
              resolve({ status: 101, message: " No response from the server" });
            }
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        resolve({ status: 101, message: "Something went wrong" });
      });
  });
};

export const emailNotification = (body) => {
    
  let userId = getUserItem("id");
  let orgId = getUserItem("orgId");
  let AdminRole = getUserItem("role");
  let Id = AdminRole == 0 ? userId : orgId;
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  return new Promise((resolve, reject) => {
    if (Id !== undefined && Id !== null && Id !== "") {
      body["user_id"] = Id;
      AxioslibUser.post("/api/project-rendered", body, {
        headers: {
          user_id: Id,
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`
        }
      })
        .then((response) => {
            
          if (response.data.status !== undefined && response.data.status !== null && response.data.status !== "") {
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        })
        .catch((error) => {
          resolve({ status: 101, message: "Something went wrong" });
        });
    } else {
      resolve({ status: 101, message: "Invalid Parameters" });
    }
  });
};

export const closestTemplate = (body, orgid) => {
  let accessToken = JSON.parse(localStorage.getItem("_user"));
  let filter = {
    ...body,
    orgId: orgid
  };
  return new Promise((resolve, reject) => {
    AxioslibUser.post("api/closest-templates", filter, {
      headers: {
        authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== undefined && response.data !== null && response.data !== null) {
            let result = {
              data: response.data,
              message: response.statusText,
              status: response.status
            };
            resolve(result);
          } else {
            resolve({ status: 101, message: " No response from the server" });
          }
        } else {
          resolve({
            status: 101,
            message: "Something went wrong. Please try again"
          });
        }
      })
      .catch((error) => {
        resolve({ status: 101, message: "Something went wrong" });
      });
  });
};
