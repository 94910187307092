import ChipInput from "material-ui-chip-input";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { CreateScreen } from "../../../services/DataHelper";
import { actionCreator } from "../../../store/index";
import Syles from "../../container/addinput/style";
import Tooltip from "@mui/material/Tooltip";
import { Modal, ModalBody } from "reactstrap";
const AddVisualEffect = (props) => {
  let project = useSelector((state) => state.project);
  let ActiveModule = useSelector((state) => state.project.ActiveModule);
  let ActiveSlide = useSelector((state) => state.project.ActiveSlide);
  let ActiveScreen = useSelector((state) => state.project.ActiveScreen);
  let ActiveImage = useSelector((state) => state.project.ActiveImage);
  let ActiveIllustration = useSelector((state) => state.project.ActiveIllustration);
  let ActiveVideo = useSelector((state) => state.project.ActiveVideo);
  let projectID = useSelector((state) => state.project.ProjectId);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  let { AddFeature, SelectImage, SelectIllustration, SelectVideo, EditAsset, DeleteFeature } = bindActionCreators(actionCreator, dispatch);

  const [projects, setProjects] = useState("");
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [imageDisValue, setImageDisValue] = useState("");
  const [imageTagValue, setImageTagValue] = useState("");
  const [illustrationDisValue, setIllustrationDisValue] = useState("");
  const [illustrationTagValue, setIllustrationTagValue] = useState("");
  const [videoDisValue, setVideoDisValue] = useState("");
  const [videoTagValue, setVideoTagValue] = useState("");
  const [imageAsset, setImageAsset] = useState(false);// true initially
  const [illuAsset, setIlluAsset] = useState(false);
  const [videoAsset, setVideoAsset] = useState(false);
  const [openUpdateModal, setopenUpdateModal] = useState(false);
  const [del_id, setdel_id] = useState(undefined);
  const [del_type, setdel_type] = useState(undefined);
  const [ImageTagError, setImageTagError] = useState(undefined);
  const [IlluTagError, setIlluTagError] = useState(undefined);
  const [VideoTagError, setVideoTagError] = useState(undefined);
  const [rendered, setrendered] = useState(0);
  const [imageCountValidate, setImageCountValidate] = useState([]);
  const [illuCountValidate, setIlluCountValidate] = useState([]);
  const [videoCountValidate, setVideoCountValidate] = useState([]);
  const [deleteImageValidate, setDeleteImageValidate] = useState(undefined);
  const [deleteIlluValidate, setDeleteIlluValidate] = useState(undefined);
  const [deleteVideoValidate, setDeleteVideoValidate] = useState(undefined);

  useEffect(() => {
    props.setImageCountValidate(imageCountValidate);
  }, [imageCountValidate]);

  useEffect(() => {
    props.setIlluCountValidate(illuCountValidate);
  }, [illuCountValidate]);

  useEffect(() => {
    props.setVideoCountValidate(videoCountValidate);
  }, [videoCountValidate]);


  useEffect(() => {
    setProjectId(projectID);
    setProjects(project);
    if (project.createdProject[projectID] != null) {
      setProjectLoaded(true);
      setrendered(rendered + 1);
      if (rendered <= 1) {
        // getActiveVisualAsset();
        getActiveItems();
      }
    } else {
    }
  }, [project, projectID, ActiveScreen]);

  useEffect(() => {
    if (projectLoaded) {
      getActiveItems();
    }
  }, [projectLoaded]);

  useEffect(() => {
    getActiveItems();
  }, [ActiveScreen, ActiveSlide, ActiveModule]);

  useEffect(() => {
    setTimeout(() => {
      if (!ImageTagError !== undefined || !IlluTagError !== undefined || !VideoTagError !== undefined) {
        setImageTagError(undefined);
        setIlluTagError(undefined);
        setVideoTagError(undefined);
      }
    }, 4000);
  }, [ImageTagError, IlluTagError, VideoTagError]);

  const getActiveItems = () => {
    if (projectLoaded) {
      if (project.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            let _arr = [0];
            Object.entries(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).map((i, item) => {
              if (i[1].Contenttype === "Video") {
                if (
                  (i[1].Contentparams.contentdescription != undefined &&
                    i[1].Contentparams.contentdescription != "" &&
                    i[1].Contentparams.contentdescription != null) ||
                  (i[1].Contentparams.contenttag != undefined && i[1].Contentparams.contenttag != "" && i[1].Contentparams.contenttag != null) 
                  
                ) {
                  SelectVideo(i[0]);
                  _arr.push(1);
                } else {
                  SelectVideo("VD01");
                }
              } else if (i[1].Contenttype === "Image") {
                console.log(i[1].Contentparams.contentdescription, i[1].Contentparams.contenttag, 'image content type' );
                if (
                  (i[1].Contentparams.contentdescription != undefined &&
                    i[1].Contentparams.contentdescription != "" &&
                    i[1].Contentparams.contentdescription != null) ||
                  (i[1].Contentparams.contenttag != undefined && i[1].Contentparams.contenttag != "" && i[1].Contentparams.contenttag != null)
                ) {
                  SelectImage(i[0]);
                  _arr.push(3);
                } else {
                  SelectImage("IM01");
                }
              } else {
              }
            });
            _arr.sort(function (a, b) {
              return b - a;
            });

            if (_arr[0] === 3) {
              console.log('checkk1');
              setImageAsset(true);
              setIlluAsset(false);
              setVideoAsset(false);
            } else if (_arr[0] === 2) {
              setImageAsset(false);
              setIlluAsset(true);
              setVideoAsset(false);
            } else if (_arr[0] === 1) {
              setImageAsset(false);
              setIlluAsset(false);
              setVideoAsset(true);
            } else  {
              console.log('checkk2');
              setImageAsset(true);// initailly true
              setVideoAsset(false);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if((videoDisValue.length || videoTagValue.length) && !videoAsset) {
      setImageAsset(false);// initailly true
      setVideoAsset(true);
    }
  },[videoDisValue, videoTagValue])

  const getActiveVisualAsset = () => {
    if (projectLoaded) {
      if (projects.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            let ActiveImageData = projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveImage];
            let ActiveIllustrationData =
              projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveIllustration];
            let ActiveVideoData = projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveVideo];
            if (ActiveImageData.Contentparams.contentdescription != undefined && ActiveImageData.Contentparams.contentdescription.length > 0) {
              setImageAsset(true);
              setIlluAsset(false);
              setVideoAsset(false);
            } else if (
              ActiveIllustrationData.Contentparams.contentdescription != undefined &&
              ActiveIllustrationData.Contentparams.contentdescription.length > 0
            ) {
              setImageAsset(false);
              setIlluAsset(true);
              setVideoAsset(false);
            } else if (ActiveVideoData.Contentparams.contentdescription != undefined && ActiveVideoData.Contentparams.contentdescription.length > 0) {
              setImageAsset(false);
              setIlluAsset(false);
              setVideoAsset(true);
            } else {
              setImageAsset(true);
              setIlluAsset(false);
              setVideoAsset(false);
            }
          }
        }
      }
    }
  };

  const handleImageClick = (imageId) => {
    SelectImage(imageId);
  };
  const handleIlluClick = (illuId) => {
    SelectIllustration(illuId);
  };
  const handleVideoClick = (videoId) => {
    SelectVideo(videoId);
  };
  const listImage = () => {
    // console.log(projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens);
    let slidesArray = [];
    let imageArrayCount = [];
    if (project.createdProject[projectId].Modules[ActiveModule] !== undefined) {
      if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
        if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
          const IMAGE = project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];
          const imageArray = Object.keys(IMAGE).filter((n) => n.indexOf("IM") > -1);

          console.log(imageArray);
          if (imageArray.length > 3) {
            // alert("hauiii")
            //  setimageLimit(true)
          }
          Object.entries(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).map((i, item) => {
            if (i[1].Contenttype === "Image") {
              // imageArrayCount.push(i[0])
              // console.log(imageArrayCount.length);

              const imageName = i[0].replace("IM0", "");
              let Count;
              let im = imageName.split("IM");
              if (im.length == 2) {
                Count = im[1];
              } else {
                Count = "0" + im[0];
              }
              let imageID = i[0];
              let OST = i[1];
              const classNames =
                imageID == ActiveImage ? "panel-label cm-gen-spec cm-full-lst-container active" : "panel-label cm-gen-spec cm-full-lst-container";
              slidesArray.push(
                <div className={classNames} id={imageID} key={imageID}>
                  <p onClick={() => handleImageClick(imageID)}>Image {Count}</p>
                  {imageID == ActiveImage && imageArray.length > 1 && (
                    <Tooltip arrow>
                      <div
                        className="cm-del-icon"
                        onClick={() => {
                          //DeleteFeature(imageID, "IM");
                          setdel_id(imageID);
                          setdel_type("IM");
                          setopenUpdateModal(true);
                          setDeleteImageValidate(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + imageID);
                        }}>
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                            fill=""
                          />
                        </svg>
                      </div>
                    </Tooltip>
                  )}
                </div>
              );
            }
          });
          return slidesArray;
        }
      }
    }
  };

  const deleteAndValidate = () => {
    let _arr = [];
    if (del_type == "IM") {
      console.log("deleteAndValidate");
      imageCountValidate.map((imgData) => {
        if (deleteImageValidate === imgData) {
          // debugger
          let i = imageCountValidate.indexOf(imgData);
          _arr = imageCountValidate;
          console.log(i, _arr, "deleteAndValidate");
          _arr.splice(1, i);
        }
      });
      setImageCountValidate(_arr);
    } else if (del_type == "IL") {
      illuCountValidate.map((illuData) => {
        if (deleteIlluValidate === illuData) {
          // debugger
          let i = illuCountValidate.indexOf(illuData);
          _arr = illuCountValidate;
          _arr.splice(1, i);
        }
      });
      setIlluCountValidate(_arr);
    } else if (del_type == "VD") {
      videoCountValidate.map((videoData) => {
        if (deleteVideoValidate === videoData) {
          // debugger
          let i = videoCountValidate.indexOf(videoData);
          _arr = videoCountValidate;
          _arr.splice(1, i);
        }
      });
      setVideoCountValidate(_arr);
    }
  };
  const listIllustration = () => {
    // console.log(projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens);
    let illustartionArray = [];
    if (project.createdProject[projectId].Modules[ActiveModule] !== undefined) {
      if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
        if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
          const IMAGE = project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];
          const illuArray = Object.keys(IMAGE).filter((n) => n.indexOf("IL") > -1);

          Object.entries(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).map((i, item) => {
            if (i[1].Contenttype === "Illustration") {
              const illuName = i[0].replace("IL0", "");
              let Count;
              let im = illuName.split("IL");
              if (im.length == 2) {
                Count = im[1];
              } else {
                Count = "0" + im[0];
              }
              var array = illuName.split("IL");
              let illuID = i[0];
              let OST = i[1];
              const classNames =
                illuID == ActiveIllustration
                  ? "panel-label cm-gen-spec cm-full-lst-container active"
                  : "panel-label cm-gen-spec cm-full-lst-container";
              illustartionArray.push(
                <div className={classNames} id={illuID} key={illuID}>
                  <p onClick={() => handleIlluClick(illuID)}>Illustration {Count}</p>
                  {illuID == ActiveIllustration && illuArray.length > 1 && (
                    <Tooltip arrow>
                      <div
                        className="cm-del-icon"
                        onClick={() => {
                          //DeleteFeature(illuID, "IL");
                          setdel_id(illuID);
                          setdel_type("IL");
                          setopenUpdateModal(true);
                          setDeleteIlluValidate(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + illuID);
                        }}>
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                            fill=""
                          />
                        </svg>
                      </div>
                    </Tooltip>
                  )}
                </div>
              );
            }
          });
          return illustartionArray;
        }
      }
    }
  };
  const listVideo = () => {
    let videoArray = [];
    if (project.createdProject[projectId].Modules[ActiveModule] !== undefined) {
      if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
        if (project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
          const VIDEO = project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];
          const vdArray = Object.keys(VIDEO).filter((n) => n.indexOf("VD") > -1);

          Object.entries(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).map((i, item) => {
            if (i[1].Contenttype === "Video") {
              const videoName = i[0].replace("VD0", "");
              var array = videoName.split("VD");
              let Count;
              let im = videoName.split("VD");
              if (im.length == 2) {
                Count = im[1];
              } else {
                Count = "0" + im[0];
              }
              let videoID = i[0];
              let OST = i[1];
              const classNames =
                videoID == ActiveVideo ? "panel-label cm-gen-spec cm-full-lst-container active" : "panel-label cm-gen-spec cm-full-lst-container";
              videoArray.push(
                <div className={classNames} id={videoID} key={videoID}>
                  <p onClick={() => handleVideoClick(videoID)}>Video {Count}</p>
                  {videoID == ActiveVideo && vdArray.length > 1 && (
                    <Tooltip arrow>
                      <div
                        className="cm-del-icon"
                        onClick={() => {
                          //DeleteFeature(videoID, "VD");
                          setdel_id(videoID);
                          setdel_type("VD");
                          setopenUpdateModal(true);
                          setDeleteVideoValidate(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + videoID);
                        }}>
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M10.1041 9.8187C10.1979 9.91178 10.2507 10.0385 10.2507 10.1707C10.2507 10.3028 10.1979 10.4295 10.1041 10.5226C10.011 10.6165 9.88436 10.6692 9.75223 10.6692C9.6201 10.6692 9.49344 10.6165 9.40039 10.5226L5.29232 6.40817L1.18424 10.5226C1.09119 10.6165 0.964535 10.6692 0.832404 10.6692C0.700273 10.6692 0.573613 10.6165 0.480566 10.5226C0.386753 10.4295 0.333984 10.3028 0.333984 10.1707C0.333984 10.0385 0.386753 9.91178 0.480566 9.8187L4.5936 5.70921L0.480566 1.59972C0.354867 1.47397 0.305775 1.2907 0.351785 1.11893C0.397794 0.947163 0.531913 0.812997 0.703622 0.766972C0.875331 0.720947 1.05854 0.770055 1.18424 0.895797L5.29232 5.01025L9.40039 0.895797C9.59471 0.701416 9.90975 0.701416 10.1041 0.895797C10.2984 1.09018 10.2984 1.40533 10.1041 1.59972L5.99104 5.70921L10.1041 9.8187Z"
                            fill=""
                          />
                        </svg>
                      </div>
                    </Tooltip>
                  )}
                </div>
              );
            }
          });
          return videoArray;
        }
      }
    }
  };

  const addVisualEffects = () => {
    if (imageAsset === true) {
      let newImage = "";
      const IMAGE = projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];
      let imageArray = [];
      var lastItem;
      var imageNo;
      var a;
      var b;
      var array;
      Object.entries(IMAGE).map((item, key) => {
        item.map((data) => {
          if (data.Contenttype === "Image" && item[0] !== "") {
            imageArray.push(item[0]);
            lastItem = imageArray.pop();
            imageNo = lastItem.replace("IM0", "");
            a = parseInt(imageNo);
            array = imageNo.split("IM");
            b = parseInt(array[1]);
            if (array[1] !== undefined && array[1] !== null && array[1] !== "") {
              data = b + 1;
            } else {
              data = a + 1;
            }
            if (data !== undefined && data !== null) {
              newImage = data <= 9 ? "IM0" + data : "IM" + data;
            }
            // console.log(data,imageArray,"data");
          }

          return data;
        });

        return item;
      });

      AddFeature({
        [newImage]: {
          Contenttype: "Image",
          Contentparams: {
            sequenceid: "3",
            contentdescription: "",
            searchstring: "",
            contenttag: ""
          }
        }
      });
      let data = [];
      data.push(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + newImage);
      let imageCount = [...imageCountValidate, ...data];
      setImageCountValidate(imageCount);
    } else if (illuAsset === true) {
      let newIllu = "";
      const ILLUSTRATION = projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];
      let illuArray = [];
      var lastItem;
      var illuNo;
      var a;
      var b;
      var array;
      Object.entries(ILLUSTRATION).map((item, key) => {
        item.map((data) => {
          if (data.Contenttype === "Illustration" && item[0] !== "") {
            illuArray.push(item[0]);
            lastItem = illuArray.pop();
            illuNo = lastItem.replace("IL0", "");
            a = parseInt(illuNo);
            array = illuNo.split("IL");
            b = parseInt(array[1]);
            if (array[1] !== undefined && array[1] !== null && array[1] !== "") {
              data = b + 1;
            } else {
              data = a + 1;
            }
            if (data !== undefined && data !== null) {
              newIllu = data <= 9 ? "IL0" + data : "IL" + data;
            }
          }

          return data;
        });

        return item;
      });
      AddFeature({
        [newIllu]: {
          Contenttype: "Illustration",
          Contentparams: {
            sequenceid: "5",
            contentdescription: "",
            contenttag: "",
            searchstring: ""
          }
        }
      });
      let data = [];
      data.push(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + newIllu);
      let illuCount = [...illuCountValidate, ...data];
      // console.log(imageCount,"imageCount");
      setIlluCountValidate(illuCount);
    } else if (videoAsset === true) {
      let newVideo = "";
      const VIDEO = projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen];
      let videoArray = [];
      var lastItem;
      var videoNo;
      var a;
      var b;
      var array;
      Object.entries(VIDEO).map((item, key) => {
        item.map((data) => {
          if (data.Contenttype === "Video" && item[0] !== "") {
            videoArray.push(item[0]);
            lastItem = videoArray.pop();
            videoNo = lastItem.replace("VD0", "");
            a = parseInt(videoNo);
            array = videoNo.split("VD");
            b = parseInt(array[1]);
            if (array[1] !== undefined && array[1] !== null && array[1] !== "") {
              data = b + 1;
            } else {
              data = a + 1;
            }
            if (data !== undefined && data !== null) {
              newVideo = data <= 9 ? "VD0" + data : "VD" + data;
            }
          }

          return data;
        });

        return item;
      });
      AddFeature({
        [newVideo]: {
          Contenttype: "Video",
          Contentparams: {
            contenttag: "",
            sequenceid: "11",
            contentdescription: "",
            searchstring: ""
          }
        }
      });
      let data = [];
      data.push(ActiveModule + "_" + ActiveSlide + "_" + ActiveScreen + "_" + newVideo);
      let videoCount = [...videoCountValidate, ...data];
      setVideoCountValidate(videoCount);
    }
  };
  useEffect(() => {
    if (projectLoaded) {
      if (projects.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveImage] !== undefined) {
              Object.entries(projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveImage]).map(
                (item, key) => {
                  let data = item[1].searchstring;
                  let dataTag = item[1].contenttag;
                  if (dataTag !== undefined && dataTag != "") {
                    let _arr = dataTag.split(",");
                    setImageTagValue(_arr);
                  } else {
                    setImageTagValue([]);
                  }
                  if (data !== undefined) {
                    setImageDisValue(data);
                  } else {
                    setImageDisValue("");
                  }
                  // }
                }
              );
            }
          }
        }
      }
    }
  }, [ActiveImage, projects]);

  useEffect(() => {
    if (projectLoaded) {
      if (projects.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            if (
              projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveIllustration] !== undefined
            ) {
              Object.entries(
                projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveIllustration]
              ).map((item, key) => {
                //console.log(item);
                let data = item[1].searchstring;
                let dataTag = item[1].contenttag;
                if (dataTag !== undefined && dataTag != "") {
                  let _arr = dataTag.split(",");
                  setIllustrationTagValue(_arr);
                } else {
                  setIllustrationTagValue([]);
                }
                if (data !== undefined) {
                  setIllustrationDisValue(data);
                } else {
                  setIllustrationDisValue("");
                }
                // }
              });
            }
          }
        }
      }
    }
  }, [ActiveIllustration, projects]);

  useEffect(() => {
    if (projectLoaded) {
      if (projects.createdProject[projectId].Modules[ActiveModule] !== undefined) {
        if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined) {
          if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined) {
            if (projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveVideo] !== undefined) {
              Object.entries(projects.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen][ActiveVideo]).map(
                (item, key) => {
                  let data = item[1].searchstring;
                  let dataTag = item[1].contenttag;
                  if (dataTag !== undefined && dataTag != "") {
                    let _arr = dataTag.split(",");
                    setVideoTagValue(_arr);
                  } else {
                    setVideoTagValue([]);
                  }
                  if (data !== undefined) {
                    setVideoDisValue(data);
                  } else {
                    setVideoDisValue("");
                  }
                  // }
                }
              );
            }
          }
        }
      }
    }
  }, [ActiveVideo, projects]);

  const editImageDisValue = (event) => {
    let imadeData = {
      searchstring: event.target.value,
      contenttag: imageTagValue.toString()
    };

    EditAsset(imadeData, "image");
    setImageDisValue(event.target.value);
    changeAsset("image");
  };
  const editImageTagValue = (event) => {
    let imadeTagData = {
      sequenceid: "3",
      contentdescription: "",
      searchstring: imageDisValue,
      contenttag: event.target.value
    };
    EditAsset(imadeTagData, "image");
    setImageTagValue(event.target.value);
  };
  const editIllustrationDisValue = (event) => {
    let illuData = {
      searchstring: event.target.value,
      contenttag: illustrationTagValue.toString()
    };
    EditAsset(illuData, "illustration");
    setIllustrationDisValue(event.target.value);
    changeAsset("illustration");
  };
  const editIllustrationTagValue = (event) => {
    let illuData = {
      sequenceid: "3",
      contentdescription: "",
      searchstring: illustrationDisValue,
      contenttag: event.target.value
    };
    EditAsset(illuData, "illustration");
    setIllustrationTagValue(event.target.value);
  };
  const editVideoDisValue = (event) => {
    let videoData = {
      searchstring: event.target.value,
      contenttag: videoTagValue.toString()
    };
    EditAsset(videoData, "video");
    setVideoDisValue(event.target.value);
    changeAsset("video");
  };
  const editVideoTagValue = (event) => {
    let videoData = {
      sequenceid: "3",
      contentdescription: "",
      searchstring: videoDisValue,
      contenttag: event.target.value
    };
    EditAsset(videoData, "video");
    setVideoTagValue(event.target.value);
  };
  const changeAsset = (type) => {
    if (type === "image") {
      console.log('checkk3');
      setImageAsset(true);
      setIlluAsset(false);
      setVideoAsset(false);
    } else if (type === "illustration") {
      setImageAsset(false);
      setIlluAsset(true);
      setVideoAsset(false);
    } else if (type === "video") {
      setImageAsset(false);
      setIlluAsset(false);
      setVideoAsset(true);
    }
  };

  const handleDeleteChip = (chip, index, type) => {
    let existing_chips;
    if (type === "image") {
      let existingChip = imageTagValue;
      existingChip.splice(index, 1);
      let imadeTagData = {
        searchstring: imageDisValue,
        contenttag: existingChip.toString()
      };
      EditAsset(imadeTagData, "image");
      setImageTagValue(existingChip);
    } else if (type === "illustration") {
      let existingChip = illustrationTagValue;
      existingChip.splice(index, 1);
      let illuData = {
        searchstring: illustrationDisValue,
        contenttag: existingChip.toString()
      };
      EditAsset(illuData, "illustration");
      setIllustrationTagValue(existingChip);
    } else if (type === "video") {
      let existingChip = videoTagValue;
      existingChip.splice(index, 1);
      let videoData = {
        searchstring: videoDisValue,
        contenttag: existingChip.toString()
      };
      EditAsset(videoData, "video");
      setVideoTagValue(existingChip);
    }
  };

  const handleTagChange = (chip, type) => {
    //console.log(chip);
    if (type === "image") {
      if (chip.length < 2) {
        setImageTagError("Tag value must not be one character");
      } else {
        let existingChip = imageTagValue;
        existingChip.push(chip.toString());
        let imageTagData = {
          searchstring: imageDisValue,
          contenttag: existingChip.toString()
        };
        EditAsset(imageTagData, "image");
        setImageTagValue(existingChip);
        setImageTagError(undefined);
      }
    } else if (type === "illustration") {
      if (chip.length < 2) {
        setIlluTagError("Tag value must not be one character");
      } else {
        let existingChip = illustrationTagValue;
        existingChip.push(chip.toString());
        let illuData = {
          searchstring: illustrationDisValue,
          contenttag: existingChip.toString()
        };
        EditAsset(illuData, "illustration");
        setIllustrationTagValue(existingChip);
        setIlluTagError(undefined);
      }
    } else if (type === "video") {
      if (chip.length < 2) {
        setVideoTagError("Tag value must not be one character");
      } else {
        let existingChip = videoTagValue;
        existingChip.push(chip.toString());
        let videoData = {
          searchstring: videoDisValue,
          contenttag: existingChip.toString()
        };
        EditAsset(videoData, "video");
        setVideoTagValue(existingChip);
        setVideoTagError(undefined);
      }
    }
  };

  return (
    <div>
      <div className="cm-vs-blk">
        <div className="cm-vs-wrapper">
          <div className="cm-vs-header">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center panel-label">
                Visual Assets
                <div className="info-icon ps-2">
                  <Tooltip title="Three Images,Three Illustartions and One video is allowed for a particular screen" arrow>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                        fill="#C0C2C4"
                      />
                    </svg>
                  </Tooltip>
                </div>
              </div>
              {/* {project.createdProject !== undefined &&
              project.createdProject[projectId] !== undefined &&
              project.createdProject[projectId].Modules !== undefined &&
              project.createdProject[projectId].Modules[ActiveModule] !== undefined &&
              project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined &&
              project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined &&
              Object.keys(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).filter(
                (n) => n.indexOf("IM") > -1
              ).length <= 2 &&
              imageAsset ? (
                <div onClick={() => addVisualEffects()} className="d-flex align-items-center cm-add-label">
                  <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z"
                      fill=""
                    />
                    <path
                      d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z"
                      fill=""
                    />
                  </svg>
                  <span>Add Asset</span>
                </div>
              ) : project.createdProject !== undefined &&
                project.createdProject[projectId] !== undefined &&
                project.createdProject[projectId].Modules !== undefined &&
                project.createdProject[projectId].Modules[ActiveModule] !== undefined &&
                project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined &&
                project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined &&
                Object.keys(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).filter(
                  (n) => n.indexOf("IL") > -1
                ).length <= 3 &&
                illuAsset ? (
                <div onClick={() => addVisualEffects()} className="d-flex align-items-center cm-add-label">
                  <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z"
                      fill=""
                    />
                    <path
                      d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z"
                      fill=""
                    />
                  </svg>
                  <span>Add Asset</span>
                </div>
              ) : project.createdProject !== undefined &&
                project.createdProject[projectId] !== undefined &&
                project.createdProject[projectId].Modules !== undefined &&
                project.createdProject[projectId].Modules[ActiveModule] !== undefined &&
                project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide] !== undefined &&
                project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen] !== undefined &&
                Object.keys(project.createdProject[projectId].Modules[ActiveModule].slides[ActiveSlide].Screens[ActiveScreen]).filter(
                  (n) => n.indexOf("VD") > -1
                ).length <= 0 &&
                videoAsset ? (
                <div onClick={() => addVisualEffects()} className="d-flex align-items-center cm-add-label">
                  <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z"
                      fill=""
                    />
                    <path
                      d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z"
                      fill=""
                    />
                  </svg>
                  <span>Add Asset</span>
                </div>
              ) : (
                <div className="d-flex align-items-center cm-add-label not-allowed">
                  <svg className="me-2" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.47266 3.17188H9.52734C9.62109 3.17188 9.66797 3.21875 9.66797 3.3125V15.6875C9.66797 15.7812 9.62109 15.8281 9.52734 15.8281H8.47266C8.37891 15.8281 8.33203 15.7812 8.33203 15.6875V3.3125C8.33203 3.21875 8.37891 3.17188 8.47266 3.17188Z"
                      fill=""
                    />
                    <path
                      d="M3.09375 8.83203H14.9062C15 8.83203 15.0469 8.87891 15.0469 8.97266V10.0273C15.0469 10.1211 15 10.168 14.9062 10.168H3.09375C3 10.168 2.95312 10.1211 2.95312 10.0273V8.97266C2.95312 8.87891 3 8.83203 3.09375 8.83203Z"
                      fill=""
                    />
                  </svg>
                  {imageAsset ? (
                    <Tooltip title="Only 3 Images allowed" arrow>
                      <span className="not-allowed">Add Asset</span>
                    </Tooltip>
                  ) : illuAsset ? (
                    <Tooltip title="Only 3 Illustrations allowed" arrow>
                      <span className="not-allowed">Add Asset</span>
                    </Tooltip>
                  ) : (
                    <Tooltip title=" Only 1 Video allowed" arrow>
                      <span className="not-allowed">Add Asset</span>
                    </Tooltip>
                  )}
                </div>
              )} */}
            </div>
          </div>
          <div className="cm-vs-division">
            <div className="navtab-selector">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <button
                    onClick={() => changeAsset("image")}
                    className={imageAsset === true ? "nav-link active" : "nav-link"}
                    id="image-tab"
                    //   data-bs-toggle="tab"
                    //   data-bs-target="#cm-image"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    disabled={videoDisValue.length || videoTagValue.length ? true : false}
                    >
                    <div className="cm-vs-container">
                      <div className="cm-vs-icon-holder cm-asset-icon mb-2">
                        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M22.5455 7.75H8.45455C8.06544 7.75 7.75 8.06544 7.75 8.45455V22.5455C7.75 22.9346 8.06544 23.25 8.45455 23.25H22.5455C22.9346 23.25 23.25 22.9346 23.25 22.5455V8.45455C23.25 8.06544 22.9346 7.75 22.5455 7.75Z"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M23.25 18.013L19.5209 14.408C19.3888 14.2803 19.2096 14.2085 19.0227 14.2085C18.8359 14.2085 18.6567 14.2803 18.5245 14.408L14.5891 18.2125C14.457 18.3402 14.2778 18.4119 14.0909 18.4119C13.9041 18.4119 13.7249 18.3402 13.5927 18.2125L11.7709 16.4513C11.6388 16.3235 11.4596 16.2518 11.2727 16.2518C11.0859 16.2518 10.9067 16.3235 10.7745 16.4513L7.75 19.3752"
                            strokeWidth="1.3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M12.9167 14.2083C13.63 14.2083 14.2083 13.63 14.2083 12.9167C14.2083 12.2033 13.63 11.625 12.9167 11.625C12.2033 11.625 11.625 12.2033 11.625 12.9167C11.625 13.63 12.2033 14.2083 12.9167 14.2083Z" />
                        </svg>
                      </div>
                      <div className="cm-asset-name">Image</div>
                      <div className="tick-active">
                        <img src="../../../assets/img/asset-tick.svg" />
                      </div>
                    </div>
                  </button>
                </li>
                {/* <li className="nav-item">
                  <button
                    onClick={() => changeAsset("illustration")}
                    className={
                      illuAsset === true ? "nav-link active" : "nav-link"
                    }
                    //   className="nav-link"
                    id="illus-tab"
                    //   data-bs-toggle="tab"
                    //   data-bs-target="#cm-illus"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    <div className="cm-vs-container">
                      <div className="cm-vs-icon-holder cm-asset-icon mb-2">
                        <svg
                          width="23"
                          height="23"
                          viewBox="0 0 23 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.5 13.6567V22.2817"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11.5 13.6567C12.4924 13.6567 13.2969 12.8522 13.2969 11.8599C13.2969 10.8675 12.4924 10.063 11.5 10.063C10.5076 10.063 9.70312 10.8675 9.70312 11.8599C9.70312 12.8522 10.5076 13.6567 11.5 13.6567Z"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.46875 6.46924V2.87549C6.46875 2.68486 6.54448 2.50205 6.67927 2.36726C6.81406 2.23246 6.99688 2.15674 7.1875 2.15674H15.8125C16.0031 2.15674 16.1859 2.23246 16.3207 2.36726C16.4555 2.50205 16.5312 2.68486 16.5312 2.87549V6.46924"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6.46847 6.46924L4.00795 11.4493C3.94957 11.5675 3.9248 11.6995 3.93637 11.8308C3.94793 11.9621 3.99538 12.0877 4.07352 12.1938L11.4997 22.2817L18.9259 12.1938C19.004 12.0876 19.0515 11.962 19.063 11.8307C19.0746 11.6994 19.0498 11.5675 18.9915 11.4493L16.531 6.46924H6.46847Z"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="cm-asset-name">Illustration</div>
                      <div className="tick-active">
                        <img src="../../../assets/img/asset-tick.svg" />
                      </div>
                    </div>
                  </button>
                </li> */}
                <li className="nav-item">
                  <button
                    onClick={() => changeAsset("video")}
                    className={videoAsset === true ? "nav-link active" : "nav-link"}
                    id="video-tab"
                    //   data-bs-toggle="tab"
                    //   data-bs-target="#cm-video"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    disabled={imageDisValue.length || imageTagValue.length ? true : false}
                    >
                    <div className="cm-vs-container">
                      <div className="cm-vs-icon-holder cm-asset-icon mb-2">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.25 4.5H3.75C3.33579 4.5 3 4.83579 3 5.25V18.75C3 19.1642 3.33579 19.5 3.75 19.5H20.25C20.6642 19.5 21 19.1642 21 18.75V5.25C21 4.83579 20.6642 4.5 20.25 4.5Z"
                            stroke="#C0C2C4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path d="M19.125 7.5H21" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M19.125 16.5H21" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M3 7.5H4.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M3 16.5H4.875" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8.625 7.5H10.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M8.625 16.5H10.125" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.875 7.5H15.375" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M13.875 16.5H15.375" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </div>
                      <div className="cm-asset-name">Video</div>
                      <div className="tick-active">
                        <img src="../../../assets/img/asset-tick.svg" />
                      </div>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-pane-block">
              <div className="tab-content">
                <div
                  className={imageAsset === true ? "tab-pane show active" : "tab-pane show"}
                  // className="tab-pane show active"
                  id="cm-image"
                  role="tabpanel"
                  aria-labelledby="image-tab">
                  <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
                    <div className="d-flex cm-full-lst-wrapper">
                      {projectLoaded && listImage()}
                      {/* <div
                      className="panel-label cm-gen-spec cm-full-lst-container active"
                      title=""
                    >
                      Image 02
                      <div className="cm-del-icon">&times;</div>
                    </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="d-flex align-items-center panel-label">
                        Description
                        <div className="info-icon ps-2">
                          <Tooltip title="Add ost description" arrow>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                                fill="#C0C2C4"
                              />
                            </svg>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="panel-txtarea-blk asset-txtarea mt-2">
                        <textarea
                          value={imageDisValue}
                          onChange={editImageDisValue}
                          placeholder="Add Description for Asset Generation/ Uploaded Asset"
                          className="panel-txtarea"></textarea>
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center panel-label">
                        Add Tags
                        <div className="info-icon ps-2">
                          <Tooltip title="Add ost description" arrow>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                                fill="#C0C2C4"
                              />
                            </svg>
                          </Tooltip>
                        </div>
                      </div>
                      {/* <div className="add-tag-blk mt-2"> */}
                      <div className="panel-txtarea-blk asset-txtarea mt-2">
                        <ChipInput
                          value={imageTagValue}
                          onAdd={(chips) => handleTagChange(chips, "image")}
                          className="panel-txtarea"
                          onDelete={(chip, index) => handleDeleteChip(chip, index, "image")}
                        />
                      </div>
                      <div className=" input-error-msg">
                        {ImageTagError != undefined && ImageTagError}
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div
                  className={illuAsset === true ? "tab-pane show active" : "tab-pane show"}
                  id="cm-illus"
                  role="tabpanel"
                  aria-labelledby="image-tab">
                  <div className="d-flex justify-content-between cm-full-lst-blk cm-illus-blk mb-3">
                    <div className="d-flex cm-full-lst-wrapper">
                      {projectLoaded && listIllustration()}
                  
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="d-flex align-items-center panel-label">
                        Description
                        <div className="info-icon ps-2">
                          <Tooltip title="Add ost description" arrow>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                                fill="#C0C2C4"
                              />
                            </svg>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="panel-txtarea-blk asset-txtarea mt-2">
                        <textarea
                          value={illustrationDisValue}
                          onChange={editIllustrationDisValue}
                          placeholder="Add Description for Asset Generation/ Uploaded Asset"
                          className="panel-txtarea"></textarea>
                      </div>
                    </div>

                    <div className="col">
                      <div className="d-flex align-items-center panel-label">
                        Add Tags
                        <div className="info-icon ps-2">
                          <Tooltip title="Add ost tags" arrow>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                                fill="#C0C2C4"
                              />
                            </svg>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="panel-txtarea-blk asset-txtarea mt-2">
                        <ChipInput
                          value={illustrationTagValue}
                          onAdd={(chips) => handleTagChange(chips, "illustration")}
                          className="panel-txtarea"
                          onDelete={(chip, index) => handleDeleteChip(chip, index, "illustration")}
                        />
                      </div>
                      <div className="input-error-msg">
                        {IlluTagError != undefined && IlluTagError}
                      </div>
                    </div>
                  </div>
                </div> */}

                <div
                  className={videoAsset === true ? "tab-pane show active" : "tab-pane show"}
                  id="cm-video"
                  role="tabpanel"
                  aria-labelledby="image-tab">
                  <div className="d-flex justify-content-between cm-full-lst-blk mb-3">
                    <div className="d-flex cm-full-lst-wrapper">
                      {projectLoaded && listVideo()}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="d-flex align-items-center panel-label">
                        Description
                        <div className="info-icon ps-2">
                          <Tooltip title="Add ost description" arrow>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                                fill="#C0C2C4"
                              />
                            </svg>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="panel-txtarea-blk asset-txtarea mt-2">
                        <textarea
                          value={videoDisValue}
                          onChange={editVideoDisValue}
                          placeholder="Add Description for Asset Generation/ Uploaded Asset"
                          className="panel-txtarea"></textarea>
                      </div>
                    </div>
                    <div className="col">
                      <div className="d-flex align-items-center panel-label">
                        Add Tags
                        <div className="info-icon ps-2">
                          <Tooltip title="Add ost tags" arrow>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M7 14C8.85652 14 10.637 13.2625 11.9497 11.9497C13.2625 10.637 14 8.85652 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 8.85652 0.737498 10.637 2.05025 11.9497C3.36301 13.2625 5.14348 14 7 14ZM7.81375 5.7645L6.93875 9.88138C6.8775 10.1789 6.96413 10.3477 7.20475 10.3477C7.3745 10.3477 7.63088 10.2865 7.805 10.1325L7.728 10.4965C7.47687 10.7993 6.923 11.0197 6.44613 11.0197C5.831 11.0197 5.56938 10.6505 5.73913 9.86562L6.38487 6.83113C6.44088 6.57475 6.39013 6.482 6.13375 6.41988L5.73913 6.349L5.81087 6.01562L7.81462 5.7645H7.81375ZM7 4.8125C6.76794 4.8125 6.54538 4.72031 6.38128 4.55622C6.21719 4.39212 6.125 4.16956 6.125 3.9375C6.125 3.70544 6.21719 3.48288 6.38128 3.31878C6.54538 3.15469 6.76794 3.0625 7 3.0625C7.23206 3.0625 7.45462 3.15469 7.61872 3.31878C7.78281 3.48288 7.875 3.70544 7.875 3.9375C7.875 4.16956 7.78281 4.39212 7.61872 4.55622C7.45462 4.72031 7.23206 4.8125 7 4.8125Z"
                                fill="#C0C2C4"
                              />
                            </svg>
                          </Tooltip>
                        </div>
                      </div>
                      <div className="panel-txtarea-blk asset-txtarea mt-2">
                        <ChipInput
                          value={videoTagValue}
                          onAdd={(chips) => handleTagChange(chips, "video")}
                          className="panel-txtarea"
                          onDelete={(chip, index) => handleDeleteChip(chip, index, "video")}
                        />
                      </div>
                      <div className="input-error-msg">
                        {VideoTagError != undefined && VideoTagError}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Syles /> */}
      <ConfirmDelete
        isOpen={openUpdateModal}
        Closemodal={setopenUpdateModal}
        Content="Do you want to delete this?"
        Header="Are you sure"
        Buttonlabel="Yes, Delete it!"
        Buttonclick={DeleteFeature}
        deleteAndValidate={deleteAndValidate}
        del_id={del_id}
        del_type={del_type}
      />
    </div>
  );
};

const ConfirmDelete = (props) => {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  useEffect(() => {}, [props]);

  return (
    <Modal
      isOpen={props.isOpen}
      fade={true}
      centered
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered custom-modal delete-modal dark modal-md"
          : "modal-dialog  modal-dialog-centered custom-modal delete-modal modal-md"
      }
      // className="modal-dialog  modal-dialog-centered custom-modal delete-modal dark modal-md"
    >
      <ModalBody>
        <div className="modal-body p-4">
          <div
            className="modal-close text-right"
            title="Close"
            data-bs-dismiss="modal"
            onClick={() => {
              props.Closemodal(false);
            }}>
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.7487 15.5463C16.9095 15.7059 17 15.9231 17 16.1497C17 16.3763 16.9095 16.5935 16.7487 16.7531C16.5892 16.9139 16.3721 17.0044 16.1456 17.0044C15.9191 17.0044 15.7019 16.9139 15.5424 16.7531L8.5 9.69972L1.45758 16.7531C1.29807 16.9139 1.08094 17.0044 0.854433 17.0044C0.627923 17.0044 0.410792 16.9139 0.251283 16.7531C0.0904604 16.5935 0 16.3763 0 16.1497C0 15.9231 0.0904604 15.7059 0.251283 15.5463L7.3022 8.5015L0.251283 1.45666C0.035798 1.2411 -0.0483584 0.926912 0.0305146 0.632452C0.109387 0.337993 0.339307 0.107994 0.633665 0.0290942C0.928023 -0.049806 1.2421 0.0343795 1.45758 0.249939L8.5 7.30328L15.5424 0.249939C15.8755 -0.083287 16.4156 -0.083287 16.7487 0.249939C17.0818 0.583164 17.0818 1.12343 16.7487 1.45666L9.69781 8.5015L16.7487 15.5463Z"
                fill=""
              />
            </svg>
          </div>
          <h3>{props.Header}</h3>
          <p>{props.Content != "" ? props.Content : ""}</p>
          <button
            type="button"
            className="btn btn-primary "
            onClick={() => {
              if (props.del_id != undefined) {
                props.Buttonclick(props.del_id, props.del_type);
                props.Closemodal(false);
                props.deleteAndValidate();
              } else {
                props.Buttonclick();
                props.Closemodal(false);
                props.deleteAndValidate();
              }
            }}>
            {props.Buttonlabel}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default AddVisualEffect;
