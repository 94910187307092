import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { createUsers } from "../../../services/authService";
import { showToaster, getUserItem } from "../../../services/helper";
import * as Yup from "yup";
import Select from "react-select";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { useStateWithCallbackLazy } from "use-state-with-callback";
// import spacetime from "spacetime";
import timezones from "timezones-list";
import {
  getTimeZones,
  rawTimeZones,
  timeZonesNames,
  abbreviations,
} from "@vvo/tzdb";
// import TimezoneSelect from 'react-timezone-select'
export default function CreateUser(props) {
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [errorStatus, setErrorStatus] = useState();
  const [uploadProgress, setUploadProgress] = useState(false);
  // const [tz, setTz] = useState(
  //   Intl.DateTimeFormat().resolvedOptions().timeZone
  // );
  const [listtimezone, setListtimezone] = useState({
    value: "TimeZone",
    label: "TimeZone",
 
  });
  const [fonts, setfonts] = useStateWithCallbackLazy();
  // const [datetime, setDatetime] = useState(spacetime.now());
  let userId = getUserItem("id");
  let userNames = getUserItem("name");
  let userNames1 = getUserItem("firstName");
  let userNames2 = getUserItem("lastName");
  let AdminRole = getUserItem("role");
  let userParentId = getUserItem("orgId");
  // const timeZones = getTimeZones();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      userName: "",
      lastName: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .trim()
        .email("Email is invalid")
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Email is required"),
      password: Yup.string()
        .trim()
        .matches(
          // "^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$",
          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        )
        .required("Password is required"),
      userName: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(1, "Minimum 1 characters are required")
        .required("Name is required"),
      lastName: Yup.string()
        .trim()
        .max(20, "Must be exactly 20 characters")
        .min(1, "Minimum 1 characters are required")
        .required("Last Name is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      console.log("here", values);
      values.Added_by = userNames!==undefined?userNames: userNames1+userNames2;
      values.orgId = AdminRole == 0 ? userId : userParentId;
      values.organizationName="organization"
      values.childUserId = userId;
      // values.name = values.userName + " " + values.lastName;
      values.timezone=listtimezone.label
      setUploadProgress(true);
      try {
        createUsers(values).then((response) => {
          console.log(response);
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setUploadProgress(false);
            if (
              response.data.result !== "" &&
              response.data.result !== undefined &&
              response.data.result !== null
            ) {
              props.setnewUsers(values.userName);
              values.userName = "";
              values.email = "";
              values.password = "";
              values.lastName = "";
              showToaster("user created successfully", "success");
              props.closePreview();
            } else {
              showToaster(response.message, "warning");
            }
          } else {
            console.log(response);
            showToaster(response.message, "warning");
            // this.setState({ errorStatus: response.message })
            // setErrorStatus(response.message);
            setUploadProgress(false);
          }
        });
      } catch (e) {
        setUploadProgress(false);
        showToaster("Something went wrong. Please Try Again", "warning");
        // this.setState({ errorStatus: e.response.data.errors })
        // setErrorStatus(e.response.data.errors);
      }
    },
  });
  // useMemo(() => {
  //   const tzValue = tz.value ?? tz;
  //   setDatetime(datetime.goto(tzValue));
  // }, [tz]);
  useEffect(() => {
    let ddd;
    timezones.map((data) => {
      ddd = data;
    });
    setListtimezone({
      name:ddd.tzCode,
      label:ddd.label
    });
    // values = ""
  }, [props.createUserModal]);
  // useEffect(() => {
  //   console.log(listtimezone, "getTimeZones");
  // }, [listtimezone]);
  useEffect(() => {
    console.log(listtimezone);
    generateFonts();
  }, [listtimezone]);
  // const openRoleTable = () => {
  //   history.push("/user-roll");
  // };
  const generateFonts = () => {
    if (listtimezone != undefined) {
      let fontOption = [];
      timezones.map((font) => {
        fontOption.push({
          value: font.tzCode,
          label: font.label,
        });
        return true;
      });
      console.log(fontOption);
      setfonts(fontOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };
  const eyeButton = () => {
    // var passEye = document.querySelector('.pass-eye');
    // passEye.onclick = ()=>{
    const passHolder = document.querySelector(".password-holder");
    passHolder.classList.toggle("active");
    var x = document.getElementsByClassName("password-input")[0];
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    // }
  };

  // const customStyles = {
  //   option: (provided, state) => ({
  //     ...provided,
  //     borderBottom: '1px dotted pink',
  //     color: state.isSelected ? 'red' : 'blue',
  //     padding: 20,
  //   }),
  //   control: () => ({
  //     // none of react-select's styles are passed to <Control />
  //     width: 200,
  //   }),
  //   singleValue: (provided, state) => {
  //     const opacity = state.isDisabled ? 0.5 : 1;
  //     const transition = 'opacity 300ms';
  
  //     return { ...provided, opacity, transition };
  //   }
  // }


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7,
    }),
    control: () => ({
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#f0efef",
      color: "#fff",
      height: "32px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      color: "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };
  const handleChange = async (e) => {
    console.log(e, "eeeeeeeeeeeeeeeeeee");
    setListtimezone(e);
  };

  return (
    <Modal
      isOpen={props.createUserModal}
      fade={true}
      centered
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered custom-modal dark add-category "
          : "modal-dialog  modal-dialog-centered custom-modal  add-category"
      }
      // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
    >
      <ModalBody>
        <form onSubmit={formik.handleSubmit} id="login-form">
          <div className="modal-body">
            <div className="text-center mb-3">
              <h2>Add User</h2>
            </div>
            <p className="text-center">
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit amet,
              consectetur{" "} */}
            </p>

            <div className="mb-3">
              <label for="" className="form-label">
                Select TimeZone
              </label>
              <Select
                className="custom-input rolluser w-100 "
                styles={customStyles}
                value={listtimezone}
                onChange={(e) => handleChange(e)}
                options={fonts}
              />
              {/* {formik.touched.lastName && formik.errors.lastName ? (
                <div className="input-error-msg">{formik.errors.lastName}</div>
              ) : null} */}
            </div>

            <div className="mb-3">
              <label for="" className="form-label">
                First Name
              </label>
              <input
                id="userName"
                name="userName"
                type="userName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.userName}
                className="custom-input"
                placeholder="Enter your Username"
                disabled={uploadProgress}
              />{" "}
              {formik.touched.userName && formik.errors.userName ? (
                <div className="input-error-msg">{formik.errors.userName}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label for="" className="form-label">
                Last Name
              </label>
              <input
                id="lastName"
                name="lastName"
                type="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
                className="custom-input"
                placeholder="Enter your Last Name"
                disabled={uploadProgress}
              />{" "}
              {formik.touched.lastName && formik.errors.lastName ? (
                <div className="input-error-msg">{formik.errors.lastName}</div>
              ) : null}
            </div>

            <div className="mb-3">
              <label for="" className="form-label">
                Email Id{" "}
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className="custom-input"
                placeholder="Enter your Email"
                disabled={uploadProgress}
              />
            </div>
            {formik.touched.email && formik.errors.email ? (
              <div className="input-error-msg">{formik.errors.email}</div>
            ) : null}
            <div className="mb-3">
              <label for="" className="form-label">
                Password{" "}
              </label>
              <input
                id="password"
                name="password"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className="custom-input password-input"
                placeholder="Enter your Password"
                disabled={uploadProgress}
              />
            </div>
            {formik.touched.password && formik.errors.password ? (
              <div className="input-error-msg">{formik.errors.password}</div>
            ) : null}
            <div className="mb-3">
              <label for="" className="form-label">
                Confirm Password{" "}
              </label>

              <input
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                className="custom-input password-input"
                placeholder="Enter Confirm Password"
                disabled={uploadProgress}
              />
            </div>
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="input-error-msg">
                {formik.errors.confirmPassword}
              </div>
            ) : null}

            <div
              className="input-error-msg"
              style={{ visibility: errorStatus ? "visible" : "hidden" }}
            >
              {errorStatus}
            </div>
            <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-4">
              <button
                type="button"
                onClick={() => {
                  {
                    props.closePreview();
                  }
                  {
                    formik.values.email = "";
                  }
                  {
                    formik.errors.email = false;
                  }
                  {
                    formik.touched.email = false;
                  }
                  {
                    formik.values.confirmPassword = "";
                  }
                  {
                    formik.errors.confirmPassword = false;
                  }
                  {
                    formik.touched.confirmPassword = false;
                  }
                  {
                    formik.values.password = "";
                  }
                  {
                    formik.errors.password = false;
                  }
                  {
                    formik.touched.password = false;
                  }
                  {
                    formik.values.userName = "";
                  }
                  {
                    formik.errors.userName = false;
                  }
                  {
                    formik.touched.userName = false;
                  }
                  {
                    formik.values.lastName = "";
                  }
                  {
                    formik.errors.lastName = false;
                  }
                  {
                    formik.touched.lastName = false;
                  }
                }}
                className="btn btn-outline rounded-0 me-2"
                data-bs-dismiss="modal"
              >
                CANCEL
              </button>
              <button type="submit" className="btn btn-primary  rounded-0">
                ADD USER
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
