import Swal from "sweetalert2";
import jwt from "jwt-simple";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const showToaster = (message, type) => {
  Toast.fire({
    icon: type,
    title: message,
  });
};

export const ShowAlertBox = (title, message, type) => {
  Swal.fire(title, message, type);

  // Swal.fire({
  //     // position: 'top-end',
  //     icon: type,
  //     title: message,
  //     showConfirmButton: false,
  //     timer: 1500
  // })
};
export const getUserItem = (type) => {
  let getUserInfo = decodeEncodedItem(localStorage.getItem("_olive_user"));
  //console.log(getUserInfo,"getUserInfo");
  if (type === "email") {
    return getUserInfo.email;
  } else if (type === "status") {
    return getUserInfo.status;
  } else if (type === "user_type") {
    return getUserInfo.user_type;
  } else if (type === "role") {
    return getUserInfo.role;
  } else if (type === "firstName") {
    return getUserInfo.firstName;
  } else if (type === "lastName") {
    return getUserInfo.lastName;
  } else if (type === "name") {
    return getUserInfo.name;
  } else if (type === "orgId") {
    return getUserInfo.orgId;
  } else if (type === "timeZone") {
    return getUserInfo.timeZone;
  }
  else if (type === "templateFeature") {
    return getUserInfo.templateFeature;
  } else if (type === "id") {
    // return '6226c5789ae7dd51adefd4c2'; //Prince USERID
    // return '6203723dbda41c5e26d539aa'
    return getUserInfo.id;
  } else if (type === "welcomemessage") {
    return getUserInfo.welcomemessage;
  }
};

const decodeEncodedItem = (decodedItem) => {
  if (decodedItem !== "" && decodedItem !== undefined && decodedItem !== null) {
    decodedItem = JSON.parse(decodedItem);
    decodedItem = jwt.decode(
      decodedItem,
      process.env.REACT_APP_JWT_SECRET,
      "HS512"
    );
  }
  return decodedItem;
};

export const isColor = (strColor) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
};

export const imageExists = (url, callback) => {
  var img = new Image();
  img.onload = function (error) {
    callback(true);
  };
  img.onerror = function (error) {
    callback(false);
  };
  img.src = url;
};

export const download = (url) => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.rel = "noreferrer";
  a.download = url.split("/").pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const RandomGenerator = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const delay = (ms) => {
  let timerObj = null;
  return () => {
    clearTimeout(timerObj);
    return new Promise(resolve => {
      timerObj = setTimeout(resolve, ms)
    })
  }
}

export const convertDateToUserTimeZone=(date)=>{
  let dateTime = new Date(date) 
  let timeZone = getUserItem('timeZone')
  return timeZone?(Date(dateTime.toLocaleDateString('en-CA',{timeZone}))+" / "+dateTime.toLocaleTimeString('en-US',{timeZone})):(dateTime.toLocaleDateString('en-CA')+" / "+dateTime.toLocaleTimeString('en-US'))
}