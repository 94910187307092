import React, { useState, lazy, Suspense, useEffect, useCallback } from "react";
import { useHistory, useRouteMatch, Route } from "react-router-dom";
import Header from "../components/header/header";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrgusers,
  getChildusers,
  updateUserStatus,
  getOrganisation,
} from "../../services/authService";
import Select from "react-select";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { showToaster, getUserItem } from "../../services/helper";
import PageLoader from "../../services/pageLoader";
import Pagination from "../dashboard/project-priview/RightBar/pagination";
// import UserCreateModal from "./createUser";
import { debounce } from "debounce";
import DailyMotionPlayer from "react-player/dailymotion";
import SaveChangeModal from "../../services/SaveChangeModal";
import { Tooltip } from "reactstrap";
export default function OrganizationList() {
  const [listUsers, setListUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [newUser, setnewUser] = useState(undefined);
  const [pageLoad, setPageLoad] = useState(false);
  let userId = getUserItem("id");
  let userName = getUserItem("name");
  let userEmail = getUserItem("email");
  let AdminRole = getUserItem("role");
  let userParentId = getUserItem("orgId");
  const history = useHistory();
  // console.log("indexOfLastPost: ", indexOfLastPost);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);
  const [posts, setPosts] = useState([]);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const [listFilterStatus, setListFilterStatus] = useState([]);
  const [SearchBoxErrorMsg, setSearchBoxErrorMsg] = useState(false);
  const [sortToggle, setSortToggle] = useState(false);
  const [sortUpdateToggle, setSortUpdateToggle] = useState(false);
  const [keyWord, setKeyWord] = useState("");
  const [statusChanged, setStatusChanged] = useState(false);
  const [listStatus, setListStatus] = useState({
    value: "All",
    isActive: 0,
  });
  const [activeStatus, setActiveStatus] = useState(false);
  const [statusType, setStatusType] = useState(undefined)
  const [statusData, setStatusData] = useState(undefined)
  const [statusOption, setStatusOption] = useStateWithCallbackLazy();
  useEffect(() => {
    getOrganisationUsers("", -1, -1);
  }, []);
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  useEffect(() => {
    if (newUser !== undefined && newUser !== "" && newUser !== null) {
      getOrganisationUsers("", -1, -1);
      setnewUser(undefined);
    }
  }, [newUser]);
  const viewCreateUser = (image) => {
    history.push("/create-organization");
  };
  const updateUserStatusq = () => {
    // console.log(data);
    let userData = statusData;
    let type=statusType
    if (type == "activate") {
      userData.isActive = 1;
    } else {
      userData.isActive = 0;
    }
    let types = "organization";
    // console.log(typeof userData);
    if (userData !== undefined && userData._id !== undefined) {
      console.log("response", "response");
      updateUserStatus(userData._id, userData, types).then((response) => {
        try {
          setPageLoad(true);
          if (
            response !== "" &&
            response !== undefined &&
            response !== null &&
            response.data !== "" &&
            response.data !== undefined &&
            response.data !== null
          ) {
            setPageLoad(false);
            if (response.data.result != undefined) {
              console.log(response, "response");
              getOrganisationUsers(keyWord, -1, -1);
            }
          } else {
            setPageLoad(false);
            showToaster("Something went wrong. Please Try Again1", "warning");
          }
        } catch (error) {
          console.log(error);
          setPageLoad(false);
          showToaster("Something went wrong. Please Try Again", "warning");
        }
      });
    }
  };
  const getOrganisationUsers = (keyword, sortCreate, sortupdated) => {
    let filter = {
      params: 0,
      // isActive: listStatus.isActive,
      // orgId: userParentId,
      keyword: keyword,
      sortCreateAt: sortCreate,
      sortUpdatedAt: sortupdated,
    };
    setPageLoad(true);
    getOrganisation(filter).then((response) => {
      console.log(response, "response");
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          // this.fontArray = response.data
          if (response.data.result != undefined) {
            let projectList1 = response.data.result.data;
            setUsers(projectList1);
            setPosts(projectList1);

            setPageLoad(false);
          }
          //generateFonts();
        }
      } catch (error) {
        setPageLoad(false);
      }
    });
  };
  const backToDashboard = () => {
    history.push("/project-list");
  };

  const __searchByClick = () => {
    setSearchBoxErrorMsg(false);
    if (keyWord.trim() !== "") {
      getInitialProjectList(keyWord.trim());
    } else {
      setSearchBoxErrorMsg(true, () => {
        setTimeout(() => {
          setSearchBoxErrorMsg(false);
        }, 10000);
      });
    }
  };
  const __serachbox = (val) => {
    try {
      //console.log(val.target.value);
      setSearchBoxErrorMsg(false);
      //setKeyWord(val.target.value);

      if (keyWord !== val.target.value.trim()) {
        setKeyWord(val.target.value);
        // getInitialProjectList(val.target.value.trim());;
        getSearchBoxResult(val.target.value);
      } else {
        if (keyWord.trim() !== "") {
          setKeyWord(val.target.value);
        } else {
          setKeyWord("");
        }
      }
    } catch (error) {
      showToaster(
        "Something went wrong. Please Refresh page and Try Again",
        "warning"
      );
    }
  };
  const getSearchBoxResult = useCallback(
    debounce((value) => {
      try {
        getInitialProjectList(value.trim());
      } catch (error) {
        showToaster(
          "Something went wrong. Please Refresh page and Try Again",
          "warning"
        );
      }
    }, 1000),
    [keyWord]
  );
  const getInitialProjectList = (keyword) => {
    setSearchBoxErrorMsg(false);
    getOrganisationUsers(keyword, -1, -1);
    // paginate(1)
    // this.props.updateJson({})
    setCurrentPage(1);
  };
  const sortCreatedAtUsers = (type) => {
    if (type == "sortDown") {
      setSortToggle(true);
      getOrganisationUsers(keyWord, 1, 1);
    } else {
      setSortToggle(false);
      getOrganisationUsers(keyWord, -1, -1);
    }
  };
  const sortUpdateAtUsers = (type) => {
    if (type == "sortDown") {
      setSortUpdateToggle(true);
      getOrganisationUsers(keyWord, 1, 1);
    } else {
      setSortUpdateToggle(false);
      getOrganisationUsers(keyWord, -1, -1);
    }
  };
  const assignRole = (userList) => {
    console.log(userList, "userList");
    let userData = {
      value:
        userList.name !== undefined
          ? userList.name
          : userList.firstName + " " + userList.lastName,
      label:
        userList.name !== undefined
          ? userList.name
          : userList.firstName + " " + userList.lastName,
      id: userList._id,
      email: userList.email,
    };
    // history.push("/rollassign");
    history.push("/admin/org-global-plan");
    window.localStorage.setItem(
      "_organizationdetails",
      JSON.stringify(userData)
    );
  };
  return (
    <>
      <PageLoader showLoader={pageLoad} />
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header />
          <div className="pb-wrapper">
            <div className="row pb-header mb-4">
              <div className="col-md-12 col-lg-7">
                <div className="d-flex align-items-center flex-wrap">
                  <button
                    onClick={() => {
                      backToDashboard();
                    }}
                    type="submit"
                    className="btn btn-outline-primary text-nowrap back-button me-3 mb-0 mb-lg-0"
                    value="submit"
                  >
                    <svg
                      width="14"
                      height="11"
                      viewBox="0 0 14 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.42652e-06 5.5C0.00211858 5.41315 0.0186696 5.32723 0.0490002 5.24563C0.0651946 5.21658 0.0839324 5.18898 0.105001 5.16313C0.123314 5.12504 0.144368 5.08828 0.168001 5.05313L4.368 0.240672C4.53056 0.0540017 4.78216 -0.0333139 5.02804 0.0116137C5.27392 0.0565413 5.47672 0.226887 5.56004 0.458484C5.64336 0.690081 5.59456 0.947744 5.432 1.13441L2.219 4.81251L13.3 4.81251C13.6866 4.81251 14 5.12031 14 5.5C14 5.87969 13.6866 6.18749 13.3 6.18749L2.219 6.18749L5.432 9.86559C5.59456 10.0523 5.64337 10.3099 5.56004 10.5415C5.47672 10.7731 5.27392 10.9435 5.02804 10.9884C4.78216 11.0333 4.53056 10.946 4.368 10.7593L0.168001 5.94687C0.144368 5.91172 0.123314 5.87496 0.105001 5.83687C0.0839324 5.81102 0.0651946 5.78342 0.0490003 5.75437C0.0186696 5.67277 0.00211859 5.58685 1.42652e-06 5.5Z"
                        fill=""
                      />
                    </svg>
                  </button>

                  <div className="pb-srch-customiser me-2 mb-0 me-md-3 ">
                    <div className="custom-search srch-holder w-100 h-100">
                      <div className="srch-icon " onClick={() => __searchByClick()}>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                        >
                          <path
                            d="M14.5 25C20.299 25 25 20.299 25 14.5C25 8.70101 20.299 4 14.5 4C8.70101 4 4 8.70101 4 14.5C4 20.299 8.70101 25 14.5 25Z"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M21.9243 21.925L27.9994 28.0001"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                      <div className="srch-inpt-holder h-100 disabled">
                        <form className="h-100">
                          <input
                             onChange={(e) => __serachbox(e)}
                            required
                            value={keyWord}
                            type="text"
                            name="focus"
                            required
                            className="pe-0 form-control srch-input h-100 "
                            placeholder="Search Organization"
                          />
                          <button
                            className="search-close"
                            onClick={() => {
                              getOrganisationUsers("", -1, -1);
                              setKeyWord("");
                            }}
                            type="reset"
                          >
                            <svg
                              version="1.1"
                              id="Layer_1"
                              x="0px"
                              y="0px"
                              viewBox="0 0 512 512"
                            >
                              <g>
                                <g>
                                  <path
                                    d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M327.115,365.904
                            L256,294.789l-71.115,71.115l-38.789-38.789L217.211,256l-71.115-71.115l38.789-38.789L256,217.211l71.115-71.115l38.789,38.789
                            L294.789,256l71.115,71.115L327.115,365.904z"
                                  />
                                </g>
                              </g>
                            </svg>
                          </button>
                        </form>
                      </div>
                    </div>
                    {SearchBoxErrorMsg === true && (
                      <div className="input-error-msg">
                        Please enter a user name
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-5 ">
                <div className="d-flex flex-wrap justify-content-end">
                  <button
                    onClick={() => {
                      viewCreateUser();
                    }}
                    type="submit"
                    className="btn btn-outline-primary text-nowrap add-user-btn"
                    value="submit"
                    data-bs-toggle="modal"
                    data-bs-target="#add-user"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.125 10H16.875"
                        stroke="#C0C2C4"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 3.125V16.875"
                        stroke="#C0C2C4"
                        stroke-width="1.3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Add Organization
                  </button>
                </div>
              </div>
            </div>
            <div className="table-wraper role-mngt">
              <div className="pb-tbl-blk">
                <div className="pb-tbl-wrapper mb-3">
                  {/* <div className="pb-head-label "></div> */}
                  <div className="pb-head-label "> Organization Name </div>
                  <div className="pb-head-label text-center">Email ID</div>
                  <div className="pb-head-label text-center">Role</div>

                  <div
                    className={
                      sortToggle
                        ? "pb-head-label text-center SortDown "
                        : "pb-head-label text-center SortUp "
                    }
                    onClick={(e) => {
                      !sortToggle
                        ? sortCreatedAtUsers("sortDown")
                        : sortCreatedAtUsers("sortUp");
                    }}
                  >
                    Created Date
                  </div>
                  <div
                    className={
                      sortUpdateToggle
                        ? "pb-head-label text-center SortDown "
                        : "pb-head-label text-center SortUp "
                    }
                    onClick={(e) => {
                      !sortUpdateToggle
                        ? sortUpdateAtUsers("sortDown")
                        : sortUpdateAtUsers("sortUp");
                    }}
                  >
                    Last Modified
                  </div>
                  <div className="pb-head-label text-center">Status</div>
                  {/* <div className="pb-head-label "></div> */}
                </div>
                {currentPosts.map((userList) => {
                  console.log(userList, "userList");
                  let Created = userList.createdAt;
                  let sortedCreatedDate = Created.split("T")[0];
                  let Modified = userList.updatedAt;
                  let sorteModifieddDate = Modified.split("T")[0];
                  return (
                    <div className="pb-tbl-wrapper pb-data-tbl">
                      {/* <div className="pb-data-label "> */}
                      {/* <div className="custom-check-2">
                          <input type="checkbox" id="html-10" />
                          <label for="html-10"></label>
                        </div> */}
                      {/* </div> */}
                      <div className="pb-data-label ">
                        {userList.orgName !== undefined
                          ? userList.orgName
                          : userList.firstName + " " + userList.lastName}
                      </div>
                      <div className="pb-data-label text-center">
                        {userList.email}
                      </div>
                      <div className="pb-data-label text-center">Organization </div>
                      <div className="pb-data-label text-center">
                        {sortedCreatedDate}
                      </div>
                      <div className="pb-data-label text-center">
                        {sorteModifieddDate}
                      </div>
                      {userList.isActive == 1 ? (
                        <div className="pb-data-label text-center">
                          <span className="status-info active">Active</span>
                        </div>
                      ) : (
                        <div className="pb-data-label text-center">
                          <span className="status-info inactive">Inactive</span>
                        </div>
                      )}
                      <div className="pb-data-label pe-0 ps-0">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="pb-stat-icon dropdown">
                            <a
                              className="p-2 pointer"
                              type=""
                              id=""
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <svg
                                width="3"
                                height="15"
                                viewBox="0 0 3 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  r="1.5"
                                  transform="matrix(-1 0 0 1 1.5 1.5)"
                                />
                                <circle
                                  r="1.5"
                                  transform="matrix(-1 0 0 1 1.5 7.5)"
                                />
                                <circle
                                  r="1.5"
                                  transform="matrix(-1 0 0 1 1.5 13.5)"
                                />
                              </svg>
                            </a>
                            <div
                              className="dropdown-menu "
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <ul>
                                {userList.isActive == 1 && (
                                
                                  <li
                                    onClick={(e) => {
                                      e.preventDefault();
                                      assignRole(userList);
                                    }}
                                  >
                                    <a className="dropdown-item"  href="#">
                                    {/* <Tooltip title="Permission" arrow> */}
                                      <img src="../../assets/img/dropdown-Permission.png" title="Permission" />
                                      {/* </Tooltip>  */}
                                      <span>Add Permission</span>
                                    </a>
                                  </li>
                                )}
                                <li>
                                  <a
                                    className="dropdown-item custom-disabled"
                                    href="#"
                                  >
                                    <img src="../../assets/img/dropdown-Edit.png" />
                                    <span>Edit</span>
                                  </a>
                                </li>
                                {userList.isActive == 1 ? (
                                 
                                  <li
                                    onClick={() => {
                                      setActiveStatus(true)
                                      setStatusData(userList)
                                      setStatusType("deactivate")
                                      // updateUserStatusq(userList, "deactivate");
                                    }}
                                  >
                                    <a className="dropdown-item" >
                                      {/* <Tooltip title="Deactivate" arrow> */}
                                      <img src="../../assets/img/dropdown-deactivate.png" title="Deactivate" />
                                      {/* </Tooltip> */}
                                       <span>Deactivate</span>
                                    </a>
                                  </li>
                                ) : (
                                 
                                  <li
                                    onClick={() => {
                                      setActiveStatus(true)
                                      setStatusData(userList)
                                      setStatusType("activate")
                                      // updateUserStatusq(userList, "activate");
                                    }}
                                  >
                                    <a className="dropdown-item" title="Activate">
                                    {/* <Tooltip title="Active" arrow> */}
                                      <svg 
                                        version="1.1"
                                        id="Capa_1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 330 330"
                                        style={{
                                          fill: "#119837",
                                          marginRight: "10px",
                                          width: "15px",
                                          height: "15px",
                                        }}
                                      >
                                        <g>
                                          <path
                                            d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300
        c-74.44,0-135-60.561-135-135S90.56,30,165,30s135,60.561,135,135S239.439,300,165,300z"
                                          />
                                          <path
                                            d="M226.872,106.664l-84.854,84.853l-38.89-38.891c-5.857-5.857-15.355-5.858-21.213-0.001
        c-5.858,5.858-5.858,15.355,0,21.213l49.496,49.498c2.813,2.813,6.628,4.394,10.606,4.394c0.001,0,0,0,0.001,0
        c3.978,0,7.793-1.581,10.606-4.393l95.461-95.459c5.858-5.858,5.858-15.355,0-21.213
        C242.227,100.807,232.73,100.806,226.872,106.664z"
                                          />
                                        </g>
                                      </svg>
                                      {/* </Tooltip> */}
                                      {/* <img src="assets/img/dropdown-deactivate.png" /> */}
                                      <span>Activate</span>
                                    </a>
                                  </li>
                                )}
                                {/* <li>
                                  <a className="dropdown-item inactive">
                                    <img src="assets/img/dropdown-trash.png" />
                                    <span className="disabled">Delete</span>
                                  </a>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {users.length == 0 && (
                  <React.Fragment>
                    <div className="no-project-selected blink">
                      No users available
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>

            {/* <div className="mb-0"> */}
            {users.length > 0 && (
              <Pagination
                paginate={paginate}
                postsPerPage={postsPerPage}
                totalPosts={posts.length}
                activePages={listStatus.label === "All" ? 1 : 0}
              />
            )}
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* <UserCreateModal
        closePreview={() => setOpenCreateUser(false)}
        createUserModal={openCreateUser}
        setnewUsers={setnewUser}
        // selectedImage={selectedImageFile}
      /> */}
      <SaveChangeModal
        isOpen={activeStatus}
        Closemodal={() => setActiveStatus(false)}
        Header="Status Confirmation"
        Content={"Are you want to "+statusType+" the organization"}
        Buttonlabel1="Cancel"
        Buttonclick1={() => setActiveStatus(false)}
        Buttonlabel2="Ok"
        Buttonclick2={() => updateUserStatusq()}
      />
      {/* <RollAssignModal
        closePreview={() => setOpenCreateUser(false)}
        createUserModal={openCreateUser}
        setnewUsers={setnewUser}
        // selectedImage={selectedImageFile}
      /> */}
    </>
  );
}
