import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { debounce } from "debounce";
import {
  getTemplatesRepo,
  getTemplatesRepoManual,
} from "../../../services/api.service";
import VideoplayerWithPreview from "../../components/Videoplayer/VideoplayerWithPreview";
import ReactImageFallback from "react-image-fallback";
import Stack from "@mui/material/Stack";
// import Posts from "./posts";
import {
  CreateModule,
  CreateProjectFromResponse,
  CreateProjectModel,
  removeMediaUrlFilter,
} from "../../../services/DataHelper";
import {
  createProjects,
  createProjectsPreview,
  getProjectID,
} from "../../../services/api.service";
import {
  getProjectById,
  getTemplatePlanByOrg,
  getUsersProjectRole,
  UpdateDbProjectDatabyId,
} from "../../../services/authService";
import ImageComponent from "../../components/ImageComponent";
import Pagination from "../../dashboard/project-priview/RightBar/pagination";
import CircularProgress from "@material-ui/core/CircularProgress";
import TemplatePreview from "./TemplatePriview";
import Tooltip from "@mui/material/Tooltip";
import { getUserItem, showToaster } from "../../../services/helper";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { actionCreator } from "../../../store/index";
import { bindActionCreators } from "redux";
import { useHistory, useParams } from "react-router";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import {
  BULLETFONT,
  FONT_SIZE_H,
  FONT_SIZE_P,
  FONT_URL,
  HEADERFONT,
  INIT_OST_FONT,
  PARAGRAPHFONT,
} from "../../../constants";
import pageLoader from "../../../services/pageLoader";
import SimpleBackdrop from "../../container/pageLoader";
export default function TemplateRepo(props) {
  const [statusOption, setStatusOption] = useStateWithCallbackLazy();
  const [templateRepo, setTemplateRepo] = useState([]);
  const [templateRepoLoader, setTemplateRepoLoader] = useState(true);
  const [templateErrorMsg, setTemplateErrorMsg] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(12);
  const [openTemplatePreview, setOpenTemplatePreview] = useState(false);
  const [selectedTemplateFile, setSelectedTemplateFile] = useState(false);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const [listFilterStatus, setListFilterStatus] = useState([]);
  const [listStatus, setListStatus] = useState(undefined);
  const [ActiveModule, setActiveModule] = useState(undefined);
  const [ActiveSlide, setActiveSlide] = useState(undefined);
  const [ActiveScreen, setActiveScreen] = useState(undefined);
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [cacheProjectData, setcacheProjectData] = useState(undefined);
  const [pageLoad, setPageLoad] = useState(false);
  const [projectRolePreview, setProjectRolePreview] = useState(false);
  const [dropDownSelection, setDropDownSelection] = useState(false);
  const [nextSelectionIndex, setNextSelectionIndex] = useState(undefined);
  const [templateSelectionType, setTemplateSelectionType] = useState(undefined);
  const [templateCheckArray, settemplateCheckArray] = useState(undefined);
  const [validatingTempLoader, setvalidatingTempLoader] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [templateUrl, setTemplateUrl] = useState(undefined);
  const [projectDataUrl, setProjectData] = useState(undefined);
  const dispatch = useDispatch();
  const history = useHistory();
  const { EditProject, DeleteScreenPreview, UpdatePreview } =
    bindActionCreators(actionCreator, dispatch);
  const [openTemplateChangesModal, setopenTemplateChangesModal] =
    useState(false);
  const ScreenPreviews = useSelector((state) => state.preview.ScreenPreviews);
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  let EditMode = useSelector((state) => state.project.EditMode);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  let COUNT = 0;
  const { id } = useParams();
  let OrganiId = getUserItem("orgId");
  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // useEffect(() => {
  //   console.log(posts, "nextSelectionIndex");
  //   if(posts.length==0){
  //     setNextSelectionIndex(nextSelectionIndex+1)
  //     // setDropDownSelection(true)
  //   }
  // }, [posts,dropDownSelection]);
  const nextScreen = () => {
    console.log(project, "proooo");
    let selectedValue;
    let selectedLabel;
    let status = listStatus.status + 1;
    listFilterStatus.map((data, index) => {
      if (index === status) {
        let data1 = listStatus.status;
        let count = data1 + 1;
        console.log(count, "count");
        setNextSelectionIndex(count);
        let ScreenWisename = data.split("_");

        selectedValue = ScreenWisename[0];
        selectedLabel = ScreenWisename[1];
        status = status;
        setConfirmModal(false);
        // setNextSelectionIndex(nextSelectionIndex)
      } else if (listFilterStatus.length === nextSelectionIndex + 1) {
        console.log("ScreenWisename", "selectedValue");
        selectedLabel = "select value";
        status = "";
        setConfirmModal(true);
      }
    });
    console.log(
      listStatus,
      listFilterStatus.length,
      nextSelectionIndex,
      "selectedValue"
    );
    setListStatus({
      value: selectedValue,
      label: selectedLabel,
      status: status,
    });
    //
    if (selectedValue !== undefined) {
      if (selectedLabel !== undefined && selectedLabel !== "select screen") {
        let name = selectedValue.split("-");
        console.log(name);
        setActiveModule(name[0]);
        setActiveSlide(name[1]);
        setActiveScreen(name[2]);
      }
    }
    setCurrentPage(1);
  };
  useEffect(() => {
    let filter = {
      project_id: projectId,
    };
    let templateValidArray = [];
    // let preview = await CreatePreviewFromResponse(response.data[0]);
    Object.entries(project[projectId].Modules).map(
      (moduleData, moduleIndex) => {
        console.log(moduleData, "moduleData");
        let mData = moduleData[1];
        let slides = mData.slides;
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          console.log(slideData, "element22");
          Object.entries(screens).map((screenData, moduleIndex) => {
            console.log(screenData, "element11");
            let slide = screenData[1];
            console.log(slide);
            // item.map((element, index) => {
            Object.entries(slide).map((item, key) => {
              console.log(item[1]);
              // console.log(activeOST, "templateValid");
              // if(item[1].Contentparams.contentdescription!=="")
              let activeTemplate = item.filter(function (data) {
                return data.Contenttype === "TemplateID";
              });
              if (activeTemplate.length > 0) {
                let templateValid = {
                  isValid:
                    activeTemplate[0].Contentparams.contentdescription !==
                      "no template" 
                      ? true
                      : false,
                  templateKeys: moduleData[0],
                  templateSlidekeys: slideData[0],
                  templateScreenkeys: screenData[0],
                  screenName:
                    moduleData[1].ModuleName +
                    "-" +
                    slideData[1].SlideName +
                    "-" +
                    screenData[1].ScreenName,
                };

                templateValidArray.push(templateValid);
              }

              // setopenTemplateChangesModal(true)
            });
          });
        });
      }
    );
    settemplateCheckArray(templateValidArray);

    // props.setTemplateMode1();
  }, [confirmModal]);

  useEffect(() => {
    console.log("details", listFilterStatus, nextSelectionIndex);
    let selectedValue;
    let status;
    let selectedLabel;
    listFilterStatus.map((data, index) => {
      console.log("selectedValue1", nextSelectionIndex, index);
      if (index === nextSelectionIndex) {
        let ScreenWisename = data.split("_");
        console.log(ScreenWisename, "selectedValue");
        selectedValue = ScreenWisename[0];
        selectedLabel = ScreenWisename[1];
        status = nextSelectionIndex;
        setConfirmModal(false);
      } else if (listFilterStatus.length === nextSelectionIndex) {
        setConfirmModal(true);
      }
    });
    console.log(
      listStatus,
      listFilterStatus.length,
      nextSelectionIndex,
      "selectedValue"
    );
    setListStatus({
      value: selectedValue,
      label: selectedLabel,
      status: status,
    });
    if (selectedValue !== undefined) {
      if (selectedLabel !== undefined && selectedLabel !== "select screen") {
        let name = selectedValue.split("-");
        console.log(name);
        setActiveModule(name[0]);
        setActiveSlide(name[1]);
        setActiveScreen(name[2]);
      }
    }
  }, [dropDownSelection]);
  // useEffect(() => {
  //   if (projectDataUrl && ScreenPreviews) {
  //     // console.log('ScreenPreviews',ScreenPreviews)
  //     const projectKeys = Object.keys(projectDataUrl);
  //     // console.log('projectKeys',projectKeys)
  //     for (const projectId of projectKeys) {
  //       if (ScreenPreviews[projectId]) {
  //         const previewData = ScreenPreviews[projectId];
  //         const previewDataKeys = Object.keys(previewData);
  //         let newProjectData = JSON.parse(JSON.stringify(projectDataUrl));
  //         let keysToDelete = [];
  //         // console.log('----------------------',newProjectData, keysToDelete)
  //         UpdatePreview(newProjectData);
  //         setProjectData(newProjectData);
  //         // DeleteScreenPreview({ projectId, keysToDelete });
  //       }
  //     }
  //   }
  // }, [ ScreenPreviews]);
  useEffect(() => {
    if (projectDataUrl && ScreenPreviews) {
      // console.log('ScreenPreviews',ScreenPreviews)
      const projectKeys = Object.keys(projectDataUrl);
      // console.log('projectKeys',projectKeys)
      for (const projectId of projectKeys) {
        if (ScreenPreviews[projectId]) {
          const previewData = ScreenPreviews[projectId];
          const previewDataKeys = Object.keys(previewData);
          let newProjectData = JSON.parse(JSON.stringify(projectDataUrl));
          let keysToDelete = [];
          for (const dkey of previewDataKeys) {
            const { moduleId, slideId, screenId } = JSON.parse(dkey);
            const mediaurl = previewData[dkey];
            // console.log('&&&&&&&&&&&&',moduleId, slideId, screenId, mediaurl)
            // console.log('#############',newProjectData[projectId])
            if (
              newProjectData[projectId] &&
              newProjectData[projectId].Modules[moduleId] &&
              newProjectData[projectId].Modules[moduleId].slides[slideId] &&
              newProjectData[projectId].Modules[moduleId].slides[slideId]
                .Screens[screenId] &&
              mediaurl &&
              mediaurl["ThumbnailUrl"]
            ) {
              // if (moduleId === 'M01' && slideId === 'SL01' && screenId === 'SC01.01') {
              //   props.setRenderThumnails(arr => [...arr, mediaurl['ThumbnailUrl']])
              // }

              newProjectData[projectId].Modules[moduleId].slides[
                slideId
              ].Screens[screenId].mediaurl = { ...mediaurl };
              keysToDelete.push(dkey);
            }
          }

          // console.log('----------------------',newProjectData, keysToDelete)
          if (keysToDelete.length) {
            UpdatePreview(newProjectData);
            setProjectData(newProjectData);
            // DeleteScreenPreview({ projectId, keysToDelete })
          }
        }
      }
    }
  }, [projectDataUrl, ScreenPreviews]);
  useEffect(() => {
    console.log(ActiveScreen, "ActiveScreen");
  }, [ActiveScreen]);

  useEffect(() => {
    console.log(id, "pppppppp");
    console.log(templateCheckArray, "templateCheckArray");
    setProjectLoaded(true);
    setTemplateRepoLoader(true);

    let orgId = AdminRole == 0 ? userId : OrganiId;
    getTemplatePlanByOrg(orgId).then(async (response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          console.log(response.data.result[0], "res123");
          setTemplateSelectionType(response.data.result[0]);

          // if (project[projectId].Modules != undefined) {

          // if (!thumbnailRequested) {
          getProjectById(projectId).then((response) => {
            if (response.data.data.length > 0) {
              setProjectData(JSON.parse(response.data.data[0].ProjectData));
              console.log("called");
              console.log(JSON.parse(response.data.data[0].ProjectData));
              console.log(project[projectId]);
            } else {
            }
            console.log(
              JSON.parse(response.data.data[0].ProjectData),
              "called"
            );
          });
          // let a =  getProjectById(projectId)

          // }
          //   }
        }
      } catch (error) {
        //this.setState({ uploadButtonProgress: false })
      }
    });

    let templateValidArray = [];
    // let projectDatas = props.projectData[props.projectId].Modules;
    if (props.projectData2 !== undefined) {
      let projectDatas = props.projectData2[id].Modules;
      console.log(projectDatas, "moduleData");
      // let preview = await CreatePreviewFromResponse(response.data[0]);
      Object.entries(projectDatas).map((moduleData, moduleIndex) => {
        console.log(moduleData, "moduleData");
        let mData = moduleData[1];
        let slides = mData.slides;
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          console.log(slideData, "element22");
          Object.entries(screens).map((screenData, moduleIndex) => {
            console.log(screenData, "element11");
            let slide = screenData[1];
            console.log(slide);
            // item.map((element, index) => {
            Object.entries(slide).map((item, key) => {
              // console.log(item);
              let activeTemplate = item.filter(function (data) {
                return data.Contenttype === "TemplateID";
              });
              if (activeTemplate.length > 0) {
                console.log(moduleData[0], slideData[0], "templateValid");

                let templateValid = {
                  isValid:
                    activeTemplate[0].Contentparams.contentdescription !==
                    "no template"
                      ? true
                      : false,
                  templateKeys: moduleData[0],
                  templateSlidekeys: slideData[0],
                  templateScreenkeys: screenData[0],
                  screenName:
                    moduleData[1].ModuleName +
                    "-" +
                    slideData[1].SlideName +
                    "-" +
                    screenData[1].ScreenName,
                };
                console.log(templateValid, "templateValid");
                templateValidArray.push(templateValid);
              }

              // setopenTemplateChangesModal(true)
            });
          });
        });
      });
    }
    console.log(templateValidArray, "templateValid");
    console.log(props);
    // getAssetRepo();
    if (templateRepo.length > 0) {
      setPosts(templateRepo);
    }
    let moduleScreenname = [];

    if (templateValidArray !== undefined) {
      templateValidArray.map((screenName) => {
        console.log(screenName);
        moduleScreenname.push(
          screenName.templateKeys +
            "-" +
            screenName.templateSlidekeys +
            "-" +
            screenName.templateScreenkeys +
            "_" +
            screenName.screenName
        );
      });
    }
    let ScreenWisename = moduleScreenname[0].split("_");
    console.log(ScreenWisename);
    setListStatus({
      value: ScreenWisename[0],
      label: ScreenWisename[1],
      status: 0,
    });
    console.log(moduleScreenname);
    setListFilterStatus(moduleScreenname);
    if (templateValidArray !== undefined) {
      let name = ScreenWisename[0].split("-");
      console.log(name);
      setActiveModule(name[0]);
      setActiveSlide(name[1]);
      setActiveScreen(name[2]);
    }
    setProjectLoaded(false);
    setTemplateRepoLoader(false);
  }, []);

  const viewTemplatePreview = (video) => {
    //console.log(video, "imageee");
    setOpenTemplatePreview(true);
    setSelectedTemplateFile(video);
  };

  const getAssetRepo = async () => {
    setTemplateRepoLoader(true);

    // if (props.type === "template") {
    console.log(props, "modules");
    // let projectDatafromDb = await getProjectById(props.projectId)
    // console.log(projectDatafromDb,"projectDatafromDb");

    // let ProjectDetails=JSON.parse(projectDatafromDb.data.data[0].ProjectData)
    // let modules = JSON.parse(projectData);
    setPosts([]);
    setTemplateRepo([]);
    // if(props.projectId!==undefined&&props.projectId!==null){

    await getProjectById(id)
      .then((response) => {
        console.log(response);
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          let ProjectDetails = JSON.parse(response.data.data[0].ProjectData);
          console.log(response.data.data[0].ProjectID, "response.data");
          // console.log(projectData);
          // if (projectData.length > 0) {
          //   setPosts(projectData);
          // }
          let projetID = response.data.data[0].ProjectID;
          let modules = ProjectDetails;
          let projectName = modules[projetID].ProjectName;
          let activeScreen =
            modules[projetID]["Modules"][ActiveModule]["slides"][ActiveSlide][
              "Screens"
            ][ActiveScreen];

          let activeScreenDetails = {
            ScreenName:
              modules[projetID]["Modules"][ActiveModule]["slides"][ActiveSlide][
                "Screens"
              ][ActiveScreen]["ScreenName"],
            ...activeScreen,
          };
          let SlideAudioVO =
            modules[projetID]["Modules"][ActiveModule]["slides"][ActiveSlide][
              "SlideAudioVO"
            ] !== undefined
              ? modules[projetID]["Modules"][ActiveModule]["slides"][
                  ActiveSlide
                ]["SlideAudioVO"]
              : "Null";
          console.log("response.data.mediaurl");
          let screenContent = {
            [projetID]: {
              ProjectName: projectName,
              Modules: {
                [ActiveModule]: {
                  ModuleName:
                    modules[projetID]["Modules"][ActiveModule]["ModuleName"],
                  slides: {
                    [ActiveSlide]: {
                      SlideName:
                        modules[projetID]["Modules"][ActiveModule]["slides"][
                          ActiveSlide
                        ]["SlideName"],
                      SlideAudioVO: SlideAudioVO,
                      Screens: {
                        [ActiveScreen]: activeScreenDetails,
                      },
                    },
                  },
                },
              },
            },
          };
          let orgId = AdminRole == 0 ? userId : OrganiId;
          getTemplatePlanByOrg(orgId).then(async (response) => {
            try {
              setTemplateRepoLoader(true);
              if (
                response !== "" &&
                response !== undefined &&
                response !== null &&
                response.status !== "" &&
                response.status !== undefined &&
                response.status !== null &&
                response.status === 200 &&
                response.data !== "" &&
                response.data !== undefined &&
                response.data !== null
              ) {
                console.log(response.data.result[0], "res123");
                setTemplateSelectionType(response.data.result[0]);

                let type;
                if (response.data.result[0] !== undefined) {
                  if (
                    response.data.result[0].private === true &&
                    response.data.result[0].public === false
                  ) {
                    type = "private";
                  } else if (
                    response.data.result[0].public === true &&
                    response.data.result[0].private === false
                  ) {
                    type = "public";
                  } else if (
                    response.data.result[0].public === true &&
                    response.data.result[0].private === true
                  ) {
                    type = "public&private";
                  }
                }
                setTemplateRepoLoader(true);
                getTemplatesRepoManual(
                  screenContent,
                  type,
                  orgId,
                  projetID
                ).then((response) => {
                  console.log(response);
                  if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null &&
                    response.status !== "" &&
                    response.status !== undefined &&
                    response.status !== null &&
                    response.status === 200 &&
                    response.data !== "" &&
                    response.data !== undefined &&
                    response.data !== null
                  ) {
                    console.log(response.data, "response.data");

                    // this.setState({ motionGraphics: response.data, motionGraphicsLoader: false })
                    let projectData = response.data[0];
                    // console.log(projectData);
                    if (projectData.length > 0) {
                      setPosts(projectData);
                      setLoading(false);
                      setTemplateRepo(projectData);
                      setTemplateRepoLoader(false);
                    } else {
                      console.log("selectedValue", "selectedValue");
                      setTemplateRepoLoader(false);
                      setTemplateRepo([]);
                      setTemplateErrorMsg("No template available");
                    }
                  } else if (
                    response !== "" &&
                    response !== undefined &&
                    response !== null
                  ) {
                    console.log("selectedValue1", nextSelectionIndex);
                    // let selectedValue;
                    // let status;
                    // listFilterStatus.map((data, index) => {

                    //   if (index ===nextSelectionIndex+1) {
                    //     console.log(data, "selectedValue");
                    //     selectedValue = data;
                    //     status = nextSelectionIndex+1;
                    //     // setNextSelectionIndex(nextSelectionIndex)
                    //   } else if (listFilterStatus.length === nextSelectionIndex+1) {
                    //     selectedValue = "select value";
                    //     status = "";
                    //   }
                    // });
                    // console.log(listStatus,"selectedValue");
                    // setListStatus({
                    //   value: selectedValue,
                    //   label: selectedValue,
                    //   status:status,
                    // });
                    // //
                    // if (selectedValue !== undefined && selectedValue !== "select screen") {
                    //   let name = selectedValue.split("-");
                    //   console.log(name);
                    //   setActiveModule(name[0]);
                    //   setActiveSlide(name[1]);
                    //   setActiveScreen(name[2]);
                    // }

                    console.log("selectedValue", "selectedValue");
                    setTemplateRepoLoader(false);
                    setTemplateRepo([]);
                    setTemplateErrorMsg("No template available");
                  } else {
                    console.log("selectedValue", "selectedValue");
                    setTemplateRepoLoader(false);
                    setTemplateRepo([]);
                    setTemplateErrorMsg("No template available");
                  }
                });
              }
            } catch (error) {
              //this.setState({ uploadButtonProgress: false })
            }
          });
        } else if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.message !== undefined &&
          response.message !== null &&
          response.message !== ""
        ) {
          setTemplateRepoLoader(false);
          setTemplateErrorMsg("No template available");
        } else {
          setTemplateRepoLoader(false);

          setTemplateErrorMsg("No template available");
        }
      })
      .catch((error) => {
        setTemplateRepoLoader(false);
        setTemplateErrorMsg("No template available");
      });
    // }
    // }
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      color: state.isSelected ? "black" : "gray",
      padding: 7,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      height: "26px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "10px",
      lineHeight: "15px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };

  const handleChangeStatus = (e) => {
    // alert()
    let ScreenWisename = e.value.split("_");
    console.log(ScreenWisename, "ScreenWisename");
    setListStatus({
      value: e.value,
      label: e.label,
      status: e.status,
    });
    let name = ScreenWisename[0].split("-");
    console.log(e, "listtt");
    setActiveModule(name[0]);
    setActiveSlide(name[1]);
    setActiveScreen(name[2]);
    setCurrentPage(1);
  };
  useEffect(() => {
    console.log("called", listStatus);
    getAssetRepo();
    setDropDownSelection(false);
  }, [listStatus]);
  const generateStatus = () => {
    if (listFilterStatus != undefined) {
      let statusOption = [];
      listFilterStatus.map((filter, index) => {
        let ScreenWisename = filter.split("_");
        console.log(ScreenWisename);
        statusOption.push({
          value: ScreenWisename[0],
          label: ScreenWisename[1],
          status: index,
        });
        return true;
      });
      console.log(statusOption[0], "statusOption");
      setStatusOption(statusOption);
      // handleChange(fontOption[0])
      // console.log(uniqueArray);
      // setfonts(fontOption);
    }
  };

  useEffect(() => {
    generateStatus();
    console.log(statusOption, "cvbfgfg");
  }, [listFilterStatus]);

  const countPlusOne = () => {
    // let  count
    COUNT += 1;
    let result = COUNT.toString();
    return result;
  };
  const closeTempModal = () => {
    setopenTemplateChangesModal(false);
  };
  useEffect(() => {
    setProjectData(project);
    props.updatedtemplateURldata(project);
    let projectData = project[projectId].Modules;
    let settemplatename = [];
    Object.entries(projectData).map((moduleData, moduleIndex) => {
      let mData = moduleData[1];
      let slides = mData.slides;
      //console.log(mData);
      Object.entries(slides).map((slideData, moduleIndex) => {
        let slide = slideData[1];
        let screens = slide.Screens;
        Object.entries(screens).map((screenData, moduleIndex) => {
          console.log(screenData);
          let _arr = [];
          let slide = screenData[1];
          let screenEntries = Object.entries(slide);
          screenEntries.sort((a, b) => {
            const isTa = /^TM/.test(a[0]);
            const isTb = /^TM/.test(b[0]);

            if (isTa && !isTb) {
              return 1;
            }
            if (isTb && !isTa) {
              return -1;
            }
            return 0;
          });
          screenEntries.map((item, key) => {
            item.map((element, index) => {
              if (element.Contenttype === "TemplateID") {
                console.log(
                  element.Contentparams.contentdescription,
                  "TemplateID"
                );
                settemplatename.push(element.Contentparams.contentdescription);

                return element;
              }
              return element;
            });

            // console.log(_arr);
            return item;
          });

          return screenData;
        });
        return slideData;
      });
      return moduleData;
    });
    console.log(settemplatename, "settemplatename");
    setTemplateUrl(settemplatename);
  }, [project]);
  const GoToBack = () => {
    let templateValidArray = [];
    // let projectDatas = props.projectData[props.projectId].Modules;
    if (props.projectData2 !== undefined) {
      let projectDatas = props.projectData2[id].Modules;
      console.log(projectDatas, "moduleData");
      // let preview = await CreatePreviewFromResponse(response.data[0]);
      Object.entries(projectDatas).map((moduleData, moduleIndex) => {
        console.log(moduleData, "moduleData");
        let mData = moduleData[1];
        let slides = mData.slides;
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          console.log(slideData, "element22");
          Object.entries(screens).map((screenData, moduleIndex) => {
            console.log(screenData, "element11");
            let slide = screenData[1];
            console.log(slide);
            // item.map((element, index) => {
            Object.entries(slide).map((item, key) => {
              // console.log(item);
              let activeTemplate = item.filter(function (data) {
                return data.Contenttype === "TemplateID";
              });
              if (activeTemplate.length > 0) {
                console.log(moduleData[0], slideData[0], "templateValid");

                let templateValid = {
                  isValid:
                    activeTemplate[0].Contentparams.contentdescription !==
                    "no template"
                      ? true
                      : false,
                  templateKeys: moduleData[0],
                  templateSlidekeys: slideData[0],
                  templateScreenkeys: screenData[0],
                  screenName:
                    moduleData[1].ModuleName +
                    "-" +
                    slideData[1].SlideName +
                    "-" +
                    screenData[1].ScreenName,
                };
                console.log(templateValid, "templateValid");
                templateValidArray.push(templateValid);
              }

              // setopenTemplateChangesModal(true)
            });
          });
        });
      });
    }
    console.log(templateValidArray, "templateValid");
    console.log(props);
    // getAssetRepo();
    if (templateRepo.length > 0) {
      setPosts(templateRepo);
    }
    let moduleScreenname = [];

    if (templateValidArray !== undefined) {
      templateValidArray.map((screenName) => {
        console.log(screenName);
        moduleScreenname.push(
          screenName.templateKeys +
            "-" +
            screenName.templateSlidekeys +
            "-" +
            screenName.templateScreenkeys +
            "_" +
            screenName.screenName
        );
      });
    }
    let ScreenWisename = moduleScreenname[0].split("_");
    console.log(ScreenWisename);
    setListStatus({
      value: ScreenWisename[0],
      label: ScreenWisename[1],
      status: 0,
    });
    console.log(moduleScreenname);
    setNextSelectionIndex(0);
    setListFilterStatus(moduleScreenname);
    if (templateValidArray !== undefined) {
      let name = ScreenWisename[0].split("-");
      console.log(name);
      setActiveModule(name[0]);
      setActiveSlide(name[1]);
      setActiveScreen(name[2]);
    }
    console.log(listFilterStatus, listStatus, "listFilterStatus");
    setConfirmModal(false);
  };
  const submiModuleDetails = async (redirectPath) => {
    setProjectLoaded(true);
    setvalidatingTempLoader(true);
    // props.setchanges(false)
    let imageIds = [];
    let slideChanges = [];
    try {
      let projectDatafromDb = await getProjectById(projectId);
      if (projectDatafromDb.data.data.length > 0) {
        setcacheProjectData(
          JSON.parse(projectDatafromDb.data.data[0].ProjectData)
        );
      }
      let cachedData = JSON.parse(projectDatafromDb.data.data[0].ProjectData);
      let projectData = project[projectId].Modules;
      Object.entries(projectData).map((moduleData, moduleIndex) => {
        let mData = moduleData[1];
        let slides = mData.slides;
        //console.log(mData);
        Object.entries(slides).map((slideData, moduleIndex) => {
          let slide = slideData[1];
          let screens = slide.Screens;
          Object.entries(screens).map((screenData, moduleIndex) => {
            console.log(screenData);
            let _arr = [];
            let slide = screenData[1];
            let screenEntries = Object.entries(slide);
            screenEntries.sort((a, b) => {
              const isTa = /^TM/.test(a[0]);
              const isTb = /^TM/.test(b[0]);

              if (isTa && !isTb) {
                return 1;
              }
              if (isTb && !isTa) {
                return -1;
              }
              return 0;
            });
            screenEntries.map((item, key) => {
              //console.log(cachedData[projectId].Modules[moduleData[0]].slides[slideData[0]].Screens[screenData[0]]);
              let oldScreendata = undefined;
              if (cachedData[projectId].Modules != undefined) {
                if (cachedData[projectId].Modules[moduleData[0]] != undefined) {
                  console.log(
                    cachedData[projectId].Modules[moduleData[0]].slides[
                      slideData[0]
                    ],
                    "slides"
                  );
                  if (
                    cachedData[projectId].Modules[moduleData[0]].slides[
                      slideData[0]
                    ] != undefined
                  ) {
                    oldScreendata =
                      cachedData[projectId].Modules[moduleData[0]].slides[
                        slideData[0]
                      ].Screens[screenData[0]];
                  }
                }
              }
              item.map((element, index) => {
                if (element.Contenttype === "OST") {
                  if (element["Contentparams"]["FontColour"].length === 8) {
                    element["Contentparams"]["FontColour"] =
                      element["Contentparams"]["FontColour"].split("*")[0];
                  }
                  console.log(
                    element["Contentparams"]["contentdescription"],
                    "popopopopo"
                  );
                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[H]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[H]"
                      )[0];
                    element["Contentparams"]["fontTag"] = HEADERFONT;
                  }

                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[P]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[P]"
                      )[0];
                    element["Contentparams"]["fontTag"] = PARAGRAPHFONT;
                  }
                  if (
                    element["Contentparams"]["contentdescription"].includes(
                      "[B]"
                    )
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "[B]"
                      )[0];
                    element["Contentparams"]["fontTag"] = BULLETFONT;
                  }

                  if (
                    element["Contentparams"]["fontTag"] === HEADERFONT ||
                    element["Contentparams"]["fontTag"] === undefined
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[H]";
                  } else if (
                    element["Contentparams"]["fontTag"] === PARAGRAPHFONT
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[P]";
                  } else if (
                    element["Contentparams"]["fontTag"] === BULLETFONT
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"] + "[B]";
                  }
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  // if (element['Contentparams']['contentdescription'] === "") {
                  //     delete slide[item[0]]
                  // console.log(element['Contentparams']["fontTag"]);

                  // }
                  if (element["Contentparams"]["fontTag"] !== "") {
                    delete element["Contentparams"]["fontTag"];
                  }
                  //console.log(element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription);
                  console.log(oldScreendata);
                  if (oldScreendata) {
                    if (oldScreendata[item[0]]) {
                      console.log("22222");
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontColour"] !=
                        oldScreendata[item[0]].Contentparams.FontColour
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontFamily"] !=
                        oldScreendata[item[0]].Contentparams.FontFamily
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontSizeH"] !=
                        oldScreendata[item[0]].Contentparams.FontSizeH
                      ) {
                        _arr.push(1);
                      }
                      if (
                        element["Contentparams"]["FontSizeP"] !=
                        oldScreendata[item[0]].Contentparams.FontSizeP
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    return element;
                  }
                  // this.setState({ projectInputs: projectInputs })
                  return element;
                }
                if (element.Contenttype === "Background") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  return element;
                }
                // console.log(oldScreendata[item[0]]);
                if (element.Contenttype === "AudioScript") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  // if (element['Contentparams']['contentdescription'] === "") {
                  //     delete slide["AS01"]
                  // }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]]) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    return element;
                  }
                  return element;
                }
                if (element.Contenttype === "Image") {
                  if (element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] =
                      element["Contentparams"]["searchstring"].trim();
                  }
                  if (element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] =
                      element["Contentparams"]["contenttag"].trim();
                  }
                  // console.log(slide, "slide");
                  //element["Contentparams"]["contentdescription"] = "";
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["searchstring"] !=
                          oldScreendata[item[0]].Contentparams.searchstring ||
                        element["Contentparams"]["contenttag"] !=
                          oldScreendata[item[0]].Contentparams.contenttag ||
                        (element["Contentparams"]["contentdescription"] &&
                          oldScreendata[item[0]].Contentparams
                            .contentdescription &&
                          element["Contentparams"]["contentdescription"] !=
                            oldScreendata[item[0]].Contentparams
                              .contentdescription)
                      ) {
                        _arr.push(1);
                      }
                    } else if (
                      element["Contentparams"]["searchstring"] ||
                      element["Contentparams"]["contenttag"] !== "Null"
                    ) {
                      _arr.push(1);
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "Video") {
                  if (element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] =
                      element["Contentparams"]["searchstring"].trim();
                  }
                  if (element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] =
                      element["Contentparams"]["contenttag"].trim();
                  }
                  // console.log(slide, "slide");
                  //element["Contentparams"]["contentdescription"] = "";
                  // console.log(oldScreendata[item[0]]);
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }
                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["searchstring"] !=
                          oldScreendata[item[0]].Contentparams.searchstring ||
                        element["Contentparams"]["contenttag"] !=
                          oldScreendata[item[0]].Contentparams.contenttag ||
                        (element["Contentparams"]["contentdescription"] &&
                          oldScreendata[item[0]].Contentparams
                            .contentdescription &&
                          element["Contentparams"]["contentdescription"] !=
                            oldScreendata[item[0]].Contentparams
                              .contentdescription)
                      ) {
                        _arr.push(1);
                      }
                    } else if (
                      element["Contentparams"]["searchstring"] ||
                      element["Contentparams"]["contenttag"] !== "Null"
                    ) {
                      _arr.push(1);
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "TemplateID") {
                  // console.log(element['Contentparams']['useTemplateStatus'],"hariiiiiiiii");
                  //console.log(element["Contentparams"]["contentdescription"]);
                  //element["Contentparams"]["contentdescription"] = "";
                  if (oldScreendata[item[0]] != undefined) {
                    if (
                        (element["Contentparams"]["contentdescription"] && oldScreendata[item[0]].Contentparams.contentdescription && element["Contentparams"]["contentdescription"] != oldScreendata[item[0]].Contentparams.contentdescription)
                    ) {
                        _arr.push(1)
                    }
                }
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["useTemplateStatus"] !== "") {
                    delete element["Contentparams"]["useTemplateStatus"];
                    return element;
                  }
                  return element;
                }
                if (element.Contenttype === "ColourScheme") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (
                    element["Contentparams"]["contentdescription"].length === 8
                  ) {
                    element["Contentparams"]["contentdescription"] =
                      element["Contentparams"]["contentdescription"].split(
                        "*"
                      )[0];
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  return element;
                }
                if (element.Contenttype === "Illustration") {
                  if (element["Contentparams"]["searchstring"]) {
                    element["Contentparams"]["searchstring"] =
                      element["Contentparams"]["searchstring"].trim();
                  }
                  if (element["Contentparams"]["contenttag"]) {
                    element["Contentparams"]["contenttag"] =
                      element["Contentparams"]["contenttag"].trim();
                  }
                  //element["Contentparams"]["contentdescription"] = "";
                  if (
                    element["Contentparams"]["contenttag"] === "" &&
                    element["Contentparams"]["searchstring"] === ""
                  ) {
                    delete slide[item[0]];
                    // return element;
                  } else {
                    element["Contentparams"]["sequenceid"] = countPlusOne();
                  }

                  if (
                    element["Contentparams"]["contenttag"] === "" ||
                    element["Contentparams"]["contenttag"] === []
                  ) {
                    element["Contentparams"]["contenttag"] = "Null";
                    // return element;
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["searchstring"] !=
                          oldScreendata[item[0]].Contentparams.searchstring ||
                        element["Contentparams"]["contenttag"] !=
                          oldScreendata[item[0]].Contentparams.contenttag ||
                        (element["Contentparams"]["contentdescription"] &&
                          oldScreendata[item[0]].Contentparams
                            .contentdescription &&
                          element["Contentparams"]["contentdescription"] !=
                            oldScreendata[item[0]].Contentparams
                              .contentdescription)
                      ) {
                        _arr.push(1);
                      }
                    } else if (
                      element["Contentparams"]["searchstring"] ||
                      element["Contentparams"]["contenttag"] !== "Null"
                    ) {
                      _arr.push(1);
                    }
                  }
                  return element;
                }
                //console.log(element, "elemetttsssss");
                if (element.Contenttype === "ScreenDuration") {
                  element["Contentparams"]["sequenceid"] = countPlusOne();
                  if (element["Contentparams"]["contenttag"] === "") {
                    element["Contentparams"]["contenttag"] = "Null";
                    // return element;
                  }
                  if (element["Contentparams"]["contentdescription"] === "") {
                    element["Contentparams"]["contentdescription"] = "7";
                    // return element;
                  }
                  if (oldScreendata) {
                    if (oldScreendata[item[0]] != undefined) {
                      if (
                        element["Contentparams"]["contentdescription"] !=
                        oldScreendata[item[0]].Contentparams.contentdescription
                      ) {
                        _arr.push(1);
                      }
                    }
                  }
                  return element;
                }

                console.log(element);
                return element;
              });
              if (slide["mediaurl"]) {
               
                if (_arr.length > 0) {
                  delete projectData[moduleData[0]].slides[slideData[0]]
                    .Screens[screenData[0]]["mediaurl"];
                }
              }
              if (_arr.length > 0) {
                slideChanges.push(moduleData[0] + "-" + slideData[0])
                console.log(moduleData[0], slideData[0])
            }
              let activeTemplate = item.filter(function (data) {
                return data.Contenttype === "TemplateID";
              });
              if (activeTemplate.length > 0) {
                if (
                  activeTemplate[0].Contentparams.contentdescription ===
                  "no template"
                ) {
                  projectData[moduleData[0]].slides[slideData[0]].SlideRender =
                    "1";
                } else {
                  projectData[moduleData[0]].slides[slideData[0]].SlideRender =
                    "0";
                }
              }
              console.log(_arr);
              return item;
            });

            return screenData;
          });
          return slideData;
        });
        return moduleData;
      });

      console.log(projectData, "111111111");
      let updatedProjectDataforDb = JSON.stringify(project);
      console.log(updatedProjectDataforDb);

      let projectDataforAPI = await removeMediaUrlFilter(
        JSON.parse(updatedProjectDataforDb),
        projectId
      );
      console.log(projectDataforAPI);
      let templateValidArray = [];
      let renderUrls = ""
      let urlArray
      let projectDatafromDb1 = await getProjectById(projectId)
      if (projectDatafromDb1.data.data.length > 0) {
          renderUrls = projectDatafromDb1.data.data[0].renderUrls;
          urlArray = projectDatafromDb1.data.data[0].renderUrls
          if (urlArray !== null && urlArray !== undefined) {
              Object.keys(urlArray).map(function (key, index) {
                  Object.keys(urlArray[key]).map(function (k, i) {
                      slideChanges.map((slidechange) => {
                          if (slidechange == key + "-" + k) {
                              delete urlArray[key][k]
                              console.log(urlArray[key][k], key, k, slidechange, "renderUrls");
                          }
                          return urlArray
                      })
                  });
              });
          }

      }
      // console.log(project.createdProject);
      let projectDataReq = {
        dbdata: {
          [projectId]: project[projectId],
        },
        APIData: {
          [projectId]: projectDataforAPI[projectId],
        },
        RenderUrl: {
          renderUrls: urlArray
      },
        type: "Update",
      };
      createProjectsPreview(JSON.stringify(projectDataReq)).then(
        async (response) => {
          try {
            if (
              response !== "" &&
              response !== undefined &&
              response !== null &&
              response.data !== "" &&
              response.data !== undefined &&
              response.data !== null
            ) {
              if (
                response.status === 200 &&
                response.status !== null &&
                response.status !== null
              ) {
                showToaster("Project Updated successfully", "success");
                // let getProjectData = await getProjetcDetailsById(projectId)
                let projectDatafromDb = await getProjectById(projectId);
                if (projectDatafromDb.data.data.length > 0) {
                }

                let filter = {
                  project_id: projectId,
                };
                console.log("projectData", "moduleData");
                // EmptyPreview()
                getProjectID(filter).then(async (response) => {
                  try {
                    if (
                      response !== "" &&
                      response !== undefined &&
                      response !== null &&
                      response.status !== "" &&
                      response.status !== undefined &&
                      response.status !== null &&
                      response.status === 200 &&
                      response.data !== "" &&
                      response.data !== undefined &&
                      response.data !== null
                    ) {
                      let projectData1 = JSON.parse(
                        response.data[0].ProjectData
                      );
                      UpdatePreview(projectData1);
                      console.log(projectData1, "moduleData");

                      // let preview = await CreatePreviewFromResponse(response.data[0]);
                      Object.entries(projectData1[projectId].Modules).map(
                        (moduleData, moduleIndex) => {
                          console.log(moduleData, "moduleData");
                          let mData = moduleData[1];
                          let slides = mData.slides;
                          Object.entries(slides).map(
                            (slideData, moduleIndex) => {
                              let slide = slideData[1];
                              let screens = slide.Screens;
                              console.log(slideData, "element22");
                              Object.entries(screens).map(
                                (screenData, moduleIndex) => {
                                  console.log(screenData, "element11");
                                  let slide = screenData[1];
                                  console.log(slide);
                                  // item.map((element, index) => {
                                  Object.entries(slide).map((item, key) => {
                                    console.log(item[1]);
                                    // console.log(activeOST, "templateValid");
                                    // if(item[1].Contentparams.contentdescription!=="")
                                    let activeTemplate = item.filter(function (
                                      data
                                    ) {
                                      return data.Contenttype === "TemplateID";
                                    });
                                    if (activeTemplate.length > 0) {
                                      let templateValid = {
                                        isValid:
                                          activeTemplate[0].Contentparams
                                            .contentdescription !==
                                            "no template"
                                            ? true
                                            : false,
                                        templateKeys: moduleData[0],
                                        templateSlidekeys: slideData[0],
                                        templateScreenkeys: screenData[0],
                                        screenName:
                                          moduleData[1].ModuleName +
                                          "-" +
                                          slideData[1].SlideName +
                                          "-" +
                                          screenData[1].ScreenName,
                                      };

                                      // if (activeTemplate[0].Contentparams.contentdescription === "") {
                                      let hArray = 0;
                                      let pArray = 0;
                                      let bArray = 0;
                                      let imgArray = [];
                                      let videoArray = [];
                                      let illuArray = [];
                                      Object.entries(slide).map(
                                        (screen, key) => {
                                          console.log(screen);

                                          if (screen[1].Contenttype === "OST") {
                                            if (
                                              screen[1].Contentparams.contentdescription.slice(
                                                -3
                                              ) === "[H]"
                                            ) {
                                              hArray++;
                                            } else if (
                                              screen[1].Contentparams.contentdescription.slice(
                                                -3
                                              ) === "[P]"
                                            ) {
                                              pArray++;
                                            } else if (
                                              screen[1].Contentparams.contentdescription.slice(
                                                -3
                                              ) === "[B]"
                                            ) {
                                              bArray++;
                                            }
                                          }
                                          if (
                                            screen[1].Contenttype === "Image"
                                          ) {
                                            console.log(screen);
                                            imgArray.push(screen[0]);
                                          }
                                          if (
                                            screen[1].Contenttype === "Video"
                                          ) {
                                            console.log(screen);
                                            videoArray.push(screen[0]);
                                          }
                                          if (
                                            screen[1].Contenttype ===
                                            "Illustration"
                                          ) {
                                            console.log(screen);
                                            illuArray.push(screen[0]);
                                          }
                                        }
                                      );
                                      console.log(imgArray, imgArray.length);

                                      templateValid["HeadingCount"] =
                                        hArray + "-H OST,";
                                      templateValid["ParagraphCount"] =
                                        pArray + "-P OST,";
                                      templateValid["BulletCount"] =
                                        bArray + "-B OST,";
                                      templateValid["ImageCount"] =
                                        imgArray.length + "-Images,";
                                      templateValid["VideoCount"] =
                                        videoArray.length + "-Videos";
                                      templateValid["IllustrationCount"] =
                                        illuArray.length + "-Illustration,";

                                      templateValidArray.push(templateValid);
                                    }

                                    // setopenTemplateChangesModal(true)
                                  });
                                }
                              );
                            }
                          );
                        }
                      );
                      settemplateCheckArray(templateValidArray);
                      props.TemplateCheckArray1(templateValidArray);
                      setvalidatingTempLoader(false);
                      setopenTemplateChangesModal(true);
                      console.log(templateValidArray, "hArray");
                      console.log(projectData1, "templateValid");
                      let projectDataforAPI = await removeMediaUrlFilter(
                        projectData1,
                        projectId
                      );

                      setProjectLoaded(false);
                      console.log(projectDataforAPI, "projectDataforAPI");
                      // props.setTemplateMode1();
                    }
                  } catch (error) {
                    console.log(error);
                    setProjectLoaded(false);
                  }
                });

                // console.log(getProjectData);
                // SlidePreviewUpdate(getProjectData.previewProject)
                // getSlidePreview()
                // this.setState({ openInputModal: true })
              } else {
                setProjectLoaded(false);

                showToaster(response.message, "warning");
              }
            } else {
              setProjectLoaded(false);

              showToaster("Something went wrong. Please Try Again", "warning");
            }
          } catch (error) {
            setProjectLoaded(false);

            showToaster("Something went wrong. Please Try Again", "warning");
          }
        }
      );
    } catch (e) {
      console.log(e, "errr");
      showToaster("Something went wrong. Please Try Again", "warning");
    }
  };

  const GotoProject = async () => {
    // if (redirectPath !== undefined && redirectPath === "goBack") {
    //     history.goBack()
    // } else {

    //   if (EditMode) {
    //     history.push({
    //       pathname: `/project-preview/${project_id}`,
    //       state: {
    //         ActiveModule: ActiveModule,
    //         ActiveSlide: ActiveSlide,
    //         ActiveScreen: ActiveScreen,
    //       },
    //     });
    //   } else {
    //     history.push(`/project-preview/${project_id}`);
    //   }

    props.setTemplateMode1();
    setopenTemplateChangesModal(false);
    // }
  };
  const GoToEditProject = async () => {
    let project_id = projectId;
    props.setTemplateMode1();
    setopenTemplateChangesModal(false);
    history.push(`/edit-project/${project_id}`);
  };
  const listSameTemplate = (template) => {};
  return (
    <React.Fragment>
      {!validatingTempLoader && (
        <Modal
          isOpen={openTemplateChangesModal}
          fade={true}
          centered
          className={
            DisplayMode
              ? "modal-dialog  modal-dialog-centered custom-modal dark template-valid"
              : "modal-dialog  modal-dialog-centered custom-modal dark template-valid"
          }
        >
          <ModalBody>
            <div className="modal-content border-0 rounded-0">
              <div className="modal-body">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h3>Template Validation Table</h3>
                  <div
                    className="close-priview"
                    data-bs-dismiss="modal"
                    onClick={closeTempModal}
                  >
                    <Tooltip title="Close" arrow>
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g data-name="Layer 2">
                          <g data-name="close">
                            <rect
                              width="24"
                              height="24"
                              transform="rotate(180 12 12)"
                              opacity="0"
                            />
                            <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                          </g>
                        </g>
                      </svg>
                    </Tooltip>
                  </div>
                </div>
                <p>
                  {" "}
                  For the X marked screens,the templates are not available.
                  Please retry by adding a different combination of on-screen
                  text and assets.
                </p>
                <div className="template-table">
                  <div className="template-table-wrap template-thead">
                    <div className="template-block"> Screen Name</div>
                    <div className="template-block">Template Available</div>
                  </div>
                  {templateCheckArray !== undefined &&
                    templateCheckArray !== "" &&
                    templateCheckArray.map((data, index) => {
                      return (
                        <div className="template-table-wrap ">
                          <div className="template-block">
                            {data.screenName}
                            {data.isValid == false ? (
                              <p>
                                (
                                {data.HeadingCount +
                                  " " +
                                  data.BulletCount +
                                  " " +
                                  data.ParagraphCount +
                                  " " +
                                  data.ImageCount +
                                  " " +
                                  data.IllustrationCount +
                                  " " +
                                  data.VideoCount}
                                ) Templates are not available in this
                                combination{" "}
                              </p>
                            ) : (
                              <p>
                                (
                                {data.HeadingCount +
                                  " " +
                                  data.BulletCount +
                                  " " +
                                  data.ParagraphCount +
                                  " " +
                                  data.ImageCount +
                                  " " +
                                  data.IllustrationCount +
                                  " " +
                                  data.VideoCount}
                                ){" "}
                              </p>
                            )}
                          </div>

                          <div className="template-block  text-center">
                            {data.isValid == false ? (
                              <span className="uncheck-btn">
                                <Tooltip title="Invalid Template" arrow>
                                  <svg
                                    width="14px"
                                    height="auto"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
                                  </svg>
                                </Tooltip>
                              </span>
                            ) : (
                              <span className="check-btn">
                                <Tooltip title="Valid Template" arrow>
                                  <svg
                                    width="20px"
                                    height="auto"
                                    viewBox="0 0 24 24"
                                    fill=""
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                      fill=""
                                    ></path>
                                  </svg>
                                </Tooltip>
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>

                <div className="d-flex justify-content-end w-100 footer-btn flex-wrap">
                  <button
                    onClick={GoToEditProject}
                    type="button"
                    className="btn btn-outline rounded-0 modal-toggle me-2"
                    data-bs-dismiss="modal"
                  >
                    Edit
                  </button>
                  <button
                    type="button"
                    onClick={GotoProject}
                    className="btn btn-primary  me-2 rounded-0"
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      <pageLoader showLoader={projectLoaded} />
      <Modal
        isOpen={props.templateMode}
        fade={true}
        centered
        className={
          DisplayMode
            ? "modal-dialog modal-dialog-centered template-modal custom-modal dark"
            : "modal-dialog  modal-dialog-centered template-modal custom-modal"
        }
        // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
      >
        <ModalBody className="p-0">
          <SimpleBackdrop showBackDrop={projectLoaded} />

          <Tooltip title="Close" arrow>
            <div className="close-priview" onClick={() => submiModuleDetails()}>
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g data-name="Layer 2">
                  <g data-name="close">
                    <rect
                      width="24"
                      height="24"
                      transform="rotate(180 12 12)"
                      opacity="0"
                    />
                    <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                  </g>
                </g>
              </svg>
            </div>
          </Tooltip>
          {!confirmModal ? (
            <div className="srch-temp-blk">
              <div className="srch-temp-wrapper">
                <div className="srch-ft-selector w-100 p-0 ">
                  <Select
                    className="custom-input"
                    styles={customStyles}
                    value={listStatus}
                    onChange={(e) => handleChangeStatus(e)}
                    options={statusOption}
                    isSearchable={false}
                  />
                </div>
              </div>
              <p>
                <b>
                  {listStatus !== undefined && listStatus.status + 1}/
                  {listFilterStatus.length}
                </b>
              </p>
              <div className="cm-st-gallery my-3">
                {templateRepoLoader === false ? (
                  templateRepo.length > 0 ? (
                    <div className="cm-gallery-wrapper">
                      {" "}
                      {currentPosts.map((template, index) => {
                        console.log(template, templateUrl, "template");

                        // console.log(details,template.mediaUrl);
                        return (
                          <div
                            className="cm-gallery-container temp-card"
                            onClick={() => {
                              viewTemplatePreview(template);
                            }}
                          >
                            <div className="img-info">
                              {template.orgId !== undefined &&
                                template.orgId !== null &&
                                template.orgId !== "" && (
                                  <Tooltip title="Private Template" arrow>
                                    <span>
                                      <svg
                                        version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        xlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 330 330"
                                        space="preserve"
                                      >
                                        <g id="XMLID_486_">
                                          <path
                                            id="XMLID_487_"
                                            d="M165,330c63.411,0,115-51.589,115-115c0-29.771-11.373-56.936-30-77.379V85c0-46.869-38.131-85-85-85
                                        S80.001,38.131,80.001,85v52.619C61.373,158.064,50,185.229,50,215C50,278.411,101.589,330,165,330z M180,219.986V240
                                        c0,8.284-6.716,15-15,15s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                                        C190,208.162,186.068,215.421,180,219.986z M110.001,85c0-30.327,24.673-55,54.999-55c30.327,0,55,24.673,55,55v29.029
                                        C203.652,105.088,184.91,100,165,100c-19.909,0-38.651,5.088-54.999,14.028V85z"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </Tooltip>
                                )}
                            </div>

                            {/* {templateUrl.map((details) => {
                              if (details === template.mediaUrl) {
                                console.log("called");
                              } else {
                                console.log(details, template.mediaUrl, "date");
                              }
                              return (
                                <>
                                  {details === template.mediaUrl ? (
                                    <div className="img-info">
                                      <Tooltip title="used Template" arrow>
                                        <span
                                          style={{
                                            border: " 6px solid #4e4e4e",
                                          }}
                                        ></span>
                                      </Tooltip>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })} */}

                            {/* {listSameTemplate(template)} */}

                            {template.thumbnailUrl !== undefined &&
                            template.thumbnailUrl !== "" &&
                            template.thumbnailUrl !== null ? (
                              <img
                                className="img-fluid"
                                src={template.thumbnailUrl}
                              />
                            ) : (
                              <ReactImageFallback
                                src={template.thumbnailUrl}
                                fallbackImage="../../../assets/img/fallbackImage.jpg"
                                initialImage="../../../assets/img/fallbackImage.jpg"
                                alt="Logo"
                                className="img-fluid"
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="v-gal-empty-blk">
                      <div className="no-txt-selected blink">
                        {/* {capitalizeFirstLetter(templateErrorMsg)} */}
                        No template available
                      </div>
                    </div>
                  )
                ) : (
                  <div className="v-gal-empty-blk">
                    <CircularProgress />
                  </div>
                )}
              </div>
              {templateRepo.length > 0 && (
                <Pagination
                  paginate={paginate}
                  postsPerPage={postsPerPage}
                  totalPosts={posts.length}
                />
              )}
              {/* {confirmModal? (
              <button
                type="button"
                onClick={() => submiModuleDetails()}
                className="btn btn-primary cm-btn"
              >
                Finish
              </button>
            ) : ( */}

              <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-3">
                {listFilterStatus.length > 1 && (
                  <button
                    type="button"
                    onClick={() => nextScreen()}
                    className="btn btn-primary cm-btn"
                  >
                    Next
                  </button>
               
                )}
              </div>
            </div>
          ) : (
            <Modal
              isOpen={true}
              fade={true}
              centered
              className={
                DisplayMode
                  ? "modal-dialog  modal-dialog-centered custom-modal dark template-validation"
                  : "modal-dialog  modal-dialog-centered custom-modal dark template-validation"
              }
            >
              <ModalBody>
                <SimpleBackdrop showBackDrop={projectLoaded} />

                <div className="modal-content border-0 rounded-0">
                  <div className="modal-body">
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <h3>Template Validation</h3>
                      {/* <div
                        className="close-priview"
                        data-bs-dismiss="modal"
                        onClick={() => submiModuleDetails()}
                      >
                        <Tooltip title="Close" arrow>
                          <svg
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g data-name="Layer 2">
                              <g data-name="close">
                                <rect
                                  width="24"
                                  height="24"
                                  transform="rotate(180 12 12)"
                                  opacity="0"
                                />
                                <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                              </g>
                            </g>
                          </svg>
                        </Tooltip>
                      </div> */}
                    </div>

                    <>
                      <div className="srch-temp-blk">
                        <aside>
                          <div className="module-wrap">
                            <div className=" tab-view">
                              <div className="tab-content">
                                <div id="accordion" className="accordion">
                                  <div className="card">
                                    {projectDataUrl != undefined &&
                                      Object.entries(
                                        projectDataUrl[id].Modules
                                      ).map((module) => {
                                        let moduleValidation1 = 0;

                                        let moduleValidation = false;

                                        if (templateCheckArray !== undefined) {
                                          templateCheckArray.map(
                                            (tempArray) => {
                                              if (
                                                module[0] ===
                                                tempArray.templateKeys
                                              ) {
                                                if (
                                                  tempArray.isValid === false
                                                ) {
                                                  moduleValidation = true;
                                                }
                                              }
                                            }
                                          );
                                        }

                                        Object.entries(module[1].slides).map(
                                          (slide, i) => {
                                            if (
                                              slide[1].SlideRender == "1" &&
                                              !moduleValidation
                                            ) {
                                              moduleValidation1++;
                                            }
                                          }
                                        );
                                        return (
                                          <div>
                                            <div
                                              className="card-header"
                                              id="headingOne"
                                            >
                                              <h5 className="mb-0 d-inline">
                                                <a
                                                  className="btn btn-link "
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={`#${module[0]}`}
                                                  aria-expanded={
                                                    ActiveModule == module[0]
                                                      ? "true"
                                                      : "false"
                                                  }
                                                  aria-controls="collapseOne"
                                                  // onClick={() => { HandleSelectedModule(module[0], module[1].ModuleName) }}
                                                >
                                                  {" "}
                                                  {module[1].ModuleName}{" "}
                                                  {moduleValidation ? (
                                                    <span className="warning-btns uncheck-btn-sm">
                                                      <svg
                                                        version="1.1"
                                                        id="Capa_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xlink="http://www.w3.org/1999/xlink"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 106.031 106.031"
                                                        space="preserve"
                                                      >
                                                        <g>
                                                          <path
                                                            d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
                                                  c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
                                                          />
                                                          <circle
                                                            cx="52.34"
                                                            cy="97.007"
                                                            r="9.024"
                                                          />
                                                        </g>
                                                      </svg>
                                                    </span>
                                                  ) : (
                                                    <span className="warning-btns check-btn-sm">
                                                      <svg
                                                        viewBox="0 0 24 24"
                                                        fill=""
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          fill-rule="evenodd"
                                                          clip-rule="evenodd"
                                                          d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                                          fill=""
                                                        ></path>
                                                      </svg>
                                                    </span>
                                                  )}
                                                  {/* <h3>{renderCheckCount>0&&renderCheckCount}</h3> */}
                                                </a>
                                              </h5>
                                            </div>

                                            <div
                                              id={module[0]}
                                              className={
                                                ActiveModule == module[0]
                                                  ? "collapse show"
                                                  : "collapse"
                                              }
                                              aria-labelledby="headingOne"
                                              data-bs-parent="#accordion"
                                            >
                                              <div
                                                className="card-body"
                                                id="child1"
                                              >
                                                <div className="card">
                                                  {Object.entries(
                                                    module[1].slides
                                                  ).map((slide, i) => {
                                                    console.log(
                                                      slide[1].SlideRender,
                                                      "module"
                                                    );
                                                    let slideValidation = false;
                                                    if (
                                                      templateCheckArray !==
                                                      undefined
                                                    ) {
                                                      templateCheckArray.map(
                                                        (tempArray) => {
                                                          if (
                                                            module[0] ===
                                                              tempArray.templateKeys &&
                                                            slide[0] ===
                                                              tempArray.templateSlidekeys
                                                          ) {
                                                            if (
                                                              tempArray.isValid ===
                                                              false
                                                            ) {
                                                              slideValidation = true;
                                                            }
                                                          }
                                                        }
                                                      );
                                                    }

                                                    return (
                                                      <div>
                                                        <div className="usage-head-label text-center "></div>
                                                        <div className="card-header">
                                                          <div className="slide-select">
                                                            {/* <Checkbox
                                disabled={slideValidation ? true : false}
                                checked={slide[1].SlideRender === "1" &&!slideValidation? true : false}
                                // onChange={(e) => changePermission(slide[0], module[0], e)}
                                // color="primary"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                                sx={{
                                  color: "#2E2E2E",
                                  '&.Mui-checked': {
                                    color: "#468BE8",
                                  },
                                }}
                              /> */}
                                                            {/* <label for="html1"></label> */}
                                                          </div>
                                                          <a
                                                            className={
                                                              slide[1]
                                                                .SlideRender ===
                                                              "1"
                                                                ? "selected"
                                                                : ""
                                                            }
                                                            data-bs-toggle="collapse"
                                                            aria-expanded={
                                                              ActiveSlide ==
                                                              slide[0]
                                                                ? "true"
                                                                : "false"
                                                            }
                                                            data-bs-target={`#${slide[0]}`}
                                                            // onClick={() => { HandleSelectedSlide(slide[0], slide[1].SlideName) }}
                                                          >
                                                            {" "}
                                                            <strong>
                                                              {/* {i + 1 > 9
                                ? `${i + 1}`
                                : `0${i + 1}`} */}
                                                              {}
                                                            </strong>{" "}
                                                            {slide[1].SlideName}
                                                            {slideValidation ? (
                                                              <span className="warning-btns uncheck-btn-sm">
                                                                <svg
                                                                  version="1.1"
                                                                  id="Capa_1"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  xlink="http://www.w3.org/1999/xlink"
                                                                  x="0px"
                                                                  y="0px"
                                                                  viewBox="0 0 106.031 106.031"
                                                                  space="preserve"
                                                                >
                                                                  <g>
                                                                    <path
                                                                      d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
                                                  c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
                                                                    />
                                                                    <circle
                                                                      cx="52.34"
                                                                      cy="97.007"
                                                                      r="9.024"
                                                                    />
                                                                  </g>
                                                                </svg>
                                                              </span>
                                                            ) : (
                                                              <span className="warning-btns check-btn-sm">
                                                                <svg
                                                                  viewBox="0 0 24 24"
                                                                  fill=""
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                                                                    fill=""
                                                                  ></path>
                                                                </svg>
                                                              </span>
                                                            )}
                                                          </a>
                                                        </div>
                                                        <div
                                                          className={
                                                            slide[0] ==
                                                            ActiveSlide
                                                              ? "collapse show"
                                                              : "collapse"
                                                          }
                                                          data-bs-parent="#child1"
                                                          id={`${slide[0]}`}
                                                        >
                                                          <div className="slide-wrap">
                                                            <ul className="slide-inner">
                                                              {Object.entries(
                                                                slide[1].Screens
                                                              ).map(
                                                                (screen, i) => {
                                                                  let screenValidation = false;

                                                                  if (
                                                                    templateCheckArray !==
                                                                    undefined
                                                                  ) {
                                                                    templateCheckArray.map(
                                                                      (
                                                                        tempArray
                                                                      ) => {
                                                                        if (
                                                                          module[0] ===
                                                                            tempArray.templateKeys &&
                                                                          slide[0] ===
                                                                            tempArray.templateSlidekeys &&
                                                                          screen[0] ===
                                                                            tempArray.templateScreenkeys
                                                                        ) {
                                                                          if (
                                                                            tempArray.isValid ===
                                                                            false
                                                                          ) {
                                                                            screenValidation = true;
                                                                          }
                                                                        }
                                                                      }
                                                                    );
                                                                  }

                                                                  return (
                                                                    <>
                                                                      <li
                                                                        className={
                                                                          screen[0] ==
                                                                            ActiveScreen &&
                                                                          "active"
                                                                        }
                                                                      >
                                                                        <div className="slide-list">
                                                                          <span
                                                                            style={{
                                                                              position:
                                                                                "absolute",
                                                                              top: "3px",
                                                                              left: "18px",
                                                                              color:
                                                                                "blue",
                                                                              zIndex:
                                                                                "99",
                                                                              fontSize:
                                                                                "small",
                                                                            }}
                                                                          >
                                                                            {screen[1][
                                                                              "TM01"
                                                                            ] &&
                                                                              screen[1][
                                                                                "TM01"
                                                                              ][
                                                                                "Contentparams"
                                                                              ] &&
                                                                              screen[1][
                                                                                "TM01"
                                                                              ][
                                                                                "Contentparams"
                                                                              ][
                                                                                "contentdescription"
                                                                              ] &&
                                                                              screen[1][
                                                                                "TM01"
                                                                              ][
                                                                                "Contentparams"
                                                                              ][
                                                                                "contentdescription"
                                                                              ]
                                                                                .split(
                                                                                  "/"
                                                                                )
                                                                                .pop()}
                                                                          </span>
                                                                          <div
                                                                         className={screen[1]['mediaurl'] != undefined ?"img-wrap":"img-wrap border-orange"} 
                                                                            // onClick={() => {
                                                                            //   HandleSelectedScreen(screen[0], screen[1].ScreenName)
                                                                            // }}
                                                                          >
                                                                            {screenValidation && (
                                                                              <span className="warning-btns uncheck-btn-sm">
                                                                                <svg
                                                                                  version="1.1"
                                                                                  id="Capa_1"
                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                  xlink="http://www.w3.org/1999/xlink"
                                                                                  x="0px"
                                                                                  y="0px"
                                                                                  viewBox="0 0 106.031 106.031"
                                                                                  space="preserve"
                                                                                >
                                                                                  <g>
                                                                                    <path
                                                                                      d="M53.015,76.703c-5.815,0-10.528-4.712-10.528-10.528V10.528C42.487,4.714,47.201,0,53.015,0
                                                  c5.813,0,10.528,4.714,10.528,10.528v55.647C63.544,71.991,58.83,76.703,53.015,76.703z"
                                                                                    />
                                                                                    <circle
                                                                                      cx="52.34"
                                                                                      cy="97.007"
                                                                                      r="9.024"
                                                                                    />
                                                                                  </g>
                                                                                </svg>
                                                                              </span>
                                                                            )}
                                                                            {
                                                                              //.log(screen[1].thumbnailUrl)
                                                                              // console.log(ScreenThumbnail.PreviewUrl.split("_")[1] === module[0]&& ScreenThumbnail.PreviewUrl.split("_")[2] === slide[0] && ScreenThumbnail.PreviewUrl.split("_")[3].split(".mp4")[0] === screen[0])
                                                                            }
                                                                            {
                                                                              //screen[1].thumbnailUrl !== undefined ?
                                                                              // <ReactImageFallback
                                                                              //       src={`${screen[1].thumbnailUrl}`}
                                                                              //       fallbackImage="../../../assets/img/demo-img.png"
                                                                              //       initialImage="../../../assets/img/demo-img.png"
                                                                              //       alt="templates"
                                                                              //       className="img-fluid"
                                                                              //       //key={Date.now()}
                                                                              //     />
                                                                              <ImageComponent
                                                                                src={
                                                                                  screen[1][
                                                                                    "mediaurl"
                                                                                  ] !=
                                                                                  undefined
                                                                                    ? `${screen[1]["mediaurl"].ThumbnailUrl}`
                                                                                    : ""
                                                                                }
                                                                              />
                                                                              // <ImageCompoe src={}/>
                                                                              // :
                                                                              // <ReactImageFallback
                                                                              //   src={"../../../assets/img/demo-img.png"+`?time=${Date.now()}`}
                                                                              //   fallbackImage={"../../../assets/img/demo-img.png"+`?time=${Date.now()}`}
                                                                              //   initialImage={"../../../assets/img/demo-img.png"+`?time=${Date.now()}`}
                                                                              //   alt="templates"
                                                                              //   className="img-fluid"
                                                                              // //key={Date.now()}
                                                                              // />
                                                                            }
                                                                            {
                                                                              // (ScreenThumbnail.PreviewUrl !== "" && ScreenThumbnail.PreviewUrl !== undefined) ?
                                                                              //   <>
                                                                              //     <ReactImageFallback
                                                                              //       src={(ScreenThumbnail.PreviewUrl.split("_")[1] === module[0] && ScreenThumbnail.PreviewUrl.split("_")[2] === slide[0] && ScreenThumbnail.PreviewUrl.split("_")[3].split(".mp4")[0] === screen[0]) && ScreenThumbnail.ThumbnailUrl}
                                                                              //       fallbackImage="../../../assets/img/demo-img.png"
                                                                              //       initialImage="../../../assets/img/demo-img.png"
                                                                              //       alt="templates"
                                                                              //       className="img-fluid"
                                                                              //       key={Date.now()}
                                                                              //     />
                                                                              //   </>
                                                                              //   :
                                                                              //   <>
                                                                              //     <ReactImageFallback
                                                                              //       src="../../../assets/img/demo-img.png"
                                                                              //       fallbackImage="../../../assets/img/demo-img.png"
                                                                              //       initialImage="../../../assets/img/demo-img.png"
                                                                              //       alt="templates"
                                                                              //       className="img-fluid"
                                                                              //       key={Date.now()}
                                                                              //     />
                                                                              //   </>
                                                                            }
                                                                            {/* {screen[1]['mediaurl'] != undefined ?  */}
                                                                            {/* <h3>Hari</h3> */}
                                                                            {/* : ""} */}
                                                                            <h3>
                                                                              {screen[0].slice(
                                                                                5
                                                                              )}
                                                                              {/* {i + 1 > 9
                                              ? `${i + 1}`
                                              : `0${i + 1}`} */}
                                                                            </h3>
                                                                          </div>
                                                                        </div>
                                                                      </li>
                                                                    </>
                                                                  );
                                                                }
                                                              )}
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                        // Object.values(module[1]).map(slide => {
                                        //   console.log(slide, "slides");
                                        // })
                                        // return <h3>Module </h3>
                                      })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </aside>

                        {/* <main className="d-flex">
    <VideoplayerWithPreview loading={false}  showVolume={false} />
    </main> */}
                      </div>
                    </>

                    <div className="d-flex justify-content-end w-100 footer-btn flex-wrap mt-3">
                      <button
                        onClick={GoToBack}
                        type="button"
                        className="btn btn-outline rounded-0 modal-toggle me-2"
                        data-bs-dismiss="modal"
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        onClick={() => submiModuleDetails()}
                        className="btn btn-primary  me-2 rounded-0"
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          )}

          <TemplatePreview
            closePreview={() => setOpenTemplatePreview()}
            closeModal={() => props.setOpenTemplatePreview()}
            openTemplatePreview={openTemplatePreview}
            selectedVideo={selectedTemplateFile}
            setTemplateUpdate={props.setTemplateUpdate}
            ActiveModule={ActiveModule}
            ActiveSlide={ActiveSlide}
            ActiveScreen={ActiveScreen}
            project={project.createdProject}
            projectId={props.projectId}
            dropDownSelection={listStatus}
            nextScreen={() => setDropDownSelection()}
            nextSelectionIndex={nextSelectionIndex}
            setNextSelectionIndex={setNextSelectionIndex}
            setProjectData={setProjectData}
            // updatedtemplateURldata={setProjectData}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
}
