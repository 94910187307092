import React, { useState,useEffect } from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate,activePages }) => {
  const [activePage, setActivePage] = useState(1);
  const pageNumbers = [];
console.log(activePage,"activePage");
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }
useEffect(() => {
  if(activePages==0){
    setActivePage(1);
    paginate(1)
  }else if(activePages==1&&activePage!==1){
    setActivePage(activePage-1);
    paginate(activePage - 1);
  }
console.log(activePages,"paginate");
}, [activePages])

  return (
    <div className="d-flex align-items-center justify-content-center pagination-holder ag-pagination-holder mb-0">
      <ul className="d-flex align-items-center">
        <li
          className="pagination-nav pagin-prev disabled"
          onClick={() => {
            if (activePage > 1) {
              setActivePage(activePage - 1);
              paginate(activePage - 1);
            }
          }}
        >
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M7.66016 1.91L3.08016 6.5L7.66016 11.09L6.25016 12.5L0.250156 6.5L6.25016 0.5L7.66016 1.91Z" />
          </svg>
        </li>
        {pageNumbers.map((number, index) => (
          <span>
            <li
              className={
                activePage == number
                  ? "pagination-nav active"
                  : "pagination-nav"
              }
              onClick={() => {
                setActivePage(number);
                paginate(number);
              }}
            >
              {number}
            </li>
          </span>
        ))}
        {/* <li className="pagination-nav active">1</li>
    <li className="pagination-nav">2</li>
    <li className="pagination-nav">...</li>
    <li className="pagination-nav">9</li>
    <li className="pagination-nav">10</li> */}
        <li
          className={
            pageNumbers.length == activePage
              ? "pagination-nav pagin-next disabled"
              : "pagination-nav pagin-next"
          }
          onClick={() => {
            if (pageNumbers.length > activePage) {
              setActivePage(activePage + 1);
              paginate(activePage + 1);
            }
          }}
        >
          <svg
            width="8"
            height="13"
            viewBox="0 0 8 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.339844 1.91L4.91984 6.5L0.339844 11.09L1.74984 12.5L7.74984 6.5L1.74984 0.5L0.339844 1.91Z" />
          </svg>
        </li>
      </ul>
    </div>

    // <div>
    //   <nav>
    //     <ul className="pagination">
    //       {pageNumbers.map(number => (
    //         <li  key={number}>
    //           <span
    //             onClick={() => paginate(number)}
    //             // href="!#"
    //             className="page-link"
    //           >
    //             {number}
    //           </span>
    //         </li>
    //       ))}
    //     </ul>
    //   </nav>
    // </div>
  );
};

export default Pagination;
