import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import { Provider } from 'react-redux';


//const store = createStore(reducer);
import { store } from './store/store'

store.subscribe(() => {
  let state = store.getState();
  console.log(state, "state");
  console.log(state['project'].DisplayMode);
  if (state['project'] !== undefined)
    localStorage.setItem("project", JSON.stringify(state['project']))
    localStorage.setItem('displayMode' ,JSON.stringify(state['project'].DisplayMode))
})

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}><App /></Provider>
  </BrowserRouter>,
  document.getElementById('root')
);