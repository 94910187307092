import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Modal, ModalBody } from "reactstrap";
import { bindActionCreators } from "redux";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import { actionCreator } from "../../../../store/index";
import { getAllFonts } from "../../../../services/api.service";
import Select from "react-select";
import Tooltip from "@mui/material/Tooltip";
import {
  AddStyles,
  getStyles,
  updateStyles,
} from "../../../../services/authService";
import { getUserItem } from "../../../../services/helper";
import { Checkbox } from "@mui/material";
import {
  BgColor,
  FONTFAMILY,
  FONTSTYLE,
  FONT_SIZE_H,
  FONT_SIZE_P,
  TextColor,
} from "../../../../constants";
export default function StyleChooser(props) {
  const history = useHistory();
  const newProject = useSelector((state) => state.newProject);
  const addProjectDetail = useSelector((state) => state.addProjectDetail);
  const [fontArray, setfontArray] = useState();
  const [fonts, setfonts] = useStateWithCallbackLazy();
  const [regular, setRegular] = useState();
  const [FontFamily, setFontFamily] = useState();
  const [FontStyle, setFontStyle] = useState();
  const [fontSizeValidate, setFontSizeValidate] = useState(false);
  const [erroMessage, setErroMessage] = useState("");
  const [FontSizeH, setFontSizeH] = useState(30);
  const [FontSizeP, setFontSizeP] = useState(30);
  const [projectLoaded, setProjectLoaded] = useState(false);
  const [textColor, setTextColor] = useState("#000000");
  const [bgColor, setBgColor] = useState("#FF9A9A");
  const [applyStyleforScreen, setapplyStyleforScreen] = useState(false);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  // const [fontSizeValidate,setFontSizeValidate] = useState(false);
  let project = useSelector((state) => state.project);
  let ActiveModule = useSelector((state) => state.project.ActiveModule);
  let ActiveSlide = useSelector((state) => state.project.ActiveSlide);
  let ActiveScreen = useSelector((state) => state.project.ActiveScreen);
  let projectID = useSelector((state) => state.project.ProjectId);
  const [FontHError, setFontHError] = useState(undefined);
  const [FontPError, setFontPError] = useState(undefined);
  const [checkSylesData, setCheckSylesData] = useState(false);
  const dispatch = useDispatch();
  let EditMode = useSelector((state) => state.project.EditMode);
  let { SetOstFont, SetOstFontNewScreens, UpdateOstFont } = bindActionCreators(
    actionCreator,
    dispatch
  );


  useEffect(async () => {
    if (props.openStyleChooser) {
      setapplyStyleforScreen(false)
      getFonts();
      let response = await getStyles(projectID);
      if (response.data.result !== null && response.data.result !== undefined) {
        let data = response.data.result;
        console.log(data.fontFamily, 'check1');
        setFontFamily({
          value: data.fontFamily,
          label: data.fontFamily,
        });
        setFontStyle({
          value: data.fontStyle,
          label: data.fontStyle,
        });
        setFontSizeH(data.headingSize);
        setFontSizeP(data.paragraphSize);
        setTextColor(data.textColor);
        setBgColor(data.bgColor);
        setCheckSylesData(true);
        setProjectLoaded(true);
      } else {
        setCheckSylesData(false);
        // getStyleFromStore();
        console.log(FONTFAMILY, 'check2');
        setFontFamily({
          value: FONTFAMILY,
          label: FONTFAMILY,
        });
        setFontStyle({
          value: FONTSTYLE,
          label: FONTSTYLE,
        });
        setFontSizeH(FONT_SIZE_H);
        setFontSizeP(FONT_SIZE_P);
        setTextColor(TextColor);
        setBgColor(BgColor);
        setProjectLoaded(true);
      }
    }
  }, [props.openStyleChooser]);

  const getStyleFromStore = () => {
    if (project) {
      if (
        project.createdProject != undefined &&
        Object.keys(project.createdProject).length == 1
      ) {
        if (
          project.createdProject[projectID].Modules[ActiveModule] != undefined
        ) {
          if (
            project.createdProject[projectID].Modules[ActiveModule].slides[
              ActiveSlide
            ] != undefined
          ) {
            if (
              project.createdProject[projectID].Modules[ActiveModule].slides[
                ActiveSlide
              ].Screens[ActiveScreen] != undefined
            ) {
              let features =
                project.createdProject[projectID].Modules[ActiveModule].slides[
                  ActiveSlide
                ].Screens[ActiveScreen];

              Object.entries(features).map((i, item) => {
                if (i[1].Contenttype === "OST") {
                  let _font = i[1].Contentparams.FontFamily;
                  console.log( _font.split("/")[4], 'check3');
                  setFontFamily({
                    value: _font.split("/")[4],
                    label: _font.split("/")[4],
                  });
                  console.log( _font.split("/")[5], 'check4');
                  setFontStyle({
                    value: _font.split("/")[5],
                    label: _font.split("/")[5],
                  });
                  setFontSizeH(i[1].Contentparams.FontSizeH);
                  setFontSizeP(i[1].Contentparams.FontSizeP);
                  setProjectLoaded(true);
                  setTextColor(i[1].Contentparams.FontColour);
                }
                if (i[0] === "CS01") {
                  setBgColor(i[1].Contentparams.contentdescription);
                }
              });
            }
          }
        }
      } else {
        setProjectLoaded(false);
      }
    } else {
      setProjectLoaded(false);
    }
  };
  // useEffect(() => {
  //   if (project) {
  //     //console.log("pppppp", Object.keys(project.createdProject).length);
  //     if (
  //       project.createdProject != undefined &&
  //       Object.keys(project.createdProject).length == 1
  //     ) {
  //       if (
  //         project.createdProject[projectID].Modules[ActiveModule] != undefined
  //       ) {
  //         if (
  //           project.createdProject[projectID].Modules[ActiveModule].slides[
  //           ActiveSlide
  //           ] != undefined
  //         ) {
  //           if (
  //             project.createdProject[projectID].Modules[ActiveModule].slides[
  //               ActiveSlide
  //             ].Screens[ActiveScreen] != undefined
  //           ) {
  //             let features =
  //               project.createdProject[projectID].Modules[ActiveModule].slides[
  //                 ActiveSlide
  //               ].Screens[ActiveScreen];

  //             Object.entries(features).map((i, item) => {
  //               if (i[1].Contenttype === "OST") {
  //                 let _font = i[1].Contentparams.FontFamily;
  //                 setFontFamily({
  //                   value: _font.split("/")[4],
  //                   label: _font.split("/")[4],
  //                 });
  //                 setFontStyle({
  //                   value: _font.split("/")[5],
  //                   label: _font.split("/")[5],
  //                 });
  //                 setFontSizeH(i[1].Contentparams.FontSizeH);
  //                 setFontSizeP(i[1].Contentparams.FontSizeP);
  //                 setProjectLoaded(true);
  //                 setTextColor(i[1].Contentparams.FontColour);
  //               }
  //               if (i[0] === "CS01") {
  //                 setBgColor(i[1].Contentparams.contentdescription);
  //               }
  //             });
  //           }
  //         }
  //       }
  //     } else {
  //       setProjectLoaded(false);
  //     }
  //   } else {
  //     setProjectLoaded(false);
  //   }
  // }, [project]);
console.log(FontFamily, 'FontFamily');
  const getFonts = () => {
    getAllFonts().then((response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          setfontArray(response.data);
        }
      } catch (error) {}
    });
  };

  useEffect(() => {
    generateFonts();
  }, [fontArray]);

  const generateFonts = () => {
    if (fontArray !== undefined) {
      let sampleArray = fontArray.map((font) => font.split("/")[4]);
      let uniqueArray = sampleArray.filter(
        (item, i, ar) => ar.indexOf(item) === i
      );
      let fontOption = [];
      uniqueArray.map((font) => {
        fontOption.push({ value: font, label: font });
        return true;
      });
      setfonts(fontOption);
    }
  };

  const handleChange = (e) => {
    // alert()
    let regularArray = [];
    let regularOption = [];
  
    fontArray.map((font) =>
    
    font.split("/")[5]!==undefined?
      font.split("/")[4] === e.value
        ? regularArray.push(font.split("/")[5])
        : "":font.split("/")[4] === e.value
        ? regularArray.push(font.split("/")[4])
        : ""
    );
    console.log(regularArray,"regularArray");
    regularArray.map((font) => {
      regularOption.push({ value: font, label: font });
      return true;
    });
    setRegular(regularOption);
    console.log(e, 'check5');
    setFontFamily(e);
    setFontStyle(regularOption[0]);
    //SetOstFont(e.value, regularOption[0].value, FontSizeH, FontSizeP);
    // this.setState({ regular: regularOption, FontFamily: e, FontStyle: regularOption[0] }, () => {
    // this.updateJson()
    // })
  };
  const handleChangeRegular = (e) => {
    setFontStyle(e);
    //SetOstFont(FontFamily.value, e.value, FontSizeH, FontSizeP);
    // this.setState({ FontStyle: e }, () => {
    //     // this.updateJson()
    // })
  };

  const SaveandContinue = async () => {
    if (applyStyleforScreen) {
      console.log("apply styles");
      try {
        let response = await getStyles(projectID);
        if (
          response.data.result !== null &&
          response.data.result !== undefined
        ) {
          console.log(response);
          let data = await response.data.result;
          let oldStyle = {
            fontFamily: data.fontFamily,
            fontStyle: data.fontStyle,
            textColor: data.textColor,
            headingSize: data.headingSize,
            paragraphSize: data.paragraphSize,
            bgColor: data.bgColor,
          };
          let newStyle = {
            fontFamily: FontFamily.value,
            fontStyle: FontStyle.value,
            headingSize: FontSizeH,
            paragraphSize: FontSizeP,
            textColor: textColor,
            bgColor: bgColor,
          };
          UpdateOstFont(oldStyle,newStyle)
        }
      } catch (error) {
        console.log(error);
      }

      // SetOstFontNewScreens(
      //   FontFamily.value,
      //   FontStyle.value,
      //   FontSizeH,
      //   FontSizeP,
      //   textColor+"*",
      //   bgColor+"*"
      // );
    } else {
      SetOstFont(
        FontFamily.value,
        FontStyle.value,
        FontSizeH,
        FontSizeP,
        textColor,
        bgColor
      );
    }
    if (checkSylesData === false) {
      postData();
    } else {
      //update api call
      updateData();
    }

    setProjectLoaded(false);
    props.CloseModal();
  };
  const closemodal = () => {
    if (!checkSylesData) {
      postData();
    }
    // if (!EditMode) {
    //   SetOstFont(
    //     FontFamily.value,
    //     FontStyle.value,
    //     FontSizeH,
    //     FontSizeP,
    //     textColor,
    //     bgColor
    //   );
    // }

    props.CloseModal();
  };
  const fontSizeChange = (e, id) => {
    if (id === "FontSizeH") {
      if (
        e.target.value.length == 0 ||
        e.target.value == 0 ||
        e.target.value <= 0
      ) {
        setFontSizeH(e.target.value);
        setFontHError("Header Font size cannot be zero");
      } else if (e.target.value >= 100) {
        setFontSizeH(e.target.value);
        setFontHError("Header font size must be less than 100");
      } else {
        setFontHError(undefined);
        setFontSizeH(e.target.value);
        if (e.target.value.length === 0) {
          setFontSizeValidate(true);
        } else {
          setFontSizeValidate(false);
        }
      }
    } else if (id === "FontSizeP") {
      if (
        e.target.value.length == 0 ||
        e.target.value == 0 ||
        e.target.value <= 0
      ) {
        setFontSizeP(e.target.value);
        setFontPError("Paragraph Font size cannot be zero");
      } else if (e.target.value >= 100) {
        setFontSizeP(e.target.value);
        setFontPError("Paragraph font size must be less than 100");
      } else {
        setFontPError(undefined);
        setFontSizeP(e.target.value);
        if (e.target.value.length === 0) {
          setFontSizeValidate(true);
        } else {
          setFontSizeValidate(false);
        }
      }
    }
  };

  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      display: "flex",
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
      height: "26px",
    }),
    singleValue: () => ({
      color: DisplayMode ? "#c0c2c3" : "#6C757D",
      fontSize: "12px",
      lineHeight: "18px",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: DisplayMode ? "#2e2e2e" : "#fff",
    }),
    indicatorSeparator: () => ({
      border: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    input: (provided) => ({
      ...provided,
      color: "#fff",
    }),
  };

  const postData = async () => {
    let userId = getUserItem("id");
    let data = {
      project_id: projectID,
      user_id: userId,
      fontStyle: FontStyle.value,
      fontFamily: FontFamily.value,
      headingSize: FontSizeH,
      paragraphSize: FontSizeP,
      textColor: textColor,
      bgColor: bgColor,
    };
    let postResponse = await AddStyles(data);
  };
  const updateData = async () => {
    let userId = getUserItem("id");
    let data = {
      project_id: projectID,
      user_id: userId,
      fontStyle: FontStyle.value,
      fontFamily: FontFamily.value,
      headingSize: FontSizeH,
      paragraphSize: FontSizeP,
      textColor: textColor,
      bgColor: bgColor,
    };
    let updateResponse = await updateStyles(data);
  };
  return (
    <Modal
      isOpen={props.openStyleChooser && projectLoaded}
      fade={true}
      centered
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
          : "modal-dialog  modal-dialog-centered styles-modal custom-modal"
      }
      // className="modal-dialog  modal-dialog-centered styles-modal custom-modal dark"
    >
      <ModalBody>
        {/* <div className="modal-content">
          <div className="modal-body"> */}
        <div className="d-flex justify-content-end">
          <Tooltip title="Close" arrow>
            <div
              className="modal-close"
              data-bs-dismiss="modal"
              // title="Close"
              onClick={(e) => closemodal()}
            >
              {FontHError != undefined || FontPError != undefined ? (
                ""
              ) : (
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.7487 15.5463C16.9095 15.7059 17 15.9231 17 16.1497C17 16.3763 16.9095 16.5935 16.7487 16.7531C16.5892 16.9139 16.3721 17.0044 16.1456 17.0044C15.9191 17.0044 15.7019 16.9139 15.5424 16.7531L8.5 9.69972L1.45758 16.7531C1.29807 16.9139 1.08094 17.0044 0.854433 17.0044C0.627923 17.0044 0.410792 16.9139 0.251283 16.7531C0.0904604 16.5935 0 16.3763 0 16.1497C0 15.9231 0.0904604 15.7059 0.251283 15.5463L7.3022 8.5015L0.251283 1.45666C0.035798 1.2411 -0.0483584 0.926912 0.0305146 0.632452C0.109387 0.337993 0.339307 0.107994 0.633665 0.0290942C0.928023 -0.049806 1.2421 0.0343795 1.45758 0.249939L8.5 7.30328L15.5424 0.249939C15.8755 -0.083287 16.4156 -0.083287 16.7487 0.249939C17.0818 0.583164 17.0818 1.12343 16.7487 1.45666L9.69781 8.5015L16.7487 15.5463Z"
                    fill=""
                  />
                </svg>
              )}
            </div>
          </Tooltip>
        </div>
        <div className="style-wraper">
          <div className="style-modal-head text-center">
            <h3>Styles</h3>
            <p>
              Select the brand guidelines for your project below. This will be
              applied to all the slides. Don't worry, you can also change them
              individually later.
            </p>
          </div>
          <div className="style-inner">
            <div className="field-block">
              <div className="d-flex mb-2">
                <div className=" mb-2 me-4 w-50">
                  <label className="custom-label">Font Family</label>
                  <Select
                    className="form-select form-field form-selectopt"
                    styles={customStyles}
                    value={FontFamily}
                    onChange={(e) => handleChange(e)}
                    options={fonts}
                  />
                </div>
                <div className=" Regular w-50">
                  <label className="custom-label">Font Style</label>
                  <Select
                    className="form-select form-field form-selectopt"
                    styles={customStyles}
                    value={FontStyle}
                    onChange={handleChangeRegular}
                    options={regular}
                  />
                </div>
              </div>
            </div>

            <div className="field-block">
              <div className="d-flex">
                {/* <div className="mb-2 me-4 w-50">
                  <label className="custom-label">Heading Size</label>
                  <input
                    className="form-control inp-txt form-field"
                    placeholder="Enter FontH"
                    type="number"
                    value={FontSizeH}
                    onkeypress="return (event.charCode == 8 || event.charCode == 0) ? null : event.charCode >= 48 && event.charCode <= 57"
                    onChange={(e) => {
                      fontSizeChange(e, "FontSizeH");
                    }}
                  />
                  {FontHError != undefined ? (
                    <span className="input-error-msg">{FontHError}</span>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div> */}
                <div className="w-50">
                  <label className="custom-label">Paragraph</label>
                  <input
                    className="form-control inp-txt form-field"
                    placeholder="Enter FontP"
                    type="number"
                    value={FontSizeP}
                    onChange={(e) => {
                      fontSizeChange(e, "FontSizeP");
                    }}
                  />
                  {FontPError != undefined ? (
                    <span className="input-error-msg">{FontPError}</span>
                  ) : (
                    <span>&nbsp;</span>
                  )}
                </div>
              </div>
              {<span className="input-error-msg">{erroMessage}</span>}
            </div>

            <div className="cm-color-picker pe-0">
              <div className="d-flex flex-wrap align-items-center">
                <div className="d-flex flex-wrap">
                  <div className="color-picker me-4 nw-color-pick">
                    <span className="color-palette">
                      <input
                        type="color"
                        className="custom-color-picker"
                        value={textColor}
                        onChange={(e) => setTextColor(e.target.value)}
                      />
                      <i className="eye-drop">
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.49944 1.66429C9.49704 1.43857 9.44904 1.21566 9.35832 1.00895C9.26761 0.802247 9.13605 0.616009 8.97155 0.461424C8.31445 -0.160631 7.25036 -0.137216 6.59956 0.513609L5.57073 1.54246C5.35677 1.34753 5.07599 1.24245 4.78662 1.24901C4.49726 1.25558 4.22153 1.37328 4.01662 1.5777L3.6871 1.90723C3.54666 2.04798 3.46779 2.23871 3.46779 2.43755C3.46779 2.63639 3.54666 2.82711 3.6871 2.96787L3.78362 3.06438L1.42376 5.42422C1.20805 5.6408 1.04833 5.90665 0.958401 6.1988C0.868474 6.49095 0.851049 6.8006 0.90763 7.10099L0.449066 8.1514C0.396728 8.26998 0.381601 8.40165 0.405683 8.52901C0.429766 8.65637 0.491925 8.77342 0.583946 8.86471C0.653202 8.93448 0.735565 8.98987 0.826301 9.02771C0.917036 9.06554 1.01436 9.08507 1.11266 9.08517C1.21651 9.08504 1.31922 9.06344 1.41433 9.02174L2.39866 8.59202C2.69905 8.64861 3.00871 8.63118 3.30085 8.54125C3.593 8.45132 3.85885 8.29159 4.07542 8.07587L6.43526 5.71603L6.53178 5.81254C6.67254 5.95299 6.86326 6.03186 7.0621 6.03186C7.26094 6.03186 7.45166 5.95299 7.59242 5.81254L7.92195 5.48302C8.12638 5.27813 8.24409 5.00239 8.25065 4.71303C8.25722 4.42366 8.15213 4.14287 7.9572 3.92892L9.00534 2.88077C9.16542 2.72176 9.29171 2.53204 9.37662 2.32299C9.46152 2.11394 9.5033 1.88989 9.49944 1.66429ZM3.5451 7.54555C3.40518 7.6849 3.23117 7.78513 3.04042 7.83624C2.84967 7.88735 2.64886 7.88756 2.45801 7.83684C2.37601 7.81497 2.28903 7.82154 2.21125 7.85548L1.20479 8.29486L1.64417 7.2884C1.67812 7.21062 1.68469 7.12363 1.66281 7.04164C1.6121 6.85079 1.61232 6.64998 1.66343 6.45923C1.71454 6.26849 1.81477 6.09447 1.9541 5.95454L4.31394 3.5947L5.90494 5.1857L3.5451 7.54555Z" />
                        </svg>
                      </i>
                    </span>
                    <p className="edit-label">Text</p>
                  </div>
                  <div className="color-picker me-4 nw-color-pick">
                    <span className="color-palette">
                      <input
                        type="color"
                        className="custom-color-picker"
                        value={bgColor}
                        onChange={(e) => {
                          setBgColor(e.target.value);
                        }}
                      />
                      {/* <input type="color" className="custom-color-picker"/> */}
                      <i className="eye-drop">
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.49944 1.66429C9.49704 1.43857 9.44904 1.21566 9.35832 1.00895C9.26761 0.802247 9.13605 0.616009 8.97155 0.461424C8.31445 -0.160631 7.25036 -0.137216 6.59956 0.513609L5.57073 1.54246C5.35677 1.34753 5.07599 1.24245 4.78662 1.24901C4.49726 1.25558 4.22153 1.37328 4.01662 1.5777L3.6871 1.90723C3.54666 2.04798 3.46779 2.23871 3.46779 2.43755C3.46779 2.63639 3.54666 2.82711 3.6871 2.96787L3.78362 3.06438L1.42376 5.42422C1.20805 5.6408 1.04833 5.90665 0.958401 6.1988C0.868474 6.49095 0.851049 6.8006 0.90763 7.10099L0.449066 8.1514C0.396728 8.26998 0.381601 8.40165 0.405683 8.52901C0.429766 8.65637 0.491925 8.77342 0.583946 8.86471C0.653202 8.93448 0.735565 8.98987 0.826301 9.02771C0.917036 9.06554 1.01436 9.08507 1.11266 9.08517C1.21651 9.08504 1.31922 9.06344 1.41433 9.02174L2.39866 8.59202C2.69905 8.64861 3.00871 8.63118 3.30085 8.54125C3.593 8.45132 3.85885 8.29159 4.07542 8.07587L6.43526 5.71603L6.53178 5.81254C6.67254 5.95299 6.86326 6.03186 7.0621 6.03186C7.26094 6.03186 7.45166 5.95299 7.59242 5.81254L7.92195 5.48302C8.12638 5.27813 8.24409 5.00239 8.25065 4.71303C8.25722 4.42366 8.15213 4.14287 7.9572 3.92892L9.00534 2.88077C9.16542 2.72176 9.29171 2.53204 9.37662 2.32299C9.46152 2.11394 9.5033 1.88989 9.49944 1.66429ZM3.5451 7.54555C3.40518 7.6849 3.23117 7.78513 3.04042 7.83624C2.84967 7.88735 2.64886 7.88756 2.45801 7.83684C2.37601 7.81497 2.28903 7.82154 2.21125 7.85548L1.20479 8.29486L1.64417 7.2884C1.67812 7.21062 1.68469 7.12363 1.66281 7.04164C1.6121 6.85079 1.61232 6.64998 1.66343 6.45923C1.71454 6.26849 1.81477 6.09447 1.9541 5.95454L4.31394 3.5947L5.90494 5.1857L3.5451 7.54555Z" />
                        </svg>
                      </i>
                    </span>
                    <p className="edit-label">Background</p>
                  </div>
                </div>
              </div>
            </div>
            {EditMode && (
              <div className="d-flex align-items-start pro-temp-container mr-4 ">
                <Checkbox
                  checked={applyStyleforScreen}
                  onChange={(e) => setapplyStyleforScreen(e.target.checked)}
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  className="style-checkbox"
                />
                <div className="cm-header .cm-subhead">
                Do not apply new style changes to screens with custom style. New style changes will be applied to other Screens. Alternatively click on Save and Continue to apply new Style changes to all screens
                </div>
              </div>
            )}

            <div className="mt-4 text-center style-modal-footer">
              <button
                type="button"
                className="btn btn-primary cm-btn px-3 m-auto"
                value="submit"
                disabled={FontHError != undefined || FontPError != undefined}
                onClick={() => {
                  SaveandContinue();
                }}
              >
                SAVE AND CONTINUE
              </button>
            </div>
          </div>
        </div>
        {/* </div>
        </div> */}
      </ModalBody>
    </Modal>
  );
}
