import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Duration from "./Duration";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import Tooltip from '@mui/material/Tooltip';
function Videoplayer(props) {
  const [isPlaying, setisPlaying] = useState(false);
  const [played, setplayed] = useState(0);
  const [seeking, setseeking] = useState(false);
  const [duration, setduration] = useState(0);
  const [volume, setvolume] = useState(1);
  const [muted, setmuted] = useState(false);
  const [light, setlight] = useState(false);
  const [thumbnailUrl, setthumbnailUrl] = useState("");
  const [videoUrl, setvideoUrl] = useState("");
  const [showProgress, setshowProgress] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)

  const playerRef = useRef(null);

  useEffect(() => {
    if (props.thumbnailUrl != undefined) {
      // setlight(props.thumbnailUrl);
      setthumbnailUrl(props.thumbnailUrl)
      // setvideoUrl(props.videoUrl)
    }
    if (props.videoUrl) {
      setvideoUrl(props.videoUrl)
    }
  }, [props]);

  useEffect(() => {
    const handleFs = () => {
      setIsFullscreen(screenfull.isFullscreen)
    }
    screenfull.on('change', handleFs);
    return () => {
      screenfull.off('change', handleFs);
    }
  }, [])

  const handleVideoPlay = (status) => {
    // console.log(status);
    if (status === "play") {
      setlight(false);
      setisPlaying(true);
    } else {
      setisPlaying(false);
    }
  };

  const handleSeekChange = (e) => {
    setplayed(parseFloat(e.target.value));
  };

  const handleSeekMouseDown = (e) => {
    setseeking(true);
  };
  const handleSeekMouseUp = (e) => {
    //console.log(parseFloat(videoPlayer.current.seekTo()));
    setseeking(false);
    playerRef.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (e) => {
    setplayed(e.played);
    //setseeking(true);
  };

  const handleDuration = (e) => {
    console.log(e);
    setduration(e);
  };

  const handleVolumeChange = (e) => {
    console.log(e.target.value,"e.target.value");
    setvolume(e.target.value);
  };

  const handleToggleMuted = (status) => {
    setmuted(status);
  };

  const handleFullScreen = () => {
    screenfull.toggle(findDOMNode(playerRef.current));
  };
  const handleVideoOnEnd =(e) =>{
    setisPlaying(false)
    setplayed(0)
  }

  return (
    <div className={(props !== undefined && props.disabled !== undefined) && (props.disabled && 'not-active')}>
      <span className="video-wrapper" style={{position:'relative'}}>
      <ReactPlayer
      ref={playerRef} 
        light={light}
        url={videoUrl}
        // controls
        volume={volume}
        width="100%"
        // height={'calc(100vh - 375px)'}
        height="100%"
        muted={muted}
        style={{position:"relative", paddingTop:"50%"}}
        // playing={true}
        playing={isPlaying}
        onPause={e => {
          setshowProgress(false)
        }}
        onProgress={(e) => handleProgress(e)}
        onDuration={(e) => handleDuration(e)}
        onEnded={(e) => handleVideoOnEnd(e)}
        onBufferEnd={e => {
          setshowProgress(false)
        }}
        onBuffer={e => {
          setshowProgress(true)
        }}
        onReady={e => {
          
        }}
      />
      {showProgress &&<img src="../../../assets/img/loader.gif" className="video-loader-gif" />}
      </span>
      <div className="video-control">
        <div className="rSlider">
          {/* <span className="slide"></span> */}
          {/* <input id="range" type="range" min="0" max="50000" /> */}
          <input
            type="range"
            min={0}
            max={0.999999}
            step="any"
            value={played}
            onMouseDown={(e) => handleSeekMouseDown(e)}
            onChange={(e) => handleSeekChange(e)}
            onMouseUp={(e) => handleSeekMouseUp(e)}
          />
        </div>

        <div className="d-flex justify-content-between align-items-center ps-2 pe-1">
          <div className="d-flex align-items-center">
            {isPlaying ? (
              <a className="play-btn pointer" onClick={() => handleVideoPlay("pause")}>
                <Tooltip title="Pause" arrow>
                  <svg width="11" height="13" viewBox="0 0 11 13" fill="none">
                    <rect y="0.5" width="4" height="12" fill="" />
                    <rect x="7" y="0.5" width="4" height="12" fill="" />
                  </svg></Tooltip>
              </a>
            ) : (
                <a className="play-btn pointer" onClick={() => handleVideoPlay("play")}>
                  <Tooltip title="Play" arrow>
                    <svg width="11" height="14" viewBox="0 0 11 14" fill="none">
                      <path d="M0 0V14L11 7L0 0Z" fill="" />
                    </svg></Tooltip>
                </a>
              )}

            <div className="time-duration ms-3">
              <span>
                <Duration seconds={duration * played} />{" "}
              </span>
              /
              <span>
                {" "}
                <Duration seconds={duration} />
              </span>
            </div>
            {(props.showVolume != undefined && props.showVolume) && (


              <div className="volume-control pointer ms-3">
                <div className="volume-icon">
                  {!muted ? (
                    <Tooltip title="Unmute" arrow>
                      <svg onClick={() => handleToggleMuted(true)} width="16" height="19" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 8.5C13.5 6.73 12.48 5.21 11 4.47V12.52C12.48 11.79 13.5 10.27 13.5 8.5ZM0 5.5V11.5H4L9 16.5V0.5L4 5.5H0Z" fill="" />
                      </svg></Tooltip>
                  ) : (
                      <a onClick={() => handleToggleMuted(false)}>
                        <Tooltip title="Mute" arrow>
                          <svg
                            version="1.1"
                            id="Layer_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512 512"
                            style={{ enableBackground: 'new 0 0 512 512' }}
                            space="preserve"
                            width="16" height="19"
                          >
                            <g>
                              <g>
                                <polygon
                                  points="214.986,24.477 99.224,140.238 0,140.238 0,371.762 99.224,371.762 214.986,487.523 231.523,487.523 
    231.523,24.477 		"
                                />
                              </g>
                            </g>
                            <g>
                              <g>
                                <polygon
                                  points="512,191.685 476.919,156.604 412.604,220.919 348.287,156.604 313.208,191.685 377.523,256 313.208,320.315 
    348.287,355.396 412.604,291.081 476.919,355.396 512,320.315 447.683,256 		"
                                />
                              </g>
                            </g>
                          </svg></Tooltip>
                      </a>
                    )}
                </div>
                <input
                  type="range"
                  min={0}
                  max={1}
                  step="any"
                  value={volume}
                  onChange={handleVolumeChange}
                />
              </div>

            )}
          </div>
          <a className="play-btn pointer" onClick={handleFullScreen}>
            <Tooltip title="Full Screen" arrow>
            {!isFullscreen ? 
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.28571 10.2857H0V16H5.71429V13.7143H2.28571V10.2857ZM0 5.71429H2.28571V2.28571H5.71429V0H0V5.71429ZM13.7143 13.7143H10.2857V16H16V10.2857H13.7143V13.7143ZM10.2857 0V2.28571H13.7143V5.71429H16V0H10.2857Z" f />
              </svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0z" fill="none"/><path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"/></svg>
            }
            </Tooltip>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Videoplayer;
