import { FONT_URL } from "../../constants";
import { ASSIGN_THUMBNAIL, CHANGEPROJECTNAMEINPREVIEW, EMPTYPREVIEW, INITIALIZEPREVIEW, PREVIEW_SETOSTFONT, PREVIEW_UPDATEFEATURE, PREVIOUS_ACTIVE_TO_PREVIEW, SELECTACTIVEMODULE, SELECTACTIVESCREEN, SELECTACTIVESLIDE, UPDATEPREVIEW, UPDATETHUMBNAIL, UPDATE_AUDIOSCRIPT_UPLOAD, UPDATESCREENPREVIEW, DELETESCREENPREVIEW, UPDATETEMPLATEURLPREVIEW } from "./constants";

const initialState = {
    previewProject: {},
    previewProject_thumbnails: {},
    ActiveModuleId: undefined,
    ActiveModuleName: undefined,
    ActiveSlideId: undefined,
    ActiveSlideName: undefined,
    ActiveScreenId: undefined,
    ActiveScreenName: undefined,
    ProjectId: undefined,
    ScreenThumbnail: undefined,
    ScreenPreviews: {},
}

const previewReducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZEPREVIEW:
            return {
                ...state,
                previewProject: action.payload.previewProject,
                previewProject_thumbnails: action.payload.previewProject,
                ActiveModuleId: action.payload.ActiveModule,
                ActiveModuleName: action.payload.ModuleName,
                ActiveSlideId: action.payload.ActiveSlide,
                ActiveSlideName: action.payload.SlideName,
                ActiveScreenId: action.payload.ActiveScreen,
                ActiveScreenName: action.payload.ScreenName,
                ProjectId: action.payload.ProjectId
            }
        case UPDATEPREVIEW:{
            return {
                ...state,
                previewProject:action.payload.previewProject
            }
        }
        case EMPTYPREVIEW: {
            return {
                previewProject: {},
                previewProject_thumbnails: {},
                ActiveModuleId: undefined,
                ActiveModuleName: undefined,
                ActiveSlideId: undefined,
                ActiveSlideName: undefined,
                ActiveScreenId: undefined,
                ActiveScreenName: undefined,
                ProjectId: undefined,
                ScreenThumbnail: undefined
            }
        }
        case SELECTACTIVESCREEN: {
            return {
                ...state,
                ActiveScreenId: action.payload.ActiveScreen,
                ActiveScreenName: action.payload.ScreenName,
            }
        }
        case SELECTACTIVESLIDE: {
            let screens = Object.entries(state.previewProject[state.ProjectId].Modules[state.ActiveModuleId]
                .slides[action.payload.ActiveSlide].Screens)[0];
            return {
                ...state,
                ActiveSlideId: action.payload.ActiveSlide,
                ActiveSlideName: action.payload.SlideName,
                ActiveScreenId: screens[0],
                ActiveScreenName: screens[1].ScreenName,
            }
        }
        case SELECTACTIVEMODULE: {
            //console.log(action.payload);
            let modules = state.previewProject[state.ProjectId].Modules;
            let first_slide1 = Object.entries(modules[action.payload.ActiveModule].slides)[0]

            let first_screen1 = Object.entries(modules[action.payload.ActiveModule].slides[first_slide1[0]].Screens)[0]
            //console.log(first_screen1);
            return {
                ...state,
                ActiveModuleId: action.payload.ActiveModule,
                ActiveModuleName: action.payload.ModuleName,
                ActiveSlideId: first_slide1[0],
                ActiveSlideName: first_slide1[1].SlideName,
                ActiveScreenId: first_screen1[0],
                ActiveScreenName: first_screen1[1].ScreenName,
            }
        }
        case PREVIEW_UPDATEFEATURE: {
            console.log(action.payload,"state");
            return {
                ...state,
                previewProject: {
                    ...state.previewProject,
                    [state.ProjectId]: {
                        ...state.previewProject[state.ProjectId],
                        Modules: {
                            ...state.previewProject[state.ProjectId].Modules,
                            [state.ActiveModuleId]: {
                                ...state.previewProject[state.ProjectId].Modules[
                                state.ActiveModuleId
                                ],
                                slides: {
                                    ...state.previewProject[state.ProjectId].Modules[
                                        state.ActiveModuleId
                                    ].slides,
                                    [state.ActiveSlideId]: {
                                        ...state.previewProject[state.ProjectId].Modules[
                                            state.ActiveModuleId
                                        ].slides[state.ActiveSlideId],
                                        Screens: {
                                            ...state.previewProject[state.ProjectId].Modules[
                                                state.ActiveModuleId
                                            ].slides[state.ActiveSlideId].Screens,
                                            [state.ActiveScreenId]: {
                                                ...state.previewProject[state.ProjectId].Modules[
                                                    state.ActiveModuleId
                                                ].slides[state.ActiveSlideId].Screens[state.ActiveScreenId],
                                                ...action.payload,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                }
            }
            
        }


        case UPDATETEMPLATEURLPREVIEW:{
            console.log(state,"state");
            return {
                ...state,
                previewProject: {
                    ...state.previewProject,
                    [action.payload.projectId]: {
                        ...state.previewProject[action.payload.projectId],
                        Modules: {
                            ...state.previewProject[action.payload.projectId].Modules,
                            [action.payload.ModuleId]: {
                                ...state.previewProject[action.payload.projectId].Modules[
                               action.payload.ModuleId
                                ],
                                slides: {
                                    ...state.previewProject[action.payload.projectId].Modules[
                                       action.payload.ModuleId
                                    ].slides,
                                    [action.payload.SlideId]: {
                                        ...state.previewProject[action.payload.projectId].Modules[
                                           action.payload.ModuleId
                                        ].slides[action.payload.SlideId],
                                        Screens: {
                                            ...state.previewProject[action.payload.projectId].Modules[
                                               action.payload.ModuleId
                                            ].slides[action.payload.SlideId].Screens,
                                            [ action.payload.ScreenId]: {
                                                ...state.previewProject[action.payload.projectId].Modules[
                                                   action.payload.ModuleId
                                                ].slides[action.payload.SlideId].Screens[ action.payload.ScreenId],
                                                
                                                TM01: {
                                                    ...state.previewProject[action.payload.projectId].Modules[
                                                      action.payload.ModuleId
                                                    ].slides[action.payload.SlideId].Screens[
                                                      action.payload.ScreenId
                                                    ].TM01,
                                                    Contentparams: action.payload.templateUrl.Contentparams,
                                                  },
                                                  
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                   
                }
            }
        }
        case PREVIEW_SETOSTFONT: {

            //console.log(action.payload);
            // Object.values(state.previewProject[state.ProjectId].Modules).forEach(slide => {
            //     Object.values(slide.slides).forEach(screen => {
            //         Object.values(screen.Screens).forEach(Screens => {
            //             Object.entries(Screens).map(item => {
            //                 if (item[1].Contenttype === "OST") {
            //                     item[1].Contentparams.FontFamily = FONT_URL + action.payload.fontFamily + "/" + action.payload.fontStyle
            //                     item[1].Contentparams.FontSizeH = action.payload.fontH
            //                     item[1].Contentparams.FontSizeP = action.payload.fontP
            //                     item[1].Contentparams.FontColour = action.payload.fontColor
            //                 }
            //                 if (item[0] === "CS01") {
            //                     item[1].Contentparams.contentdescription = action.payload.bgColor
            //                 }
            //                 //console.log(item);
            //             })
            //         })
            //     })
            // })
            // console.log(state.previewProject);

            return {
                ...state,
                previewProject: {
                    ...state.previewProject,
                    [state.ProjectId]: {
                        ...state.previewProject[state.ProjectId],
                        Modules: {
                            ...state.previewProject[state.ProjectId].Modules,
                            [state.ActiveModuleId]: {
                                ...state.previewProject[state.ProjectId].Modules[
                                state.ActiveModuleId
                                ],
                                slides: {
                                    ...state.previewProject[state.ProjectId].Modules[
                                        state.ActiveModuleId
                                    ].slides,
                                    [state.ActiveSlideId]: {
                                        ...state.previewProject[state.ProjectId].Modules[
                                            state.ActiveModuleId
                                        ].slides[state.ActiveSlideId],
                                        Screens: {
                                            ...state.previewProject[state.ProjectId].Modules[
                                                state.ActiveModuleId
                                            ].slides[state.ActiveSlideId].Screens,
                                            [state.ActiveScreenId]: {
                                                ...state.previewProject[state.ProjectId].Modules[
                                                    state.ActiveModuleId
                                                ].slides[state.ActiveSlideId].Screens[state.ActiveScreenId],
                                                ...action.payload,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                }
            }
        }

        case UPDATE_AUDIOSCRIPT_UPLOAD: {
            
            return {
                ...state,
                previewProject: {
                    ...state.previewProject,
                    [state.ProjectId]: {
                        ...state.previewProject[state.ProjectId],
                        Modules: {
                            ...state.previewProject[state.ProjectId].Modules,
                            [state.ActiveModuleId]: {
                                ...state.previewProject[state.ProjectId].Modules[
                                state.ActiveModuleId
                                ],
                                slides: {
                                    ...state.previewProject[state.ProjectId].Modules[
                                        state.ActiveModuleId
                                    ].slides,
                                    [state.ActiveSlideId]: {
                                        ...state.previewProject[state.ProjectId].Modules[
                                            state.ActiveModuleId
                                        ].slides[state.ActiveSlideId],
                                        SlideAudioVO: action.payload,
                                    },
                                },
                            },
                        },
                    },
                }
            }
        }
        case PREVIOUS_ACTIVE_TO_PREVIEW: {
            console.log(action.payload);
            let modules = state.previewProject[state.ProjectId].Modules;
            let slide_name = modules[action.payload.activeModule].slides[action.payload.activeSlide].SlideName

            let screen_name = modules[action.payload.activeModule].slides[action.payload.activeSlide].Screens[action.payload.activeScreen].ScreenName
            console.log(screen_name);

            //console.log(first_screen1);
            return {
                ...state,
                ActiveModuleId: action.payload.activeModule,
                ActiveModuleName: modules[action.payload.activeModule].ModuleName,
                ActiveSlideId: action.payload.activeSlide,
                ActiveSlideName: slide_name,
                ActiveScreenId: action.payload.activeScreen,
                ActiveScreenName: screen_name
            }
        }
        case ASSIGN_THUMBNAIL: {
            return {
                ...state,
                ScreenThumbnail: action.payload
            }
        }

        case CHANGEPROJECTNAMEINPREVIEW:
            return {
                ...state,
                previewProject: {
                    ...state.previewProject,
                    [state.ProjectId]: {
                        ...state.previewProject[state.ProjectId],
                        ProjectName: action.payload
                    }
                }
            }
        case UPDATETHUMBNAIL: {
            console.log(action.payload);
            if(!state.previewProject_thumbnails[state.ProjectId]){
                return { ...state };
            }
            return {
                ...state,
                previewProject_thumbnails: {
                    ...state.previewProject_thumbnails,
                    [state.ProjectId]: {
                        ...state.previewProject_thumbnails[state.ProjectId],
                        Modules: {
                            ...state.previewProject_thumbnails[state.ProjectId].Modules,
                            [action.payload.ModuleId]: {
                                ...state.previewProject_thumbnails[state.ProjectId].Modules[
                                action.payload.ModuleId
                                ],
                                slides: {
                                    ...state.previewProject_thumbnails[state.ProjectId].Modules[
                                        action.payload.ModuleId
                                    ].slides,
                                    [action.payload.SlideId]: {
                                        ...state.previewProject_thumbnails[state.ProjectId].Modules[
                                            action.payload.ModuleId
                                        ].slides[action.payload.SlideId],
                                        Screens: {
                                            ...state.previewProject_thumbnails[state.ProjectId].Modules[
                                                action.payload.ModuleId
                                            ].slides[action.payload.SlideId].Screens,
                                            [action.payload.ScreenId]: {
                                                ...state.previewProject_thumbnails[state.ProjectId].Modules[
                                                    action.payload.ModuleId
                                                ].slides[action.payload.SlideId].Screens[action.payload.ScreenId],
                                                thumbnailUrl: action.payload.thumbnailUrl
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                }
            }
        }
        case UPDATESCREENPREVIEW:{
            const tempScreenPreviews = {...state.ScreenPreviews};
            const { projectId, moduleId, slideId, screenId, mediaurl } = action.payload;
            tempScreenPreviews[projectId] = { ...tempScreenPreviews[projectId], [JSON.stringify({moduleId, slideId, screenId})]: mediaurl };
            return {
                ...state,
                ScreenPreviews: { ...tempScreenPreviews }
            }
        }
        case DELETESCREENPREVIEW:{
            const tempScreenPreviews = {...state.ScreenPreviews};
            for(const x of action.payload.keysToDelete) {
                delete tempScreenPreviews[action.payload.projectId][x]
            }
            return {
                ...state,
                ScreenPreviews: { ...tempScreenPreviews }
            }
        }
        default:
            return { ...state }
    }
}

export default previewReducer;