import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { showToaster } from "../../../../services/helper";

import { actionCreator } from "../../../../store";
import SaveDataWarningModal from "./SaveDataWarningModal";

export default function OstData(props) {
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const ActiveModule = useSelector((state) => state.preview.ActiveModuleId);
  const ActiveSlide = useSelector((state) => state.preview.ActiveSlideId);
  const ActiveScreen = useSelector((state) => state.preview.ActiveScreenId);
  const [ostRepo, setOstRepo] = useState([]);
  const [foundValue, setFoundValue] = useState([]);
  const [bgColor, setBgColor] = useState(undefined);
  const dispatch = useDispatch();
  const [OstValidation, setOstValidation] = useState(false);
  const [OstValidation1, setOstValidation1] = useState(false);
  const [readOnlyField, setreadOnlyField] = useState(true);
  const [prevOstValue, setprevOstValue] = useState(undefined);
  const [EditedTextareaField, setEditedTextareaField] = useState(undefined);

  let { UpdatePreviewFeature } = bindActionCreators(actionCreator, dispatch);

  useEffect(() => {
    if(project[projectId]!==undefined&&
      project[projectId]["Modules"]!==undefined&&
   project[projectId]["Modules"][ActiveModule]!==undefined&&
    project[projectId]["Modules"][ActiveModule]["slides"]!==undefined&&
   project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]!==undefined&&
   project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens"]!==undefined&& 
   project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens" ][ActiveScreen]!==undefined){
    let activeScreenDetails =
      project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
        "Screens"
      ][ActiveScreen];
    let i = 0;
    Object.entries(activeScreenDetails).map((item, key) => {
      let fontbgcolor;
      if (item[0] === "CS01") {
      
        fontbgcolor= item[1].Contentparams.contentdescription;
        setBgColor(fontbgcolor)
        console.log(fontbgcolor,"item[0]");
      }

    })
  }
    updateState();
  }, []);

  useEffect(() => {
    updateState();
  }, [ActiveScreen]);

  const updateState = () => {
    let ostValuesArray = [];
    let foundValuearray = [];
    let selecetdostOnSAve = [];
    let activeScreenDetails =
      project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
        "Screens"
      ][ActiveScreen];

    Object.entries(activeScreenDetails).map((item, key) => {
      let found = item.filter(function (data) {
        return data.Contenttype === "OST";
      });
      let ostkey = item[0];
      if (ostkey.substring(0, 2) === "OS") {
        let ostChar = item[0].charAt(2);
        if (/^[0-0]+$/.test(ostChar)) {
          let s = item[0].substring(0, 2) + "T" + item[0].substring(2 + 1);
          var lastFive;
          if (
            found[0]["Contentparams"]["contentdescription"].includes("[H]") ||
            found[0]["Contentparams"]["contentdescription"].includes("[P]") ||
            found[0]["Contentparams"]["contentdescription"].includes("[B]")
          ) {
            lastFive = found[0]["Contentparams"]["contentdescription"].substr(
              found[0]["Contentparams"]["contentdescription"].length - 3
            );
          }
          var ddd;
          let ostPoint;
          if (lastFive === "[H]") {
            ostPoint = "(Heading)";
          } else if (lastFive === "[P]") {
            ostPoint = "(Paragraph)";
          } else {
            ostPoint = "(Bullet)";
          }
          if (lastFive !== undefined && lastFive !== null && lastFive !== "") {
            ddd = s + ostPoint;
          } else {
            ddd = s;
          }
          selecetdostOnSAve.push(ddd);
        }
        if (/^[1-9]+$/.test(ostChar)) {
          let a =
            item[0].substring(0, 2) +
            "T" +
            item[0].substring(2, item[0].length);
          var lastFive;
          if (
            found[0]["Contentparams"]["contentdescription"].includes("[H]") ||
            found[0]["Contentparams"]["contentdescription"].includes("[P]") ||
            found[0]["Contentparams"]["contentdescription"].includes("[B]")
          ) {
            lastFive = found[0]["Contentparams"]["contentdescription"].substr(
              found[0]["Contentparams"]["contentdescription"].length - 3
            );
          }
          var ddd;
          let ostPoint;
          if (lastFive === "[H]") {
            ostPoint = "(Heading)";
          } else if (lastFive === "[P]") {
            ostPoint = "(Paragraph)";
          } else {
            ostPoint = "(Bullet)";
          }
          if (lastFive !== undefined && lastFive !== null && lastFive !== "") {
            ddd = a + ostPoint;
          } else {
            ddd = a;
          }
          // var ddd=a+":"+lastFive
          selecetdostOnSAve.push(ddd);
        }
      }
      if (found.length > 0) {
        let osttemp = Object.keys(item[0]);
        // console.log("osttemp", osttemp)
        // osttemp.shift();
        let a = found.map((i) => {
          return i.Contenttype;
        });
        foundValuearray.push(a);
        setFoundValue(selecetdostOnSAve);
        //console.log(selecetdostOnSAve, "selecetdostOnSAve");

        // this.setState({ foundValue: selecetdostOnSAve });
        let lastFive;
        let ostValues = found[0]["Contentparams"]["contentdescription"];
        if (
          found[0]["Contentparams"]["contentdescription"].includes("[H]") ||
          found[0]["Contentparams"]["contentdescription"].includes("[P]") ||
          found[0]["Contentparams"]["contentdescription"].includes("[B]")
        ) {
          lastFive = found[0]["Contentparams"]["contentdescription"].substr(
            found[0]["Contentparams"]["contentdescription"].length - 3
          );
        }
        if (lastFive === "[H]" || lastFive === "[B]" || lastFive === "[P]") {
          var ostValue = ostValues.slice(0, -3);
          ostValuesArray.push(ostValue);
        } else {
          ostValuesArray.push(ostValues);
        }
        setOstRepo(ostValuesArray);
      }
    });
  };

  const ListOST = () => {
    let ostList = [];
    if(project[projectId]!==undefined&&
      project[projectId]["Modules"]!==undefined&&
   project[projectId]["Modules"][ActiveModule]!==undefined&&
    project[projectId]["Modules"][ActiveModule]["slides"]!==undefined&&
   project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]!==undefined&&
   project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens"]!==undefined&& 
   project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide]["Screens" ][ActiveScreen]!==undefined){
    let activeScreenDetails =
      project[projectId]["Modules"][ActiveModule]["slides"][ActiveSlide][
        "Screens"
      ][ActiveScreen];
    let i = 0;
    Object.entries(activeScreenDetails).map((item, key) => {
      let fontbgcolor;
      if (item[0] === "CS01") {
        console.log(item[1],"item[0]");
        fontbgcolor= item[1].Contentparams.contentdescription;
      }
     
      if (item[1].Contenttype === "OST") {
        i++;
        let fontsizeh=item[1].Contentparams.FontSizeH
       let fontsizep= item[1].Contentparams.FontSizeP ;
      let fontextcolor=  item[1].Contentparams.FontColour ;
      let fontFamily=  item[1].Contentparams.FontFamily.split("/")[4]
      
        console.log(fontFamily, "fontFamily");
        var ostData = item[1].Contentparams.contentdescription;

        var ostValue = ostData.slice(0, -3);
        var ostType = ostData.substr(ostData.length - 3);
        let ostPoint;
        if (ostType === "[H]") {
          ostPoint = "(Heading)";
        } else if (ostType === "[P]") {
          ostPoint = "(Paragraph)";
        } else {
          ostPoint = "(Bullet)";
        }
        ostList.push(
          <div className="card p-2">
            
            <div className="card-header d-flex justify-content-between align-items-center">
             
              <a
                data-bs-toggle={OstValidation ? "" : "collapse"}
                data-bs-target={`#collapse-${key}`}
              >
                {" "}
                {/* {item} */}
                OST {item[0].toString().slice(2)}
              </a>
              {
              // ostType === "[H]" ? (
              //   <div className="Decoration-btn">
              //     <button type="button" className="btn editor-btn br-2">
              //       <svg
              //         className="fill-i"
              //         width="10"
              //         height="12"
              //         viewBox="0 0 10 12"
              //         fill="none"
              //         xmlns="http://www.w3.org/2000/svg"
              //       >
              //         <path d="M0 11.6364H2.46023V6.82955H7.45455V11.6364H9.90909V0H7.45455V4.80114H2.46023V0H0V11.6364Z" />
              //       </svg>
              //     </button>
              //   </div>
              // ) : 
              ostType === "[P]" ? (
                <div className="Decoration-btn">
                  <button type="button" className="btn editor-btn br-2">
                    <svg
                      className="outline-i"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.8125 9H13.5"
                        stroke=""
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.8125 4.5H15.1875"
                        stroke=""
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M2.8125 13.5H9"
                        stroke=""
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              ) 
              // : ostType === "[B]" ? (
              //   <div className="Decoration-btn">
              //     <button type="button" className="btn editor-btn br-2">
              //       <svg
              //         className="fill-i"
              //         width="12"
              //         height="12"
              //         viewBox="0 0 12 12"
              //         fill="none"
              //         xmlns="http://www.w3.org/2000/svg"
              //       >
              //         <path d="M1.38462 2.76923C0.618462 2.76923 0 2.15077 0 1.38462C0 0.627692 0.618462 0 1.38462 0C2.14154 0 2.76923 0.627692 2.76923 1.38462C2.76923 2.15077 2.14154 2.76923 1.38462 2.76923ZM3.69231 0.923077H12V1.84615H3.69231V0.923077ZM1.38462 7.38462C0.618462 7.38462 0 6.76615 0 6C0 5.24308 0.618462 4.61538 1.38462 4.61538C2.14154 4.61538 2.76923 5.24308 2.76923 6C2.76923 6.76615 2.14154 7.38462 1.38462 7.38462ZM3.69231 5.53846H12V6.46154H3.69231V5.53846ZM1.38462 12C0.618462 12 0 11.3815 0 10.6154C0 9.85846 0.618462 9.23077 1.38462 9.23077C2.14154 9.23077 2.76923 9.85846 2.76923 10.6154C2.76923 11.3815 2.14154 12 1.38462 12ZM3.69231 10.1538H12V11.0769H3.69231V10.1538Z" />
              //       </svg>
              //     </button>
              //   </div>
              // ) 
              : (
                ""
              )}
              {readOnlyField ? (
                // <button
                //   className="btn btn-primary"
                //   onClick={() => {
                //     EditField(item, `ostTextarea${key}`);
                //   }}
                // >
                //   Edit
                // </button>
                <span
                  className="action-btn ms-1"
                  onClick={() => {
                    EditField(item, `ostTextarea${key}`);
                  }}
                >
                  <svg
                    width="16px"
                    height="16px"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    enable-background="new 0 0 512 512"
                  >
                    <g>
                      <g>
                        <path d="m455.1,137.9l-32.4,32.4-81-81.1 32.4-32.4c6.6-6.6 18.1-6.6 24.7,0l56.3,56.4c6.8,6.8 6.8,17.9 0,24.7zm-270.7,271l-81-81.1 209.4-209.7 81,81.1-209.4,209.7zm-99.7-42l60.6,60.7-84.4,23.8 23.8-84.5zm399.3-282.6l-56.3-56.4c-11-11-50.7-31.8-82.4,0l-285.3,285.5c-2.5,2.5-4.3,5.5-5.2,8.9l-43,153.1c-2,7.1 0.1,14.7 5.2,20 5.2,5.3 15.6,6.2 20,5.2l153-43.1c3.4-0.9 6.4-2.7 8.9-5.2l285.1-285.5c22.7-22.7 22.7-59.7 0-82.5z"></path>
                      </g>
                    </g>
                  </svg>
                </span>
              ) : (
                <>
                  {/* <button
                    className="btn btn-primary"
                    onClick={() => {
                      SaveUpdate();
                    }}
                  >
                    Save
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      CancelUpdate();
                    }}
                  >
                    Cancel
                  </button> */}
                  <span className="action-btn me-1 ms-1">
                    <svg
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      fill="#31d131"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => {
                        SaveUpdate();
                      }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.8198 6.19526C20.0601 6.45561 20.0601 6.87772 19.8198 7.13807L9.9736 17.8047C9.73328 18.0651 9.34364 18.0651 9.10332 17.8047L4.18024 12.4714C3.93992 12.2111 3.93992 11.7889 4.18024 11.5286C4.42056 11.2682 4.8102 11.2682 5.05053 11.5286L9.53846 16.3905L18.9495 6.19526C19.1898 5.93491 19.5794 5.93491 19.8198 6.19526Z"
                        fill=""
                      ></path>
                    </svg>
                  </span>
                  <span className="action-btn" onClick={() => {
                      CancelUpdate();
                    }}>
                    <svg
                      width="16px"
                      height="16px"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="red"
                    >
                      <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
                    </svg>
                  </span>
                </>
              )}
            </div>
            <div
              className=" collapse "
              data-bs-parent="#child-1"
              // id="collapse-1-A"
              id={`collapse-${key}`}
            >
              <div className="card-body">
                <div className="form-group">
                {ostType == "[P]" && (
                  <>
                      <textarea
                      onChange={(e) => {
                        if (e.target.value.length > 1500) {
                          setOstValidation(true);
                          props.setOstValidation(true);
                        }else if(e.target.value.length <1){
                          // props.setOstValidation1(true);
                          showToaster('Minimum 1 character is Required', 'warning');
                          setOstValidation1(true);
                        } else {
                          // props.setOstValidation1(false);
                          setOstValidation1(false);
                          props.setOstValidation(false);
                          setOstValidation(false);
                          // handlechangeOst(e, item[0], item[1], ostType);
                          item[1].Contentparams.contentdescription =
                            e.target.value + ostType;
                          let data = {
                            [item[0]]: item[1],
                          };
                          UpdatePreviewFeature(data);
                        }
                      }}
                        // style={{
                        //   lineHeight: "22px",
                        //   color: fontextcolor,
                        //   backgroundColor: bgColor!==undefined&&bgColor,
                        //   fontSize: fontsizep + "px",
                        //   fontFamily:
                        //     fontFamily !== undefined && fontFamily,
                        // }}
                        className="form-control text-tab"
                        value={ostValue}
                        id={`ostTextarea${key}`}
                        readOnly={readOnlyField}
                      ></textarea>
                      <p>{ostValue.length} character</p>
                      </>
                    )}
                    {/* {ostType == "[H]" && (
                      <>
                      <textarea
                      onChange={(e) => {
                        if (e.target.value.length > 1500) {
                          setOstValidation(true);
                          props.setOstValidation(true);
                        }else if(e.target.value.length <1){
                          // props.setOstValidation1(true);
                          showToaster('Minimum 1 character is Required', 'warning');
                          setOstValidation1(true);
                        } else {
                          // props.setOstValidation1(false);
                          setOstValidation1(false);
                          props.setOstValidation(false);
                          setOstValidation(false);
                          // handlechangeOst(e, item[0], item[1], ostType);
                          item[1].Contentparams.contentdescription =
                            e.target.value + ostType;
                          let data = {
                            [item[0]]: item[1],
                          };
                          UpdatePreviewFeature(data);
                        }
                      }}
                        // style={{
                        //   lineHeight: "22px",
                        //   color: fontextcolor,
                        //   backgroundColor: bgColor!==undefined&&bgColor,
                        //   fontSize: fontsizeh + "px",
                        //   fontFamily:
                        //     fontFamily !== undefined && fontFamily,
                        // }}
                        className="form-control tab-text"
                        value={ostValue}
                        id={`ostTextarea${key}`}
                        readOnly={readOnlyField}
                      ></textarea>
                      <p>{ostValue.value} character</p>
                      </>
                    )}
                    {ostType == "[B]" && (
                      <>
                      <textarea
                      onChange={(e) => {
                        if (e.target.value.length > 1500) {
                          setOstValidation(true);
                          props.setOstValidation(true);
                        }else if(e.target.value.length <1){
                          // props.setOstValidation1(true);
                          showToaster('Minimum 1 character is Required', 'warning');
                          setOstValidation1(true);
                        } else {
                          // props.setOstValidation1(false);
                          setOstValidation1(false);
                          props.setOstValidation(false);
                          setOstValidation(false);
                          // handlechangeOst(e, item[0], item[1], ostType);
                          item[1].Contentparams.contentdescription =
                            e.target.value + ostType;
                          let data = {
                            [item[0]]: item[1],
                          };
                          UpdatePreviewFeature(data);
                        }
                      }}
                        style={{
                          lineHeight: "22px",
                          color: fontextcolor,
                          backgroundColor:  bgColor!==undefined&&bgColor,
                          fontSize: fontsizep + "px",
                          fontFamily:
                            fontFamily !== undefined && fontFamily,
                        }}
                        className="form-control"
                        value={ostValue}
                        id={`ostTextarea${key}`}
                        readOnly={readOnlyField}
                      ></textarea>
                      <p>{ostValue.length} character</p>
                      </>
                    )} */}
                 
                </div>
              </div>
              {OstValidation ? (
                <div
                  name="courseName"
                  component="div"
                  className="input-error-msg red"
                >
                  Must be less than 1500 characters
                </div>
              ) : null}
              {/* {OstValidation1 ? (
                <div
                  name="courseName"
                  component="div"
                  className="input-error-msg red"
                >
                  Must be greater than 1 character
                </div>
              ) : null} */}
            </div>
          </div>
        );
      }
    });
    return ostList;
  }
  };

  const handlechangeOst = (e, ostName, ost, ostType) => {
    //console.log(data);
  };

  const EditField = (oldValue, field) => {
    console.log(oldValue,field,"oldValue");
    props.setpreviewEditMode(true)
    setreadOnlyField(false);
    setEditedTextareaField(field);
    setprevOstValue(JSON.stringify(oldValue));
  };

  const SaveUpdate = () => {
    setreadOnlyField(true);
    showToaster("Successfully saved", "success");
    // UpdatePreviewFeature(data);
    setprevOstValue(undefined);
    setEditedTextareaField(undefined);
    props.setchanges(true)
    props.setpreviewEditMode(false)
    props.setshowWarning(false)
  };

  const CancelUpdate = () => {
    let prevOstValues = JSON.parse(prevOstValue);
    let data = {
      [prevOstValues[0]]: prevOstValues[1],
    };
    UpdatePreviewFeature(data);
    setreadOnlyField(true);
    setprevOstValue(undefined);
    setEditedTextareaField(undefined);
    props.setpreviewEditMode(false)
    props.setshowWarning(false)
  };

  return (
    <div className="card">
      <div className="card-header" id="heading-2">
        <h5 className="mb-0">
          <a
            className="btn btn-link collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#ost-2"
            aria-expanded="false"
            aria-controls="ost-2"
          >
            {" "}
            OST{" "}
          </a>
        </h5>
      </div>
      <div
        id="ost-2"
        className="collapse"
        aria-labelledby="heading-2"
        data-bs-parent="#accordion-1"
      >
        <div className="card-body" id="child-1">
          <div className="card-inner p-0">
            {ListOST()}
            {/* {foundValue.map((item, i) => (
              <div className="card">
                <div className="card-header">
                  <a
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${i}`}
                  >
                    {" "}
                    {item}
                  </a>
                </div>
                <div
                  className=" collapse "
                  data-bs-parent="#child-1"
                  // id="collapse-1-A"
                  id={`collapse-${i}`}
                >
                  <div className="card-body">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        onChange={(e) => {
                          handlechangeOst(e, i);
                        }}
                        value={ostRepo[i]}
                        id=""
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </div>
      <SaveDataWarningModal show={props.showWarning} cancel={() => CancelUpdate()} save={() => SaveUpdate()}/>
    </div>
  );
}
