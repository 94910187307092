
import React, { useState } from 'react'
import { Modal, ModalBody } from "reactstrap";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import Videoplayer from "../../../components/Videoplayer/Videoplayer";

export default function PreviewModal(props) {
    let DisplayMode = useSelector(state => state.project.DisplayMode)

    const [playing, setplaying] = useState(false);
    const [url, seturl] = useState(null);
    const handleStop = () => {
        setplaying(false)
        seturl(null)
        // this.setState({ url: null, playing: false })
    }
    return (


        <Modal

            isOpen={props.openPreviewModals}
            fade={true}
            className={DisplayMode ? "modal-dialog  modal-dialog-centered custom-modal preview-modal dark " : "modal-dialog  modal-dialog-centered custom-modal preview-modal"}
            // className="modal-dialog  modal-dialog-centered custom-modal replace-modal dark "
            centered
        >
            <ModalBody id="preview-modal" >
                {/* <div className="modal fade" id="preview-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered custom-modal preview-modal">
                        <div className="modal-content border-0">
                            <div className="modal-body"> */}
                <div className="preview-modal-close" data-bs-dismiss="modal" onClick={() => props.closePreview()}>
                    <Tooltip title="Close" arrow>
                        <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g data-name="Layer 2"><g data-name="close"><rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" /><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" /></g></g></svg>
                    </Tooltip> </div>
                {/* <ReactPlayer url={props.previewUrl} light={props.thumbnailUrl} controls width="100%" muted={true} playing={true} /> */}
                <Videoplayer thumbnailUrl={props.thumbnailUrl} videoUrl={props.previewUrl}  showVolume={true}/>


                {/* <video id='video' controls="controls" preload='none' width="100%" poster="assets/img/video-poster.png">
                                    <source id='mp4' src="http://media.w3.org/2010/05/sintel/trailer.mp4" type='video/mp4' />
                                    <source id='webm' src="http://media.w3.org/2010/05/sintel/trailer.webm" type='video/webm' />
                                    <source id='ogv' src="http://media.w3.org/2010/05/sintel/trailer.ogv" type='video/ogg' />
                                </video> */}

                {/* <div className="video-control">
                    <div className="rSlider">
                        <span className="slide"></span>
                        <input id="range" type="range" min="0" max="50000" />
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <a onClick={handleStop} className="play-btn me-3">
                                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0V14L11 7L0 0Z" fill="" />
                                </svg>
                            </a>
                            <a href="" className="play-btn me-3">
                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.5 8C13.5 6.23 12.48 4.71 11 3.97V12.02C12.48 11.29 13.5 9.77 13.5 8ZM0 5V11H4L9 16V0L4 5H0Z" fill="" />
                                </svg>
                            </a>
                            <div className="time-duration">
                                <span>0:00 </span>/<span> 4:00</span>
                            </div>
                        </div>

                    </div>
                </div> */}

                {/* </div>
                        </div>
                    </div>
                </div> */}


            </ModalBody>
        </Modal>
    )
}



