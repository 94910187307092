import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Duration from "./Duration";
import screenfull from "screenfull";
import { findDOMNode } from "react-dom";
import Tooltip from "@mui/material/Tooltip";
import demoImg from "../../../assets/img/demo-img.png";
import { NULL } from "xlsx-populate/lib/FormulaError";
function VideoplayerWithPreview(props) {
  const [isPlaying, setisPlaying] = useState(false);
  const [played, setplayed] = useState(0);
  const [seeking, setseeking] = useState(false);
  const [duration, setduration] = useState(0);
  const [volume, setvolume] = useState(0);
  const [muted, setmuted] = useState(false);
  const [light, setlight] = useState(undefined);
  const [thumbnailUrl, setthumbnailUrl] = useState("");
  const [videoUrl, setvideoUrl] = useState("");
  const [showProgress, setshowProgress] = useState(false);

  const videoPlayer = useRef(null);

  useEffect(() => {
    console.log(props, "propssssssss");
    if (props.thumbnailUrl != undefined) {
      setlight(props.thumbnailUrl);
      setthumbnailUrl(props.thumbnailUrl);
      setvideoUrl(props.videoUrl);
    } else {
      setlight(undefined);
      setthumbnailUrl("");
      setvideoUrl("");
    }
  }, [props]);

  const handleVideoPlay = (status) => {
    // console.log(status);
    if (status === "play") {
      //setlight(false);
      setisPlaying(true);
    } else {
      setisPlaying(false);
    }
  };
  // useEffect(() => {
  //   if(isPlaying){
  //     if ((duration * played)===duration) {
  //       console.log(duration * played,duration,"played");
  //       setisPlaying(false);
  //     }
  //   }

  // }, [isPlaying])
  const handleSeekChange = (e) => {
    setplayed(parseFloat(e.target.value));
  };

  const handleSeekMouseDown = (e) => {
    setseeking(true);
  };
  const handleSeekMouseUp = (e) => {
    //console.log(parseFloat(videoPlayer.current.seekTo()));
    setseeking(false);
    videoPlayer.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (e) => {
    setplayed(e.playedSeconds / e.loadedSeconds);
    //setseeking(true);
  };

  const handleDuration = (e) => {
    setduration(e);
  };

  const handleVolumeChange = (e) => {
    setvolume(e.target.value);
  };

  const handleToggleMuted = (status) => {
    setmuted(status);
  };

  const handleFullScreen = () => {
    screenfull.request(findDOMNode(videoPlayer.current));
  };

  return (
    <div
      className={
        props !== undefined &&
        props.disabled !== undefined &&
        props.disabled &&
        "not-active"
      }
    >
      <div className="video-wrap">
        {showProgress && (
          <img
            src="../../../assets/img/loader.gif"
            className="video-loader-gif"
          />
        )}
        {isPlaying ? (
          <ReactPlayer
            ref={videoPlayer}
            //light={light}
            url={videoUrl}
            // controls
            volume={volume}
            width="100%"
            // height={'calc(100vh - 375px)'}
            height="100%"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
            muted={muted}
            playing={isPlaying}
            onProgress={(e) => handleProgress(e)}
            onDuration={(e) => handleDuration(e)}
            onEnded={(e) => {
              setisPlaying(false);
              setplayed(0);
            }}
            onBufferEnd={(e) => {
              setshowProgress(false);
            }}
            onBuffer={(e) => {
              setshowProgress(true);
            }}
          />
        ) : (
          <>
            {props.loading ? (
              <div className="loader-wrapper video-loader">
                <div className="loader-inner">
                  <img src="../../../assets/img/loader.gif"></img>
                </div>
              </div>
            ) : (
              <img
                src={
                  light == undefined
                    ? demoImg
                    : light + "?time=" + new Date().getTime()
                }
                onError={(e) => {
                  e.target.setAttribute("src", demoImg);
                  console.log(light, "light");
                }}
                className="thumbnail-img"
              />
            )}
          </>
        )}
      </div>
      <div className="video-control">
        <div className="rSlider">
          {/* <span className="slide"></span> */}
          {/* <input id="range" type="range" min="0" max="50000" /> */}
          <input
            type="range"
            min={0}
            max={0.999999}
            step="any"
            value={played}
            onMouseDown={(e) => handleSeekMouseDown(e)}
            onChange={(e) => handleSeekChange(e)}
            onMouseUp={(e) => handleSeekMouseUp(e)}
          />
        </div>

        <div className="d-flex justify-content-between align-items-center ps-2 pe-1">
          <div className="d-flex align-items-center">
            {isPlaying ? (
              <a
                className="play-btn pointer"
                onClick={() => handleVideoPlay("pause")}
              >
                <Tooltip title="Pause" arrow>
                  <svg width="11" height="13" viewBox="0 0 11 13" fill="none">
                    <rect y="0.5" width="4" height="12" fill="" />
                    <rect x="7" y="0.5" width="4" height="12" fill="" />
                  </svg>
                </Tooltip>
              </a>
            ) : (
              <a
                className="play-btn pointer"
                onClick={() => handleVideoPlay("play")}
              >
                {" "}
                <Tooltip title="Play" arrow>
                  <svg width="11" height="14" viewBox="0 0 11 14" fill="none">
                    <path d="M0 0V14L11 7L0 0Z" fill="" />
                  </svg>
                </Tooltip>
              </a>
            )}
            {played ? (
              <div className="time-duration ms-3">
                <span>
                  <Duration seconds={duration * played} />{" "}
                </span>

                <span>
                  {" "}
                  <Duration seconds={duration} />
                </span>
              </div>
            ): null}
            {props.showVolume != undefined && props.showVolume && (
              <div className="volume-control pointer ms-3">
                <div className="volume-icon">
                  {!muted ? (
                    <svg
                      onClick={() => handleToggleMuted(true)}
                      width="16"
                      height="19"
                      viewBox="0 0 14 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 8.5C13.5 6.73 12.48 5.21 11 4.47V12.52C12.48 11.79 13.5 10.27 13.5 8.5ZM0 5.5V11.5H4L9 16.5V0.5L4 5.5H0Z"
                        fill=""
                      />
                    </svg>
                  ) : (
                    <a onClick={() => handleToggleMuted(false)}>
                      <svg
                        version="1.1"
                        id="Layer_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                        style={{ enableBackground: "new 0 0 512 512" }}
                        space="preserve"
                        width="16"
                        height="19"
                      >
                        <g>
                          <g>
                            <polygon
                              points="214.986,24.477 99.224,140.238 0,140.238 0,371.762 99.224,371.762 214.986,487.523 231.523,487.523 
    231.523,24.477 		"
                            />
                          </g>
                        </g>
                        <g>
                          <g>
                            <polygon
                              points="512,191.685 476.919,156.604 412.604,220.919 348.287,156.604 313.208,191.685 377.523,256 313.208,320.315 
    348.287,355.396 412.604,291.081 476.919,355.396 512,320.315 447.683,256 		"
                            />
                          </g>
                        </g>
                      </svg>
                    </a>
                  )}
                </div>
                <input
                  type="range"
                  min={0}
                  max={1}
                  step="any"
                  value={volume}
                  onChange={handleVolumeChange}
                />
              </div>
            )}
          </div>
          <a className="play-btn pointer" onClick={handleFullScreen}>
            <Tooltip title="Full Screen" arrow>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.28571 10.2857H0V16H5.71429V13.7143H2.28571V10.2857ZM0 5.71429H2.28571V2.28571H5.71429V0H0V5.71429ZM13.7143 13.7143H10.2857V16H16V10.2857H13.7143V13.7143ZM10.2857 0V2.28571H13.7143V5.71429H16V0H10.2857Z"
                  f
                />
              </svg>
            </Tooltip>
          </a>
        </div>
      </div>
    </div>
  );
}

export default VideoplayerWithPreview;
