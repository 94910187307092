import React, { useEffect, useState, useRef } from "react";
import { Modal, ModalBody } from "reactstrap";
import ReactPlayer from "react-player";
import { LinearProgress, Tooltip } from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import Videoplayer from "../../components/Videoplayer/Videoplayer";
import { useSelector, useDispatch } from "react-redux";
import {
  getProjectPreview,
  renderPreview,
  renderSerial,
  createProjects,
  getFinalRenderVideo,
} from "../../../services/api.service";
import { bindActionCreators } from "redux";
import { actionCreator } from "../../../store";
import {
  CreatePreviewFromResponse,
  removeMediaUrlFilter,
} from "../../../services/DataHelper";
import { ShowAlertBox, showToaster } from "../../../services/helper";
import LinearWithValueLabel from "../../components/LinearProgressWithLabel";
import { useHistory } from "react-router-dom";
import {
  getProjectById,
  getUsersProjectRole,
} from "../../../services/authService";
export default function RenderProject(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const project = useSelector((state) => state.preview.previewProject);
  const projectId = useSelector((state) => state.preview.ProjectId);
  const [selectedVideo, setselectedVideo] = useState(undefined);
  const [videos, setVideos] = useState(undefined);
  const [startRender, setstartRender] = useState(false);
  const [renderStatus, setrenderStatus] = useState(0);
  const [statusMessage, setstatusMessage] = useState("");
  const [TemplateAvailable, setTemplateAvailable] = useState(true);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [pageLoaded, setPageLoaded] = useState(false);
  let { UpdatePreview } = bindActionCreators(actionCreator, dispatch);
  const timerRef = useRef(null);
  const mounted = useRef(true);
  useEffect(() => {
    //getPreview(projectId,project)
           console.log(props,"prooooo");
    if (props.openRenderVideoSerial && !startRender) {
      setVideos([]);
      renderProject();
    }
    if (!props.openRenderVideoSerial) {
      clearTimeout(timerRef.current);
    }
  }, [props]);

  useEffect(() => {
    return () => {
      mounted.current = false;
      clearTimeout(timerRef.current);
    };
  }, []);

  useEffect(() => {
    // console.log(props);
    // if (props != undefined && props != null && props != "") {
    //   if (
    //     props.priviewrenderUrl != undefined &&
    //     props.priviewrenderUrl != "" &&
    //     props.priviewrenderUrl != null
    //   ) {
    //     setselectedVideo(props.priviewrenderUrl[0].url);
    //     console.log(props);
    //   }
    // }
  }, [props]);
  const goToDashboard = () => {
    history.push("/project-list");
  };
  const renderProject = async () => {
    setstartRender(true);
    //setrenderProject(project);
    let verifyTemplateAvailable = await props.checkTemplateAvailable(
      project,
      projectId
    );
    let renderFilter = {
      project_id: projectId,
      projectName: project[projectId].ProjectName,
    };
    let projectDatafromDb = await getProjectById(projectId);
    if (projectDatafromDb.data.data.length > 0) {
      UpdatePreview(JSON.parse(projectDatafromDb.data.data[0].ProjectData));
    }
    renderSerial(renderFilter).then((response) => {
      if (
        response.message !== undefined &&
        response.message !== null &&
        response.message !== ""
      ) {
        console.log(response, "response");
        setTemplateAvailable(true);
        getPreview(projectId, project, projectDatafromDb);
      } else {
        ShowAlertBox(
          "Oops...",
          "Something went wrong. Please try again",
          "error"
        );
      }
    });
  };

  const getPreview = (ProjectID, project, projectDatafromDb) => {
    let renderFilter = {
      project_id: ProjectID,
      projectName: project[projectId].ProjectName,
    };
    getFinalRenderVideo(renderFilter)
      .then((response) => {
        if (!props.openRenderVideoSerial) {
          if (mounted && mounted.current) {
            setstartRender(false);
          }
          return;
        }
        console.log(response, "apiStatus");
        if (
          response.data !== undefined &&
          response.data !== null &&
          response.data !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200
        ) {
          // if (response.apiStatus === false) {
          // }
          console.log(response, "111111111111");
          var renderStatus = false;
          if (
            response.data !== undefined &&
            response.data !== null &&
            response.data !== ""
          ) {
            let mediaurl1 = response.data;
            var arrayValueKey = [];
              Object.keys(mediaurl1).map(function (key, index) {
                arrayValueKey.push(key)
              });
              console.log(arrayValueKey[arrayValueKey.length - 1])
              let lastArray=arrayValueKey[arrayValueKey.length - 1]
              console.log(response.data[lastArray].final_video_url,"qwerty");
            if (response.data[lastArray].final_video_url !== ""
            ) {
              setrenderStatus(100);
              var arrayValue = [];
              let mediaurl = response.data;
              Object.keys(mediaurl).map(function (key, index) {
                if (mediaurl[key].final_video_url !== "") {
                  arrayValue.push(mediaurl[key].final_video_url);
                  console.log(arrayValue);

                }
              });
              console.log(arrayValue);

              if (
                arrayValue !== undefined &&
                arrayValue !== null &&
                arrayValue !== ""
              ) {
                console.log(arrayValue);
                setVideos(arrayValue);
              }
            } else {
              timerRef.current = setTimeout(() => {
                getPreview(ProjectID, project);
              }, 5000);
            }
          } else if (
            response.message !== undefined &&
            response.message !== null &&
            response.message !== ""
          ) {
            timerRef.current = setTimeout(() => {
              getPreview(ProjectID, project);
            }, 5000);
          }
        }
      })
      .catch(console.log);
  };

  return (
    <Modal
      isOpen={props.openRenderVideoSerial}
      fade={true}
      className={
        DisplayMode
          ? "modal-dialog  modal-dialog-centered custom-modal preview-modal dark"
          : "modal-dialog  modal-dialog-centered custom-modal preview-modal"
      }
      // className="modal-dialog  modal-dialog-centered custom-modal preview-modal"
      centered
    >
      <ModalBody>
        {/* <div className="modal fade" id="preview-modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog  modal-dialog-centered custom-modal preview-modal">
                        <div className="modal-content border-0">
                            <div className="modal-body"> */}

        <div
          className="preview-modal-close"
          data-bs-dismiss="modal"
          onClick={() => {
            setstartRender(false);
            props.closePreviewSerial();
          }}
        >
          <Tooltip title="Close" arrow>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g data-name="Layer 2">
                <g data-name="close">
                  <rect
                    width="24"
                    height="24"
                    transform="rotate(180 12 12)"
                    opacity="0"
                  />
                  <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" />
                </g>
              </g>
            </svg>
          </Tooltip>
        </div>
        <div>
          {/* {TemplateAvailable ? ( */}
          <div>
            <>
              {videos != undefined && videos.length > 0 ? (
                <Videoplayer
                  thumbnailUrl=""
                  videoUrl={
                    selectedVideo !== undefined ? selectedVideo : videos[0]
                  }
                  showVolume={true}
                />
              ) : (
                <>
                  {/* <CircularProgress
                    color="inherit"
                    size="2rem"
                    style={{ position: "absolute", left: "50%", top: "50%",color:'aliceblue' }}
                  /> */}
                  <Videoplayer
                    thumbnailUrl="../../../assets/img/loader.gif"
                    videoUrl={""}
                    showVolume={true}
                    disabled={true}
                  />
                </>
              )}
            </>
            {/* <ReactPlayer
                url={selectedVideo}
                controls
                width="100%"
                muted={true}
                playing={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "menu",
                    },
                  },
                }}
              /> */}
            {/* {videos != undefined && videos.length > 0 ? (
              <div className="video-control">
                <div className="slide-wrap">
                  <ul className="slide-inner">
                    {videos.map((video, i) => {
                      console.log(video);
                      return (
                        <li
                          className="active"
                          onClick={() => setselectedVideo(video.url)}
                        >
                          <div className="img-wrap">
                            <ReactPlayer
                              url={video.url}
                              controls={false}
                              width="100%"
                              height=""
                              muted={true}
                              playing={false}
                            />
                            <h3>
                              SL{Number(i + 1) <= 9 ? "0" + (i + 1) : i + 1}
                            </h3>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            ) :  */}
            {pageLoaded ? (
              <div className="loader-wrapper video-loader">
                <div className="loader-inner">
                  <img src="../../../assets/img/loader.gif"></img>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="video-control">
              <div className="slide-wrap">
                <ul className="slide-inner">
                  {props.setSlideCount.length > 0 &&
                    props.setSlideCount.map((video, i) => {
                      return (
                        <li
                          className={
                            videos !== undefined && videos[i] !== undefined
                              ? selectedVideo == videos[i] && "active"
                              : "not-active"
                          }
                        >
                          <div
                            className="img-wrap"
                            onClick={() => {
                              setselectedVideo(videos[i]);
                            }}
                          >
                            {videos !== undefined && videos[i] !== undefined ? (
                              <>
                                <ReactPlayer
                                  url={
                                    videos !== undefined &&
                                    videos[i] !== undefined
                                      ? videos[i]
                                      : ""
                                  }
                                  controls={false}
                                  width="100%"
                                  height=""
                                  muted={true}
                                  playing={false}
                                />
                                <h3>{video}</h3>
                              </>
                            ) : (
                              <>
                                <span className="hover-text">
                                  Rendering in queue
                                </span>
                                <Videoplayer
                                  videoUrl="../../../assets/img/videobutton.png"
                                  thumbnailUrl={props.thumbnailUrl[i]}
                                />
                                <h3>{video} </h3>
                                {/* {props.moduleCount.map((module, i) => {
                                      console.log(module);
                                      return (
                                        <h3>
                                          {module} 
                                        </h3>)
                                    })
                                    } */}
                                {/* <CircularProgress
                                    size="2rem"
                                    color="inherit"
                                  /> */}
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}
                </ul>
                <div className="d-flex align-items-center rendernotify">
                  <span>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dumm
                  </span>
                  <button
                    type="button"
                    className="btn btn-primary rounded-0"
                    onClick={goToDashboard}
                  >
                    Dashboard
                  </button>
                </div>
                {renderStatus !== 100 && (
                  <LinearProgress style={{ marginTop: "10px" }} />
                )}
              </div>
            </div>
            {/* } */}
            {/* <LinearWithValueLabel progress={renderStatus} /> */}
          </div>
          {/* ) : (
              <span className="invalid-msg blink">
                There are no templates matching input provided
              </span>
            )} */}
          {/* ) : (
              <p>Video URL is not available now! Please render again.</p>
            )} */}
        </div>
        {/* <div className="video-control">
                                    <div className="rSlider">
                                        <span className="slide"></span>
                                        <input id="range" type="range" min="0" max="50000" />
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <a href="" className="play-btn me-3">
                                                <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 0V14L11 7L0 0Z" fill="" />
                                                </svg>
                                            </a>
                                            <a href="" className="play-btn me-3">
                                                <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.5 8C13.5 6.23 12.48 4.71 11 3.97V12.02C12.48 11.29 13.5 9.77 13.5 8ZM0 5V11H4L9 16V0L4 5H0Z" fill="" />
                                                </svg>
                                            </a>
                                            <div className="time-duration">
                                                <span>0:00 </span>/<span> 4:00</span>
                                            </div>
                                        </div>

                                    </div>
                                </div> */}

        {/* </div>
                        </div>
                    </div>
                </div> */}
      </ModalBody>
    </Modal>
  );
}
