import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { CreatePreviewFromResponse, CreateScreen } from "../../../services/DataHelper";
import Header from "../../components/header/header";
import { createProjects, getProjectID } from "../../../services/api.service";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import { actionCreator } from "../../../store/index";
import ListModules from "./ListModules";
import RightScreenSetails from "./RightBar/RightScreenDetails";
import PreviewVideo from "./PreviewVideo";
import TemplateRepo from "./RightBar/TemplateRepo";
import NavigatorOnline from "react-navigator-online";
import { showToaster, ShowAlertBox, getUserItem } from "../../../services/helper";
import Tooltip from "@mui/material/Tooltip";
import PageLoader from "../../../services/pageLoader";
import { getProjectById, getTemplatePlanByOrg } from "../../../services/authService";
function ProjectDetails(props) {
  const dispatch = useDispatch();
  const { InitilizePreview, PreviousActivetoPreview, EmptyPreview } = bindActionCreators(actionCreator, dispatch);
  let preview = useSelector((state) => state.preview);
  const [pageLoad, setPageLoad] = useState(false);
  const [templateMode, setTemplateMode] = useState(false);
  const [ASValidation, setASValidation] = useState(false);
  const [OstValidation, setOstValidation] = useState(false);
  const [TemplateUpdate, setTemplateUpdate] = useState(false);
  const [madeChange, setmadeChange] = useState(false);
  const [thumbnailUrl, setthumbnailUrl] = useState("");
  const [reload, setReload] = useState(false);
  let DisplayMode = useSelector((state) => state.project.DisplayMode);
  const [projectData, setProjectData] = useState(undefined);
  const project = useSelector((state) => state.preview.previewProject);
  let EditMode = useSelector((state) => state.project.EditMode);
  const history = useHistory();
  const [templateCheckArray, settemplateCheckArray] = useState(undefined);
  const [validatingTempLoader, setvalidatingTempLoader] = useState(false);
  const [RenderThumnails, setRenderThumnails] = useState([]);
  const [changes, setchanges] = useState(false);
  const [previewEditMode, setpreviewEditMode] = useState(false);
  const [showWarning, setshowWarning] = useState(false);
  const [templateSelectionType, setTemplateSelectionType] = useState(undefined);
  const [first, setfirst] = useState(false);

  let userId = getUserItem("id");
  let AdminRole = getUserItem("role");
  let OrganiId = getUserItem("orgId");
  useEffect(async () => {
    setPageLoad(true);
    let templateSelection1;
    let orgId = AdminRole == 0 ? userId : OrganiId;
    await getTemplatePlanByOrg(orgId).then(async (response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          templateSelection1 = response.data.result[0];
          setTemplateSelectionType(response.data.result[0]);
          setPageLoad(false);
        }
      } catch (error) {
      }
    });
    let filter = {
      project_id: props.match.params.id
    };
    setPageLoad(true);
    // EmptyPreview()
    settemplateCheckArray(undefined);
    setvalidatingTempLoader(true);
    let templateValidArray = [];

    await getProjectID(filter).then(async (response) => {
      try {
        if (
          response !== "" &&
          response !== undefined &&
          response !== null &&
          response.status !== "" &&
          response.status !== undefined &&
          response.status !== null &&
          response.status === 200 &&
          response.data !== "" &&
          response.data !== undefined &&
          response.data !== null
        ) {
          let projectData = JSON.parse(response.data[0].ProjectData);
          let a = await getProjectById(filter.project_id);
          if (a.data.data.length > 0) {
            // setProjectData(JSON.parse(a.data.data[0].ProjectData));
          }
          let preview = await CreatePreviewFromResponse(a.data.data[0]);
          InitilizePreview(preview);
          if (EditMode && props.location.state !== undefined) {
            PreviousActivetoPreview({
              activeModule: props.location.state.ActiveModule,
              activeSlide: props.location.state.ActiveSlide,
              activeScreen: props.location.state.ActiveScreen
            });
          }
          let projectData1 = JSON.parse(response.data[0].ProjectData);
          let projectDatas = projectData1[props.match.params.id].Modules;
          // let preview = await CreatePreviewFromResponse(response.data[0]);
          Object.entries(projectDatas).map((moduleData, moduleIndex) => {
            let mData = moduleData[1];
            let slides = mData.slides;
            Object.entries(slides).map((slideData, moduleIndex) => {
              let slide = slideData[1];
              let screens = slide.Screens;
              Object.entries(screens).map((screenData, moduleIndex) => {
                let slide = screenData[1];
                // item.map((element, index) => {
                Object.entries(slide).map((item, key) => {
                  let activeTemplate = item.filter(function (data) {
                    return data.Contenttype === "TemplateID";
                  });
                  if (activeTemplate.length > 0) {
                    let isValid;
                    // if(templateSelection1.manualTemplate!==true){
                    //   if( activeTemplate[0].Contentparams.contentdescription !=="No template"){
                    //     isValid=true
                    //   }else{
                    //     isValid=false
                    //   }
                    // }else{
                    if (activeTemplate[0].Contentparams.contentdescription !== "no template") {
                      isValid = true;
                    } else {
                      isValid = false;
                    }
                    // }
                    let templateValid = {
                      isValid: isValid,
                      templateKeys: moduleData[0],
                      templateSlidekeys: slideData[0],
                      templateScreenkeys: screenData[0],
                      screenName: moduleData[1].ModuleName + "-" + slideData[1].SlideName + "-" + screenData[1].ScreenName
                    };

                    templateValidArray.push(templateValid);
                  }

                  // setopenTemplateChangesModal(true)
                });
              });
            });
          });
          settemplateCheckArray(templateValidArray);
          setvalidatingTempLoader(false);
          setPageLoad(false);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }, []);

  const backToDashboard = () => {
    let message = "you want to exit from the project?";
    let confirmBtn = "exit";
    Swal.fire({
      title: "Are you sure ",
      html: message,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Yes, " + confirmBtn + " it!",
      confirmButtonColor: "#d33",
      focusConfirm: false,
      focusCancel: true
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/project-list");
      }
    });
  };
  const showMessage = (status) => {
    if (status) {
      showToaster('Now! you have an internet connection.', "success");
    } else {
      showToaster("Now! you have no internet connection", "warning");
    }
  };
  return (
    <div>
      <div className={DisplayMode ? "theme dark" : "theme"}>
        <div className="main-wrapper">
          <Header setchanges={setchanges} />
          {/* {props.type === "template" && <TemplateRepo />} */}
          <PageLoader showLoader={pageLoad} />
          {templateMode && (
            <TemplateRepo
              setTemplateUpdate={setTemplateUpdate}
              templateMode={templateMode}
              setTemplateMode={setTemplateMode}
              setchanges={setchanges}
            />
          )}
          {
            <div className="content-inner d-flex">
              {templateCheckArray !== undefined && templateSelectionType !== undefined && (
                <ListModules
                  TemplateCheckArray={templateCheckArray}
                  thumbnailUrl={thumbnailUrl}
                  setRenderThumnails={setRenderThumnails}
                  reload={reload}
                  setReload={setReload}
                  setProjectData={setProjectData}
                  projectData={projectData}
                  templateSelection={templateSelectionType}
                  templateType={props.location.state !== undefined && props.location.state.type}
                />
              )}
              <main className="d-flex">
                <PreviewVideo
                  RenderThumnails={RenderThumnails}
                  reload={reload}
                  setReload={setReload}
                  templateCheckArray={templateCheckArray}
                  madeChange={madeChange}
                  TemplateUpdate={TemplateUpdate}
                  setTemplateUpdate={setTemplateUpdate}
                  ASValidation={ASValidation}
                  OstValidation={OstValidation}
                  setthumbnailUrl={setthumbnailUrl}
                  changes={changes}
                  setchanges={setchanges}
                  setProjectData={setProjectData}
                />
                <RightScreenSetails
                  setmadeChange={setmadeChange}
                  setTemplateMode={setTemplateMode}
                  templateMode={templateMode}
                  setOstValidation={setOstValidation}
                  setASValidation={setASValidation}
                  setchanges={setchanges}
                  previewEditMode={previewEditMode}
                  setpreviewEditMode={setpreviewEditMode}
                  showWarning={showWarning}
                  setshowWarning={setshowWarning}
                />
              </main>
            </div>
          }
        </div>
      </div>

      <div className="modal audio-modal dark">
        <div className="modal-overlay modal-toggle"></div>
        <div className="modal-wrapper modal-transition ">
          <div className="modal-body">
            <div className="modal-content border-0">
              <div className="img-block">
                <video id="video" preload="none" width="100%" poster="assets/img/img-2.png">
                  <source id="mp4" src="http://media.w3.org/2010/05/sintel/trailer.mp4" type="video/mp4" />
                  <source id="webm" src="http://media.w3.org/2010/05/sintel/trailer.webm" type="video/webm;" />
                  <source id="ogv" src="http://media.w3.org/2010/05/sintel/trailer.ogv" type="video/ogg" />
                </video>
              </div>

              <div className="time-duration text-end mt-2">
                <span>0:00 </span>/<span> 4:00</span>
              </div>

              <div className="audio-sec">
                <span className="play-audio">
                  <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="0.5" width="4" height="12" fill="" />
                    <rect x="7" y="0.5" width="4" height="12" fill="" />
                  </svg>
                </span>

                <div className="audio-wrap">
                  <div id="waveform" onclick="wavesurfer.playPause();"></div>
                </div>
                <span className="sound-audio">
                  <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M13.5 8.5C13.5 6.73 12.48 5.21 11 4.47V12.52C12.48 11.79 13.5 10.27 13.5 8.5ZM0 5.5V11.5H4L9 16.5V0.5L4 5.5H0Z"
                      fill=""
                    />
                  </svg>
                </span>
              </div>

              <div className="d-flex justify-content-between footer-btn flex-wrap">
                <button type="button" className="btn btn-outline border-0 rounded-0 upload-btn">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.04688 5.76485L9 2.8125L11.9531 5.76485" stroke="" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M9 10.6874V2.81445" stroke="" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round" />
                    <path
                      d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875"
                      stroke=""
                      stroke-width="1.3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <span>UPLOAD AUDIO</span>
                </button>
                <button type="button" className="btn btn-outline me-2 rounded-0 modal-toggle">
                  Cancel
                </button>
                <button type="button" className="btn btn-primary  rounded-0">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavigatorOnline onChange={(status) => showMessage(status)} />
    </div>
  );
}

export default ProjectDetails;
